export const VIDEO_STATE = {
  WAITING: 0,
  RECORDING: 1,
  THINKING: 2,
  ANSWERING: 3
}

export const DELAY = {
  STOP_RECORDING: 800,
  LONG_PRESS: 500
}

export const MIN_AUTO_LISTEN_DURATION = 3000

export const SOURCE_OF_INTERACTION = {
  ASK_FORM: 'ASK_FORM',
  CONVERSATION_STARTERS: 'CONVERSATION_STARTERS',
  EXTERNAL: 'EXTERNAL',
  SHARED_TRANSCRIPTION: 'SHARED_TRANSCRIPTION',
  VOICE: 'VOICE',
  SANTA_MENU: 'SANTA_MENU'
}

export const BUFFERING = {
  ANSWER: 'answer',
  CANNOT_ANSWER: 'cannotAnswer',
  WAITING: 'waiting'
}
