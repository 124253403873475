import React from 'react'

import FormButton from 'components/FormButton/FormButton'
import Translation from 'components/Translation/Translation'
import { COMMON_INTERACT } from 'locales/translationIds'

import styles from './Card.module.css'

const Card = ({ storyFile, onSelect }) => {
  return (
    <div className={styles.card}>
      <div className={styles.imageNameContainer}>
        <div className={styles.imageContainer}>
          <div style={{ backgroundImage: `url("${storyFile.avatar}")` }} className={styles.image} />
        </div>
        <div className={styles.name}>{storyFile.username}</div>
        <div className={styles.bio}>{storyFile.bio}</div>
      </div>
      <FormButton text={<Translation id={COMMON_INTERACT} />} onClick={() => onSelect(storyFile)} />
    </div>
  )
}

export default Card
