import React, { useState } from 'react'
import classNames from 'classnames'

import Modal from 'components/Modal/Modal'
import { useStoreSelector, SELECT } from 'providers/StoreProvider'

import ShareForm from './ShareForm/ShareForm'
import ShareControlIcon from './ShareControlIcon'

import styles from './ShareControl.module.css'

const ShareControl = () => {
  const [showShare, setShowShare] = useState(false)
  const { isDefault } = useStoreSelector(SELECT.LANGUAGE)
  const toggleShowShare = () => setShowShare(!showShare)

  return (
    <>
      <button onClick={toggleShowShare} className={styles.button}>
        <ShareControlIcon className={classNames({ [styles.iconBig]: !isDefault })} />
      </button>
      <Modal isOpen={showShare} onClose={toggleShowShare}>
        <ShareForm onCloseClick={toggleShowShare} />
      </Modal>
    </>
  )
}

export default ShareControl
