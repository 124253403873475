import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { appParamsService } from 'configuration'
import Translation from 'components/Translation/Translation'
import {
  COMMON_RESOURCES,
  COMMON_CONV_STARTERS_HINTS,
  COMMON_CONV_STARTERS_QUESTIONS,
  COMMON_CONV_STARTERS_TOGGLE_LABEL,
  COMMON_CONV_STARTERS_DISCOVER_QUESTIONS
} from 'locales/translationIds'
import { handleKeyPress } from 'utils'

import ConversationStartersToggle from '../ConversationStartersToggle/ConversationStartersToggle'
import ConversationStartersHints from '../ConversationStartersHints/ConversationStartersHints'
import ConvStartersList from './ConvStartersList'

import styles from './ConvStartersDropdown.module.css'

const { isModeMedtronic, isModeHealthySoil, openConversationStarters } = appParamsService

const MEDTRONIC_LABEL = COMMON_RESOURCES
const getCSTip = () => {
  if (isModeMedtronic) return MEDTRONIC_LABEL
  if (isModeHealthySoil) return COMMON_CONV_STARTERS_DISCOVER_QUESTIONS
  return COMMON_CONV_STARTERS_HINTS
}

const getCSLabel = () => {
  if (isModeMedtronic) return MEDTRONIC_LABEL
  if (isModeHealthySoil) return COMMON_CONV_STARTERS_QUESTIONS
  return COMMON_CONV_STARTERS_TOGGLE_LABEL
}

const CS_TIP = getCSTip()
const CS_BUTTON_LABEL = getCSLabel()

const ConvStartersDropdown = ({
  convStarters,
  dynamicCS,
  isOpen,
  onToggle,
  onItemClick,
  animateButton,
  containerClassName,
  menuContainerClassName,
  toggleClassName,
  toggleLabelClassName
}) => {
  const [transitionEnded, setTransitionEnded] = useState(false)
  const [dynamicConvStarters, setDynamicConvStarters] = useState(dynamicCS)
  const dropDownRef = useRef(null)

  useEffect(() => {
    // @TODO: Extract to hook
    const onOutsideClick = event => {
      if (
        (transitionEnded || openConversationStarters) &&
        isOpen &&
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target)
      ) {
        onToggle(false)
      }
    }

    document.addEventListener('touchstart', onOutsideClick)
    document.addEventListener('mousedown', onOutsideClick)
    return () => {
      document.removeEventListener('touchstart', onOutsideClick)
      document.removeEventListener('mousedown', onOutsideClick)
    }
  }, [dropDownRef, isOpen, onToggle, transitionEnded])

  useEffect(() => {
    if (dynamicCS?.length) {
      setDynamicConvStarters(dynamicCS)
    }
  }, [dynamicCS])

  const onDropdownContainerTransitionEnd = () => setTransitionEnded(isOpen)

  return (
    <div className={classNames(styles.container, containerClassName)}>
      {animateButton && (
        <ConversationStartersHints
          tip={<Translation id={CS_TIP} />}
          showMessageQuestion={!isModeMedtronic}
        />
      )}
      <div
        className={styles.root}
        ref={dropDownRef}
        onKeyDown={e => handleKeyPress(e, 'Escape', () => onToggle(false))}
      >
        <ConversationStartersToggle
          isExpanded={isOpen}
          onClick={() => onToggle(!isOpen)}
          label={<Translation id={CS_BUTTON_LABEL} />}
          toggleClassName={toggleClassName}
          toggleLabelClassName={toggleLabelClassName}
        />
        <div
          id="conv-starters-menu"
          onTransitionEnd={onDropdownContainerTransitionEnd}
          className={classNames(styles.dropdownContainer, menuContainerClassName, {
            [styles.showDropdownContainer]: isOpen,
            [styles.hideDropdownContainer]: !isOpen
          })}
          aria-hidden={!isOpen}
        >
          <ConvStartersList
            onContainerTransitionEnd={onDropdownContainerTransitionEnd}
            containerClassName={menuContainerClassName}
            isOpen={isOpen}
            convStarters={convStarters}
            dynamicConvStarters={dynamicConvStarters}
            onItemClick={onItemClick}
          />
        </div>
      </div>
    </div>
  )
}

export default ConvStartersDropdown
