import objectToGetParams from 'react-share/es/utils/objectToGetParams'
import createShareButton from 'react-share/es/hocs/createShareButton'

function emailLink(url, _a) {
  const subject = _a.subject,
    body = _a.body,
    separator = _a.separator
  return (
    'mailto:' + objectToGetParams({ subject: subject, body: body ? body + separator + url : url })
  )
}

// Use instead of regular EmailShareButton till fixed in library. Fixes mail client not opening on iOS Safari through iframe.
// https://github.com/nygardk/react-share/issues/304
const CustomEmailShareButton = createShareButton(
  'email',
  emailLink,
  function (props) {
    return {
      subject: props.subject,
      body: props.body,
      separator: props.separator || ' '
    }
  },
  {
    openShareDialogOnClick: false,
    onClick: function (_, link) {
      window.open(link, '_blank')
    }
  }
)

export default CustomEmailShareButton
