import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import { appParamsService } from 'configuration'
import { LANGUAGES } from 'shared/constants'
import Svg, { checkMark } from 'components/shared/Svg'
import { localStorageService, LOCAL_STORAGE_KEY } from 'utils/BrowserStorageService'
import { handleKeyPress } from 'utils'
import Translation, { translate } from 'components/Translation/Translation'
import {
  COMMON_OFF,
  COMMON_SUBTITLES,
  COMMON_VOICE_INPUT,
  COMMON_VOICE_INPUT_SAFELITE
} from 'locales/translationIds'
import { useStoreSelector, SELECT } from 'providers/StoreProvider'
import { ReactComponent as SafeliteSettingsIcon } from 'assets/images/Safelite/Icon_Safelite_Settings_Grey.svg'
import { ReactComponent as SafeliteSettingsIconSelected } from 'assets/images/Safelite/Icon_Safelite_Settings_Blue.svg'

import SubtitlesSettings from './components/SubtitlesSettings/SubtitlesSettings'
import languageIsoCodes from './components/SubtitlesSettings/languageIsoCodes'
import VoiceInputSettings from './components/VoiceInputSettings/VoiceInputSettings'
import SettingsMenuItem from './components/SettingsMenuItem/SettingsMenuItem'
import SettingsMenuIcon from './components/SettingsMenuIcon'

import styles from './SettingsMenu.module.css'

const { isModeSafelite } = appParamsService

const MENUS = {
  SETTINGS: { id: 'settings', label: 'Settings' },
  SUBTITLES: { id: 'subtitles', label: <Translation id={COMMON_SUBTITLES} /> },
  VOICE_INPUT: {
    id: 'voiceInput',
    label: <Translation id={isModeSafelite ? COMMON_VOICE_INPUT_SAFELITE : COMMON_VOICE_INPUT} />
  }
}
const SETTINGS_ITEMS = [MENUS.SUBTITLES, MENUS.VOICE_INPUT]

const SettingsMenu = ({
  className = {},
  disableSubtitlesMenu = false,
  initialSubtitles,
  menuClassName = {},
  onSubtitlesChange,
  onVoiceLangChange,
  subtitles
}) => {
  const SUBTITLES_LABEL_OFF = translate(COMMON_OFF)
  const language = useStoreSelector(SELECT.LANGUAGE)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(MENUS.SETTINGS)
  const [activeSubtitles, setActiveSubtitles] = useState(
    subtitles?.includes(initialSubtitles) ? initialSubtitles : SUBTITLES_LABEL_OFF
  )
  const menuRef = useRef(null)

  useEffect(() => {
    const onOutsideClick = event => {
      if (isOpen && menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false)
        setSelectedOption(MENUS.SETTINGS)
      }
    }

    document.addEventListener('touchstart', onOutsideClick)
    document.addEventListener('mousedown', onOutsideClick)
    return () => {
      document.removeEventListener('touchstart', onOutsideClick)
      document.removeEventListener('mousedown', onOutsideClick)
    }
  }, [isOpen, menuRef])

  const resetToMainMenu = () => {
    setSelectedOption(MENUS.SETTINGS)
  }

  const onSettingsButtonClick = () => {
    setIsOpen(prevState => !prevState)
    resetToMainMenu()
  }

  const onSubtitlesSelect = value => {
    onSubtitlesChange(value)
    setActiveSubtitles(value)
    !appParamsService.rcTransmitter &&
      localStorageService.setItem(LOCAL_STORAGE_KEY.SUBTITLES, value)

    resetToMainMenu()
  }

  const onVoiceLangSelect = langData => {
    onVoiceLangChange(langData)
    !appParamsService.rcTransmitter &&
      localStorageService.setItem(LOCAL_STORAGE_KEY.VOICE_LANG, langData)
    resetToMainMenu()
  }

  const renderSettingsMenuItem = (item, index) => {
    const menuItemProps = {
      index,
      key: `${index}-${item.id}`,
      title: item.label,
      onItemClick: () => setSelectedOption(item)
    }

    switch (item.id) {
      case MENUS.SUBTITLES.id:
        menuItemProps.settingLabel =
          languageIsoCodes[activeSubtitles]?.nativeName || SUBTITLES_LABEL_OFF
        menuItemProps.disabled = disableSubtitlesMenu
        break
      case MENUS.VOICE_INPUT.id:
        menuItemProps.settingLabel = language.name
        break
      default:
    }

    return <SettingsMenuItem {...menuItemProps} />
  }

  const checkMarkIcon = (
    <span>
      <Svg width={15} height={15} path={checkMark} />
    </span>
  )

  const handleEscapePress = () => {
    setIsOpen(false)
    resetToMainMenu()
  }

  return (
    <div className={classNames([styles.container, className])} ref={menuRef}>
      <button
        id="settings-btn"
        className={classNames(styles.settingsButton, { [styles.rotateButton]: isOpen })}
        onClick={onSettingsButtonClick}
        aria-expanded={isOpen}
        aria-label="Settings"
      >
        {isModeSafelite ? (
          isOpen ? (
            <SafeliteSettingsIconSelected />
          ) : (
            <SafeliteSettingsIcon />
          )
        ) : (
          <SettingsMenuIcon isOpen={isOpen} />
        )}
      </button>
      {isOpen && (
        <div
          role="menu"
          id="settings-menu"
          className={classNames([styles.menuContainer, menuClassName])}
          onKeyDown={e => handleKeyPress(e, 'Escape', handleEscapePress)}
        >
          {selectedOption.id !== MENUS.SETTINGS.id && (
            <div
              role="menuitem"
              tabIndex={0}
              className={styles.menuHeader}
              onClick={resetToMainMenu}
              aria-label="Back"
              onKeyDown={e => handleKeyPress(e, 'Enter', resetToMainMenu)}
            >
              <span className={styles.headerBackCaret}>&#60;</span>
              {selectedOption.label}
            </div>
          )}
          {selectedOption.id === MENUS.SETTINGS.id && SETTINGS_ITEMS.map(renderSettingsMenuItem)}
          {selectedOption.id === MENUS.SUBTITLES.id && (
            <SubtitlesSettings
              activeSubtitles={activeSubtitles}
              subtitles={subtitles}
              offLabel={SUBTITLES_LABEL_OFF}
              onSubtitlesChange={onSubtitlesSelect}
              className={styles.menuItem}
              selectedIcon={checkMarkIcon}
            />
          )}
          {selectedOption.id === MENUS.VOICE_INPUT.id && (
            <VoiceInputSettings
              activeVoiceLang={language}
              languages={LANGUAGES}
              onLanguageChange={onVoiceLangSelect}
              className={styles.menuItem}
              selectedIcon={checkMarkIcon}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default SettingsMenu
