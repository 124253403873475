import { sessionStorageService, SESSION_STORAGE_KEY } from 'utils/BrowserStorageService'
import { postEventToParent, WINDOW_MESSAGES } from 'utils'
import { URL_PARAMS } from 'constants'
import { appParamsService } from 'configuration'

const { experimentalFeatures } = appParamsService

const SEPARATOR = '_' // reserved symbol in the video on_end_event column to separate event name and its params
export const END_EVENT_DIALOGUE_TYPES = {
  endStoryfile: 'endStoryfile',
  showUrl: 'showUrl',
  showText: 'showText'
}

const VIDEO_END_EVENTS = {
  refresh: refreshHandler,
  linkToStoryfile: refreshToStoryfileIdHandler,
  endStoryfile: endStoryfileHandler,
  openConversationStarters: openConvStartersHandler,
  showUrl: showURLHandler,
  showText: showTextHandler
}

function _migrateJsonParams(eventName, options) {
  switch (eventName) {
    case 'linkToStoryfile':
      const { videoId, asr, text } = options
      const paramsArr = [options.storyfileId]
      const secondParam = videoId || text || asr
      secondParam && paramsArr.push(secondParam)
      return {
        eventName: 'refresh',
        params: paramsArr
      }

    case 'showUrl':
      return { eventName, params: [options.message, options.buttons] }

    case 'showText':
      return { eventName, params: [options.text] }

    case 'custom':
      const [event, ...rest] = options.text.split(SEPARATOR)
      return { eventName: event, params: [rest.join(SEPARATOR)] }

    default:
      return { eventName, params: [] }
  }
}

export const handleVideoEventJson = (eventName, options, state, onEndEvents) => {
  console.log(`Video end event ${eventName}`)

  const parentEventPayload = _migrateJsonParams(eventName, options)
  postEventToParent({ type: WINDOW_MESSAGES.VIDEO_END_EVENT, ...parentEventPayload })
  if (experimentalFeatures) {
    console.log('parentEventPayload', parentEventPayload)
  }

  const handler = VIDEO_END_EVENTS[eventName]
  if (!handler) {
    return
  }

  return handler({ state, onEndEvents, ...options })
}

function refreshHandler(options) {
  if (!options.enabled) {
    return {}
  }

  window.location.reload()
}

export function refreshToStoryfileIdHandler(options) {
  sessionStorageService.setItem(SESSION_STORAGE_KEY.REFRESH_EVENT, true)

  const { state, storyfileId, asr, videoId, text } = options
  const searchParams = new URLSearchParams(window.location.search)

  // In case of multiple ids, we want to preserve them
  // and append the uid to load with a '_' separator
  const uidParam = searchParams.get(URL_PARAMS.uid)
  if (uidParam && uidParam.split(';').length > 1) {
    const uids = uidParam.split('_')[0] // clear previously set '_' uid
    searchParams.set(URL_PARAMS.uid, uids + `_${storyfileId}`)
  } else {
    searchParams.set(URL_PARAMS.uid, storyfileId)
  }
  searchParams.delete(URL_PARAMS.user) // in case app was initially loaded with a 'user' param

  const hasVideoTrigger = videoId || asr || text

  if (hasVideoTrigger) {
    if (videoId) {
      searchParams.set(URL_PARAMS.intro, 1)
      searchParams.set(URL_PARAMS.introId, videoId)
    } else if (asr) {
      searchParams.set(URL_PARAMS.st, window.btoa(state.lastAnsweredTranscription))
    } else if (text) {
      searchParams.set(URL_PARAMS.st, window.btoa(text))
    }
  } else {
    searchParams.delete(URL_PARAMS.intro)
    searchParams.delete(URL_PARAMS.introId)
    searchParams.delete(URL_PARAMS.st)
  }

  window.location.search = searchParams.toString()
}

function openConvStartersHandler(options) {
  if (!options.enabled) {
    return {}
  }

  const newState = { openCSMenu: true }
  return newState
}

function endStoryfileHandler(options) {
  if (!options.enabled) {
    return {}
  }

  const newState = { endEventDialogueType: END_EVENT_DIALOGUE_TYPES.endStoryfile }
  return newState
}

function showURLHandler(options) {
  const newState = {
    endEventDialogueType: END_EVENT_DIALOGUE_TYPES.showUrl,
    endEventDialogueDescription: options.message,
    endEventDialogueUrlButtons: options.buttons
  }

  return newState
}

function showTextHandler(options) {
  const newState = {
    endEventDialogueType: END_EVENT_DIALOGUE_TYPES.showText
  }

  if (options.text) {
    newState.endEventDialogueData = options.text.replaceAll('\\n', ' \n ')
  }

  return newState
}

export const willJsonEndEventsTriggerUI = (events = []) => {
  let hasUIEffects = false
  events.forEach(event => {
    const handler = VIDEO_END_EVENTS[event]
    if (handler) {
      hasUIEffects = true
    }
  })

  return hasUIEffects
}
