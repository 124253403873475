import React from 'react'

import Translation from 'components/Translation/Translation'
import { AUTH_COMMON_OR_LOGIN_WITH } from 'locales/translationIds'
import googleIcon from 'assets/images/google-icon.svg'
import facebookIcon from 'assets/images/facebook-icon.svg'

import styles from './SingleSignOnButtons.module.css'

const SingleSignOnButtons = ({ googleLogin, facebookLogin }) => {
  return (
    <>
      <p className={styles.ssoText}>
        <Translation id={AUTH_COMMON_OR_LOGIN_WITH} />
      </p>
      <div className={styles.ssoButtonsContainer}>
        <button className={styles.ssoButton} onClick={googleLogin}>
          <img src={googleIcon} className={styles.ssoIcon} alt="Google icon" />
          <span>Google</span>
        </button>
        <button className={styles.ssoButton} onClick={facebookLogin}>
          <img src={facebookIcon} className={styles.ssoIcon} alt="Facebook icon" />
          <span>Facebook</span>
        </button>
      </div>
    </>
  )
}

export default SingleSignOnButtons
