import React from 'react'

import Translation from 'components/Translation/Translation'
import { COMMON_CONV_STARTERS_HINTS_QUESTION } from 'locales/translationIds'

import styles from './ConversationStartersHints.module.css'

const ConversationStartersHints = ({ tip = '', showMessageQuestion = true }) => (
  <div className={styles.hintMessage} aria-hidden="true">
    {showMessageQuestion && (
      <div className={styles.hintMessageQuestion}>
        <Translation id={COMMON_CONV_STARTERS_HINTS_QUESTION} />
      </div>
    )}
    <div className={styles.hintMessageTip}>{tip}</div>
  </div>
)

export default ConversationStartersHints
