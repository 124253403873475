const STROKE_STYLE = '#61D6D1'
const FILL_STYLE = '#61D6D1'

const useAudioVisualizer = () => {
  let currentStream = {}
  let mediaStreamSources

  const startStream = (stream, canvasRef) => {
    let analyser
    if (!mediaStreamSources) {
      let audioCtx = new AudioContext()
      analyser = audioCtx.createAnalyser()

      let source = audioCtx.createMediaStreamSource(stream)
      source.connect(analyser)
      mediaStreamSources = {
        audioCtx,
        analyser,
        source
      }
    } else {
      cancelAnimationFrame(mediaStreamSources.animation)
    }

    analyser.fftsize = 32768
    let bufferLength = analyser.frequencyBinCount
    currentStream.data = new Uint8Array(bufferLength)

    function renderFrame() {
      currentStream.animation = requestAnimationFrame(currentStream.loop)
      mediaStreamSources.animation = currentStream.animation
      analyser.getByteFrequencyData(currentStream.data)

      visualize(currentStream.data, canvasRef)
    }

    currentStream.loop = renderFrame
    renderFrame()
  }

  function stopStream() {
    cancelAnimationFrame(currentStream.animation)
  }

  const visualize = (data, canvasRef) => {
    if (!canvasRef.current) return

    let ctx = canvasRef.current.getContext('2d')
    let h = canvasRef.current.height
    let w = canvasRef.current.width

    ctx.strokeStyle = STROKE_STYLE
    ctx.lineWidth = 1

    const drawblockData = {
      data,
      ctx,
      h,
      w
    }
    ctx.clearRect(0, 0, w, h)
    ctx.beginPath()
    drawBlocks(drawblockData)
  }

  const drawBlocks = drawblockData => {
    let { data, ctx, h, w } = drawblockData

    let percent = h / 255
    let width = w / 50
    let skip = true
    for (let point = 0; point <= 50; point++) {
      let p = data[point]
      p *= percent
      let x = width * point

      if (skip) {
        ctx.rect(x, h / 2 + p / 2, width, -p)
        skip = false
      } else {
        skip = true
      }
    }

    ctx.fillStyle = FILL_STYLE
    ctx.fill()
    ctx.stroke()
  }

  return {
    startStream,
    stopStream
  }
}

export default useAudioVisualizer
