import React, { useState, useEffect } from 'react'

import { appParamsService } from 'configuration'

import AskSantaConvStartersDropdown from '../AskSanta/AskSantaConvStartersDropdown/AskSantaConvStartersDropdown'
import ConvStartersDropdown from './components/ConvStartersDropdown/ConvStartersDropdown'
import ConvStartersList from './components/ConvStartersDropdown/ConvStartersList'
import useConversationStarters from './hooks/useConversationStarters'

import './ConversationStarters.css'

const ConversationStarters = ({
  userId,
  dynamicConvStarters,
  openMenu = false,
  onStarterClick,
  onMenuClose = () => {},
  animateButton = true,
  dropdownContainerClassName,
  menuContainerClassName,
  toggleClassName,
  toggleLabelClassName,
  listOnly = false
}) => {
  const [isOpen, setIsOpen] = useState(appParamsService.openConversationStarters || openMenu)
  const convStarters = useConversationStarters(userId)

  useEffect(() => {
    if (openMenu) {
      setIsOpen(openMenu)
    }
  }, [openMenu])

  const submitQuestion = text => {
    setIsOpen(false)
    onMenuClose()

    onStarterClick(text)
  }

  const onToggle = openState => {
    setIsOpen(openState)

    if (!openState) {
      onMenuClose()
    }
  }

  const dropDownProps = {
    convStarters,
    containerClassName: dropdownContainerClassName,
    dynamicCS: dynamicConvStarters,
    isOpen,
    onToggle,
    onItemClick: submitQuestion
  }

  if (listOnly) {
    return (
      <ConvStartersList
        containerClassName={menuContainerClassName}
        isOpen={isOpen}
        convStarters={convStarters}
        dynamicConvStarters={dynamicConvStarters}
        onItemClick={submitQuestion}
      />
    )
  }

  if (!convStarters.length) {
    return null
  }

  if (appParamsService.isModeSanta) {
    return <AskSantaConvStartersDropdown {...dropDownProps} />
  }

  return (
    <ConvStartersDropdown
      {...dropDownProps}
      animateButton={animateButton}
      menuContainerClassName={menuContainerClassName}
      toggleClassName={toggleClassName}
      toggleLabelClassName={toggleLabelClassName}
    />
  )
}

export default ConversationStarters
