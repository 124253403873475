import React from 'react'
import classNames from 'classnames'

import Spinner from 'components/Spinner/Spinner'

import styles from './FormButton.module.css'

export const STYLING_TYPES = {
  default: 'default',
  primary: 'primary',
  link: 'link'
}

export default function FormButton({
  text,
  styling = STYLING_TYPES.primary,
  className = {},
  type = 'button',
  loading,
  disabled,
  elementId,
  onClick = () => {}
}) {
  return (
    <button
      className={classNames(styles[styling], className)}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      id={elementId}
    >
      {loading ? <Spinner className={styles.spinner} /> : text}
    </button>
  )
}
