import React from 'react'

import Spinner from 'components/Spinner/Spinner'
import FormButton from 'components/FormButton/FormButton'
import Translation from 'components/Translation/Translation'
import { AUTH_COMMON_USE_S_S_O } from 'locales/translationIds'

import styles from './SSOButton.module.css'

const SSOButton = ({ loading, onClick }) => {
  return loading ? (
    <Spinner className={styles.spinner} />
  ) : (
    <FormButton
      text={<Translation id={AUTH_COMMON_USE_S_S_O} />}
      className={styles.SSOButton}
      onClick={onClick}
    />
  )
}

export default SSOButton
