/* Declarations of methods which are being reused by the server and client */

const { LANGUAGES, LANGUAGE_VARIANTS, DEFAULT_LANGUAGE } = require('./constants')

module.exports.getLanguageByTag = tag => {
  const language = LANGUAGES.find(l => l.tag === tag)
  if (language) {
    return language
  }

  // Language not found, check for supported variants.
  // @TODO: Unify logic when new UI designs are decided.
  const variant = LANGUAGE_VARIANTS.find(lang => lang.variants.find(variant => variant === tag))
  if (variant) {
    return { tag, name: variant.name, appLocale: variant.appLocale }
  }

  console.error(`Language with tag '${tag}' not supported.`)
  return DEFAULT_LANGUAGE
}
