import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import Providers from 'providers/Providers'
import { SENTRY_IGNORED_ERRORS, SENTRY_DENIED_URLS } from 'constants/sentry'

import App from './App'
import { envConfig } from './configuration'

import './index.css'

process.env.NODE_ENV === 'production' &&
  envConfig['ENVIRONMENT'] !== 'local' &&
  Sentry.init({
    dsn: envConfig['SENTRY_DSN'],
    environment: envConfig['ENVIRONMENT'],
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    ignoreErrors: SENTRY_IGNORED_ERRORS,
    denyUrls: SENTRY_DENIED_URLS
  })

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Providers>
    <App />
  </Providers>
)
