import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import { handleKeyPress } from 'utils'

import styles from './SettingsMenuItem.module.css'

const SettingsMenuItem = ({
  index,
  title,
  settingLabel = '',
  disabled = false,
  onItemClick = () => {}
}) => {
  const firstMenuItemRef = useRef(null)

  useEffect(() => {
    if (firstMenuItemRef.current) {
      firstMenuItemRef.current.focus()
    }
  }, [])

  const handleItemSelect = () => {
    if (!disabled) {
      onItemClick(title)
    }
  }

  return (
    <div
      ref={index === 0 ? firstMenuItemRef : null}
      className={classNames(styles.menuItem, { [styles.menuItemActive]: !disabled })}
      onClick={handleItemSelect}
      role="menuitem"
      tabIndex={0}
      onKeyDown={e => handleKeyPress(e, 'Enter', handleItemSelect)}
    >
      {title}
      <div aria-hidden="true">
        {settingLabel && <span className={styles.settingLabel}>{settingLabel}</span>}
        {!disabled && <span className={styles.caret}>&gt;</span>}
      </div>
    </div>
  )
}

export default SettingsMenuItem
