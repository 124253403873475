import React, { useReducer, useMemo, useContext } from 'react'

import { appParamsService } from 'configuration'

const { language } = appParamsService

// defines all parent nodes in the store
export const SELECT = {
  LANGUAGE: 'language'
}

// defines store dispatch actions
const ACTION = {
  LANGUAGE_SET: 'LANGUAGE_SET'
}

const dispatchContextValue = {
  language: { set: () => {} }
}

const DispatchContext = React.createContext(dispatchContextValue)
const LanguageStoreContext = React.createContext(language)

// defines a map between selectors and contexts
const SELECT_CONTEXT_MAP = {
  [SELECT.LANGUAGE]: LanguageStoreContext
}

const reducer = (state, action) => {
  let newState = {}
  switch (action.type) {
    case ACTION.LANGUAGE_SET:
      newState = { ...state, language: action.payload }
      break
    default:
      throw new Error(`Store action is not defined: ${action.type}`)
  }
  storeState = newState
  return newState
}

export const useStoreSelector = type => useContext(SELECT_CONTEXT_MAP[type])
export const useStoreDispatch = () => useContext(DispatchContext)

// defines the initial state
let storeState = { language: language }

// exports the current store state, it could be used outside of a react component
export const getStoreState = name => (name ? storeState[name] : storeState)

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, storeState)

  // defines all of the dispatch functions memoized
  const dispatchValue = useMemo(() => {
    const languageSet = payload => dispatch({ type: ACTION.LANGUAGE_SET, payload })

    return { language: { set: languageSet } }
  }, [])

  /* We are dividing the store elements into a different contexts, because we need a memoization.
   When a parent node is changed in the store we want to pre-render only the components which are listening/using it.*/
  return (
    <DispatchContext.Provider value={dispatchValue}>
      <LanguageStoreContext.Provider value={state.language}>
        {children}
      </LanguageStoreContext.Provider>
    </DispatchContext.Provider>
  )
}

export default StoreProvider
