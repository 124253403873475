import React, { useEffect, useRef } from 'react'

const VoiceInputSettings = ({
  activeVoiceLang = {},
  languages = [],
  onLanguageChange = () => {},
  className,
  selectedIcon
}) => {
  const firstMenuItemRef = useRef(null)

  useEffect(() => {
    if (firstMenuItemRef.current) {
      firstMenuItemRef.current.focus()
    }
  }, [])

  return (
    <>
      {languages.map((language, i) => (
        <div
          ref={i === 0 ? firstMenuItemRef : null}
          role="menuitem"
          className={className}
          key={`${language.tag} - ${i}`}
          onClick={() => onLanguageChange(language)}
          tabIndex={0}
        >
          {language.name}
          {activeVoiceLang.tag === language.tag && selectedIcon}
        </div>
      ))}
    </>
  )
}

export default VoiceInputSettings
