import { useEffect, useState } from 'react'

const events = ['mousedown', 'keypress', 'touchstart', 'onclick']

const useInteractionListener = startListening => {
  const [hasDOMInteraction, setHasDOMInteraction] = useState(false)

  useEffect(() => {
    if (!startListening) return

    const attachListeners = () =>
      events.forEach(ev => document.addEventListener(ev, interactionHandler))

    const clearListeners = () =>
      events.forEach(ev => document.removeEventListener(ev, interactionHandler))

    const interactionHandler = () => {
      setHasDOMInteraction(true)
      clearListeners()
    }

    attachListeners()
  }, [startListening])

  return hasDOMInteraction
}

export default useInteractionListener
