import React, { useState } from 'react'

import { authStoryFileCrypto, login, resetPassword } from 'api'
import { storeAuthCookie, parseAuth } from 'api/token-store'
import { appParamsService } from 'configuration'
import Translation, { translate } from 'components/Translation/Translation'
import {
  AUTH_HEADER_NO_ACCESS,
  AUTH_HEADER_PROVIDE_ID,
  AUTH_HEADER_LOGIN_REQUIRED,
  AUTH_HEADER_NOT_EXIST,
  AUTH_ERROR_ALL_FIELDS_REQUIRED,
  AUTH_ERROR_WRONG_EMAIL_OR_PASSWORD,
  AUTH_ERROR_EMPTY_EMAIL
} from 'locales/translationIds'

import LoginForm from './components/LoginForm/LoginForm'
import StoryFileLogo from './components/StoryFileLogo/StoryFileLogo'
import styles from './AuthScreen.module.css'

const AuthScreen = ({
  onLoginSuccess,
  isSFPrivate,
  doesSFExist,
  ssoLoading,
  startSSOLoading,
  startPollingAuthStoryFile
}) => {
  const [showResetForm, setShowResetForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const showForm = doesSFExist && !isSFPrivate

  const renderAuthHeader = () => {
    let translationId = ''

    switch (true) {
      case isSFPrivate:
        translationId = AUTH_HEADER_NO_ACCESS
        break
      case doesSFExist:
        translationId = appParamsService.ssoLoading ? '' : AUTH_HEADER_LOGIN_REQUIRED
        break
      case doesSFExist === false:
        translationId = AUTH_HEADER_NOT_EXIST
        break
      default:
        translationId = AUTH_HEADER_PROVIDE_ID
    }

    return <Translation id={translationId} />
  }

  const toggleResetForm = () => {
    if (loading) {
      return
    }

    setErrorMsg('')
    setShowResetForm(!showResetForm)
  }

  const onLoginSubmit = async (email, password) => {
    if (!email || !password) {
      setErrorMsg(translate(AUTH_ERROR_ALL_FIELDS_REQUIRED))
      return
    }

    setErrorMsg('')
    setLoading(true)

    try {
      const {
        data: { data }
      } = await login(email, password)

      const auth = parseAuth(data)
      storeAuthCookie(auth)
      onLoginSuccess()
    } catch (e) {
      setErrorMsg(translate(AUTH_ERROR_WRONG_EMAIL_OR_PASSWORD))
      console.error('Error logging in: ', e)
    } finally {
      setLoading(false)
    }
  }

  const onCryptoLoginSubmit = async (signature, message, address) => {
    const { data } = await authStoryFileCrypto({
      storyfileId: appParamsService.storyFileIds[0],
      signature,
      message,
      address
    })
    const auth = parseAuth(data)

    storeAuthCookie(auth)
    onLoginSuccess()
  }

  const onResetSubmit = async email => {
    if (!email) {
      setErrorMsg(translate(AUTH_ERROR_EMPTY_EMAIL))
      return
    }

    setErrorMsg('')
    setLoading(true)

    try {
      await resetPassword(email)

      setLoading(false)
      toggleResetForm()
    } catch (e) {
      console.error('Error resetting password: ', e)
      setLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <StoryFileLogo />
      <div className={styles.headerText}>{renderAuthHeader()}</div>
      {showForm && (
        <LoginForm
          onLoginSubmit={onLoginSubmit}
          onCryptoLoginSubmit={onCryptoLoginSubmit}
          onResetSubmit={onResetSubmit}
          showResetForm={showResetForm}
          toggleResetForm={toggleResetForm}
          loading={loading}
          setLoading={setLoading}
          errorMsg={errorMsg}
          ssoLoading={ssoLoading}
          startSSOLoading={startSSOLoading}
          startPollingAuthStoryFile={startPollingAuthStoryFile}
          onLoginSuccess={onLoginSuccess}
        />
      )}
    </div>
  )
}

export default AuthScreen
