import React from 'react'
import classNames from 'classnames'

import KeyboardButtonIcon from './KeyboardButtonIcon'

import styles from './KeyboardButton.module.css'

const KeyboardButton = ({ onClick, iconClassName, containerClassName }) => (
  <button
    id="keyboard-btn"
    className={classNames(styles.button, containerClassName)}
    onClick={onClick}
    aria-label="Show text input"
  >
    <KeyboardButtonIcon className={iconClassName} />
  </button>
)

export default KeyboardButton
