import React from 'react'

import * as locales from 'locales'
import { getStoreState, useStoreSelector, SELECT } from 'providers/StoreProvider'

const KEY_PATH_DELIMITER = '.'
const DEFAULT_TRANSLATIONS = locales.en

let translated = {}
let translations = {}

const loadTranslations = locale => {
  if (!translations[locale]) {
    flushCache()
    translations[locale] = locales[locale]
    if (!translations[locale]) {
      throw new Error("Couldn't load translations for the locale ", locale)
    }
  }
  return translations[locale]
}

const flushCache = () => {
  translated = {}
  translations = {}
}

const getTranslation = (key, locale, values = []) => {
  const translations = loadTranslations(locale)
  if (translated[key]) return translated[key]

  const path = key.split(KEY_PATH_DELIMITER)
  let translation = path.reduce((acc, key) => acc[key] || acc, translations)
  if (typeof translation !== 'string') {
    console.error(`A translation for the key "${key}" is missing !`)
    translation = path.reduce((acc, key) => acc[key] || acc, DEFAULT_TRANSLATIONS)
    translation = typeof translation !== 'string' ? key : translation
  }
  if (values.length) {
    translation = replaceValues(translation, values)
  }
  translated[key] = translation
  return translation
}

const replaceValues = (translation, values) => {
  values.forEach((value, index) => {
    translation = translation.replace(new RegExp('\\{' + index + '\\}', 'g'), value)
  })
  return translation
}

export const translate = (key, values = []) => {
  const { appLocale } = getStoreState(SELECT.LANGUAGE)
  return getTranslation(key, appLocale, values)
}

const Translation = ({ id }) => {
  const { appLocale } = useStoreSelector(SELECT.LANGUAGE)
  return id && getTranslation(id, appLocale)
}

export default React.memo(Translation)
