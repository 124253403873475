export const SENTRY_IGNORED_ERRORS = [
  // Random plugins/extensions
  'top.GLOBALS',
  //
  // Microsoft crawlers error, https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
  'Object Not Found Matching Id',
  //
  // Win10 Google analytics error, https://issuetracker.google.com/issues/234737618?pli=1
  'Illegal invocation',
  //
  // Linux HeadlessChrome error, https://github.com/getsentry/sentry-javascript/issues/2292
  'Non-Error exception captured',
  //
  // Unhandled firebase storage mechanism errors on iOS (IndexedDB) https://github.com/firebase/firebase-js-sdk/issues/2710
  /database/i
]

export const SENTRY_DENIED_URLS = [
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i
]
