import React, { useRef, useEffect } from 'react'

import { translate } from 'components/Translation/Translation'
import { COMMON_OFF } from 'locales/translationIds'

import languageIsoCodes from './languageIsoCodes'

const SubtitlesSettings = ({
  activeSubtitles,
  subtitles = [],
  mapper = languageIsoCodes,
  offLabel = translate(COMMON_OFF),
  onSubtitlesChange = () => {},
  className,
  selectedIcon
}) => {
  const firstMenuItemRef = useRef(null)

  useEffect(() => {
    if (firstMenuItemRef.current) {
      firstMenuItemRef.current.focus()
    }
  }, [])

  return (
    <>
      <div
        ref={firstMenuItemRef}
        role="menuitem"
        tabIndex={0}
        className={className}
        onClick={() => onSubtitlesChange(offLabel)}
      >
        {offLabel}
        {activeSubtitles === offLabel && selectedIcon}
      </div>
      {subtitles.map((code, i) => (
        <div
          role="menuitem"
          className={className}
          key={`${code} - ${i}`}
          tabIndex={0}
          onClick={() => onSubtitlesChange(code)}
        >
          {mapper[code]?.nativeName}
          {activeSubtitles === code && selectedIcon}
        </div>
      ))}
    </>
  )
}

export default SubtitlesSettings
