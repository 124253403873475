import React from 'react'

import { loadThemeColor } from 'utils/color'

const KeyboardButtonIcon = ({ className = '' }) => (
  <svg
    width={25}
    height={25}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.07 41.07"
    className={className}
    aria-hidden="true"
  >
    <defs>
      <style>
        {`.kbic-11 {
          fill:${loadThemeColor('--keyboard-color')};
          }
          .kbic-12 {
              fill:#fff;
          }`}
      </style>
    </defs>
    <rect className="kbic-11" width="41.07" height="41.07" rx="4" />
    <rect className="kbic-12" x="7.8" y="28.14" width="15.61" height="5.9" rx="1.5" />
    <rect className="kbic-12" x="7.8" y="18.07" width="5.9" height="5.9" rx="1.5" />
    <rect className="kbic-12" x="17.5" y="18.07" width="5.9" height="5.9" rx="1.5" />
    <rect className="kbic-12" x="27.53" y="18.07" width="5.9" height="5.9" rx="1.5" />
    <rect className="kbic-12" x="27.53" y="28.14" width="5.9" height="5.9" rx="1.5" />
    <rect className="kbic-12" x="7.64" y="8" width="5.9" height="5.9" rx="1.5" />
    <rect className="kbic-12" x="17.34" y="8" width="5.9" height="5.9" rx="1.5" />
    <rect className="kbic-12" x="27.37" y="8" width="5.9" height="5.9" rx="1.5" />
  </svg>
)

export default KeyboardButtonIcon
