import React from 'react'

import { useStoreSelector, SELECT } from 'providers/StoreProvider'
import { loadThemeColor } from 'utils/color'

const BUTTON_CONTENT_LOCALE_MAP = {
  en: (
    <g>
      <path
        d="M40.6 57.6c-.3-3.2-2.3-4.8-5.8-4.8-3.1 0-5.1 1.3-5.1 3.4 0 1.7 1.2 2.7 4.2 3.4l3.8.8c2.8.6 4.2 1.2 5.3 2.2 1.2 1.1 1.9 2.7 1.9 4.5 0 4.4-3.6 7.1-9.2 7.1-6 0-9.7-3.1-10.2-8.7H29c.3 3.7 2.8 5.8 6.8 5.8 3.4 0 5.7-1.6 5.7-4 0-1.7-1.3-2.9-3.7-3.5l-5.2-1.3c-4.3-1-6.2-2.9-6.2-6 0-4.1 3.4-6.7 8.7-6.7s8.2 2.5 8.9 7.7h-3.4zM52.2 58.3c1.6-1.4 3-2 5-2 2.1 0 3.7.7 4.7 2 .8 1 1.1 2.2 1.1 4.1v11.3h-3.2V62.9c0-1.4-.2-2.2-.7-2.7-.6-.6-1.5-1-2.6-1-2.8 0-4.4 1.7-4.4 4.5v9.9h-3.2V50.4h3.2v7.9zM67.7 62.4c.1-2 .5-3.2 1.4-4.1 1.1-1.2 3.1-1.9 5.5-1.9 4.2 0 6.6 2 6.6 5.5v7.9c0 1.3.2 1.6.9 1.6.3 0 .5 0 .8-.2v2.5c-.8.2-1.4.2-2.1.2-1.5 0-2.2-.7-2.5-2.3-1.7 1.7-3.6 2.5-5.8 2.5-3.3 0-5.5-2-5.5-5 0-2.7 1.7-4.4 5.1-5.1l3.7-.7c2-.4 2.4-.7 2.4-1.7 0-1.6-1.4-2.7-3.7-2.7-1.6 0-2.9.5-3.4 1.4-.2.5-.3.8-.4 2.1h-3zM78.3 65c-.6.2-1.2.4-2 .6l-3.3.8c-2 .5-2.8 1.3-2.8 2.6 0 1.5 1.3 2.6 3.1 2.6 2.7 0 5-2 5-4.4V65zM89.1 56.8v2.7c.7-1 .9-1.4 1.4-1.8.8-.8 2-1.3 3-1.3.6 0 1.2.1 1.8.4v2.9c-.7-.1-1-.2-1.5-.2-2.6 0-4.6 2.3-4.6 5.3v8.9H86V56.8h3.1zM100.2 66c.2 3.5 2 5.4 4.9 5.4 2 0 3.3-.9 4.1-2.9h3.1c-.8 3.5-3.5 5.6-7.2 5.6-4.7 0-8-3.6-8-8.9 0-5.3 3.2-8.9 7.9-8.9 3.4 0 5.8 1.8 6.9 5 .4 1.3.6 2.6.7 4.6h-12.4zm9-2.3c-.1-3.1-1.7-4.8-4.2-4.8-2.6 0-4.5 2-4.7 4.8h8.9z"
        className="sscc2"
      />
    </g>
  ),
  es: (
    <g>
      <path
        d="M24.2 60.6c-.6-2.5-2.4-4-5-4-3.6 0-6 2.9-6 7.3 0 4.5 2.4 7.6 5.9 7.6 2.9 0 4.9-2 5.4-5.2h2.7c-.2 1.9-.6 3-1.6 4.4-1.5 2.1-3.7 3.2-6.5 3.2s-5-1.1-6.6-3.2c-1.4-1.8-2.1-4.1-2.1-6.7 0-5.7 3.6-9.7 8.8-9.7 4 0 6.8 2.2 7.6 6l-2.6.3zM42.1 66.8c0 4.2-2.7 7.1-6.6 7.1s-6.5-3-6.5-7.2 2.6-7.1 6.5-7.1c4-.1 6.6 2.8 6.6 7.2zm-10.4-.1c0 3.2 1.4 5 3.9 5 2.4 0 3.9-1.8 3.9-4.9 0-3.3-1.4-5.1-3.9-5.1s-3.9 1.8-3.9 5zM47.1 59.9v1.7c1-1.5 2-2.1 3.7-2.1 1.8 0 2.9.7 3.5 2.3 1.1-1.6 2.2-2.3 4.1-2.3 1.4 0 2.7.5 3.3 1.4.6.8.9 1.7.9 3.3v9.2H60v-9c0-1.8-.7-2.6-2.2-2.6-1.8 0-2.9 1.3-2.9 3.4v8.3h-2.6v-9.3c0-1.5-.8-2.4-2.2-2.4-1.7 0-2.9 1.3-2.9 3.2v8.4h-2.6V59.9h2.5zM69 59.8v1.5c1.3-1.2 2.5-1.8 4.1-1.8 3.6 0 6 2.9 6 7.3 0 4.3-2.2 7.1-5.8 7.1-1.8 0-3.3-.6-4.4-1.9v5.8h-2.6v-18H69zm-.1 6.8c0 3.3 1.3 5.1 3.9 5.1 2.4 0 3.8-1.8 3.8-5s-1.4-5-3.8-5-3.9 1.8-3.9 4.9zM82.2 64.4c.1-1.6.4-2.6 1.1-3.3.9-1 2.5-1.5 4.4-1.5 3.4 0 5.3 1.6 5.3 4.4v6.4c0 1.1.1 1.3.7 1.3.2 0 .4 0 .7-.1v2c-.6.1-1.2.2-1.7.2-1.2 0-1.8-.5-2-1.8-1.4 1.4-2.9 2-4.7 2-2.6 0-4.4-1.6-4.4-4 0-2.2 1.4-3.5 4.1-4.1l3-.6c1.6-.3 2-.6 2-1.4 0-1.3-1.2-2.1-3-2.1-1.3 0-2.4.4-2.7 1.2-.2.4-.3.7-.3 1.7h-2.5zm8.5 2.1c-.5.2-.9.3-1.6.5l-2.6.6c-1.6.4-2.3 1-2.3 2.1 0 1.2 1 2.1 2.5 2.1 2.2 0 4-1.6 4-3.5v-1.8zM99.4 59.9V62c.5-.8.8-1.1 1.1-1.4.6-.6 1.6-1.1 2.4-1.1.5 0 1 .1 1.4.3v2.3c-.5-.1-.8-.1-1.2-.1-2.1 0-3.7 1.9-3.7 4.3v7.1h-2.6V59.9h2.6zM112.8 62h-2.4v8.5c0 .8.4 1.2 1 1.2.4 0 .7-.1 1.4-.4v2.1c-1.1.3-1.5.3-2.2.3-1.9 0-2.9-1-2.9-3.3V62H106v-2.1h1.8v-4.3h2.6v4.3h2.4V62zM117.9 57.7h-2.6v-3h2.6v3zm0 15.8h-2.6V59.9h2.6v13.6zM124.2 59.9V62c.5-.8.8-1.1 1.1-1.4.6-.6 1.6-1.1 2.4-1.1.5 0 1 .1 1.4.3v2.3c-.5-.1-.8-.1-1.2-.1-2.1 0-3.7 1.9-3.7 4.3v7.1h-2.6V59.9h2.6z"
        className="sscc2"
      />
    </g>
  )
}

const ShareControlIcon = ({ className = '' }) => {
  const { appLocale } = useStoreSelector(SELECT.LANGUAGE)

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 139.5 85.2"
      width="53"
      height="34"
      className={className}
    >
      <defs>
        <style type="text/css">
          {`.sscc1 {
            fill:${loadThemeColor('--theme-color-primary')};
          }
          .sscc2 {
              fill:#FFFFFF;
          }`}
        </style>
      </defs>
      <path
        className="sscc1"
        d="M123.5,85.2H16c-8.8,0-16-7.2-16-16V16C0,7.2,7.2,0,16,0h107.5c8.8,0,16,7.2,16,16v53.2
      C139.5,78,132.3,85.2,123.5,85.2z"
      />
      <g>
        <path
          className="sscc2"
          d="M83,4.7v5.4c-8,2-19.5,7.5-23.2,19c9.3-8.2,22.4-7.3,23.2-7.2v5.4L97.6,16L83,4.7z"
        />
        <path
          className="sscc2"
          d="M85.5,42.6H51.1V9.3h20.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5H54v27.4h28.5V30c0-0.8,0.7-1.5,1.5-1.5
          c0.8,0,1.5,0.7,1.5,1.5V42.6z"
        />
      </g>
      {BUTTON_CONTENT_LOCALE_MAP[appLocale]}
    </svg>
  )
}

export default ShareControlIcon
