const DEFAULT_THEME_COLOR_PRIMARY = '#008c9e'

/* 
  https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#micro-functions-version-4
*/
const rgbShade = (p, c0) => {
  let i = parseInt,
    r = Math.round,
    [a, b, c] = c0.split(','),
    n = p < 0,
    t = n ? 0 : 255 * p,
    P = n ? 1 + p : 1 - p
  return {
    red: r(i(a[3] === 'a' ? a.slice(5) : a.slice(4)) * P + t),
    green: r(i(b) * P + t),
    blue: r(i(c) * P + t)
  }
}

const colorToHex = color => {
  var hexadecimal = color.toString(16)
  return hexadecimal.length === 1 ? '0' + hexadecimal : hexadecimal
}

const convertRGBtoHex = (red, green, blue) =>
  `#${colorToHex(red) + colorToHex(green) + colorToHex(blue)}`

export const convertHexToRGB = color => {
  const red = parseInt(color[1] + color[2], 16)
  const green = parseInt(color[3] + color[4], 16)
  const blue = parseInt(color[5] + color[6], 16)
  return `${red},${green},${blue}`
}

export const hexShade = (percentage, color) => {
  const rgb = `rgb(${convertHexToRGB(color)})`
  const shadedColor = rgbShade(percentage, rgb)
  return {
    hex: convertRGBtoHex(shadedColor.red, shadedColor.green, shadedColor.blue),
    rgb: `${shadedColor.red},${shadedColor.green},${shadedColor.blue}`
  }
}

export const loadThemeColor = (key, defaultColor = DEFAULT_THEME_COLOR_PRIMARY) =>
  getComputedStyle(document.documentElement).getPropertyValue(key).trim() || defaultColor
