import React from 'react'

import { loadThemeColor } from 'utils/color'

const RotatePhoneIcon = ({ className }) => {
  const themeColorPrimary = loadThemeColor('--theme-color-primary')
  const themeColorPrimaryLight = loadThemeColor('--theme-color-primary-light')

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 11.6 10.4"
      version="1.1"
      viewBox="0 0 11.6 10.4"
      xmlSpace="preserve"
      className={className}
    >
      <style type="text/css">
        {`.rpi1 { fill:${themeColorPrimaryLight}; }
        .rpi2 { fill:${themeColorPrimary}; }
        `}
      </style>
      <path
        d="M.3 8.2h4.2V.9H.3v7.3zm4.5-5.8v6.2c0 .1 0 .2-.1.3-.1.1-.2.2-.4.2H.4C.2 9.1 0 9 0 8.8V.5C0 .4 0 .3.1.2.2.1.3 0 .5 0h3.9c.2 0 .4.1.4.3v2.1z"
        className="rpi1"
      ></path>
      <defs>
        <path
          id="SVGID_1_"
          d="M.3 8.2h4.2V.9H.3v7.3zm4.5-5.8v6.2c0 .1 0 .2-.1.3-.1.1-.2.2-.4.2H.4C.2 9.1 0 9 0 8.8V.5C0 .4 0 .3.1.2.2.1.3 0 .5 0h3.9c.2 0 .4.1.4.3v2.1z"
        ></path>
      </defs>
      <clipPath id="SVGID_00000010277469322331475820000011375217989290590084_">
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g clipPath="url(#SVGID_00000010277469322331475820000011375217989290590084_)">
        <path d="M-88.1 -60.3H74.4V19.700000000000003H-88.1z" className="rpi1"></path>
        <defs>
          <path
            id="SVGID_00000036967873400079830230000006573770031045866686_"
            d="M-88.1 -60.3H74.4V19.700000000000003H-88.1z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000100344679561969145850000016577955544351794838_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000036967873400079830230000006573770031045866686_"
          ></use>
        </clipPath>
        <g clipPath="url(#SVGID_00000100344679561969145850000016577955544351794838_)">
          <path d="M-1.2 -1.2H6V10.3H-1.2z" className="rpi1"></path>
        </g>
      </g>
      <g>
        <defs>
          <path
            id="SVGID_00000042723298204790436130000012337872544015859390_"
            d="M2.2 4.9v4.2h7.3V4.9H2.2zm5.9 4.5H1.9c-.1 0-.2 0-.3-.1-.2-.1-.2-.2-.3-.4V5c0-.2.1-.4.3-.4H10c.1 0 .2 0 .3.1.1.1.2.2.2.4V9c0 .2-.1.4-.3.4H8.1z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000133521837306660065830000002363494959108425617_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000042723298204790436130000012337872544015859390_"
          ></use>
        </clipPath>
        <g clipPath="url(#SVGID_00000133521837306660065830000002363494959108425617_)">
          <defs>
            <path
              id="SVGID_00000044135807391662195800000017417750599718453147_"
              d="M-9.2 -83.5H70.8V79H-9.2z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000108997913422561517210000010134816091718158504_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000044135807391662195800000017417750599718453147_"
            ></use>
          </clipPath>
          <g clipPath="url(#SVGID_00000108997913422561517210000010134816091718158504_)">
            <path d="M0.2 3.4H11.7V10.6H0.2z" className="rpi2"></path>
          </g>
        </g>
      </g>
      <path
        d="M6.8 3.1l.7.7.7-.7h-.4c-.1-.4-.2-.7-.5-1-.4-.3-.9-.5-1.4-.5V2c.6-.1 1.2.4 1.4 1h-.5v.1z"
        className="rpi1"
      ></path>
    </svg>
  )
}

export default RotatePhoneIcon
