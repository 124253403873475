export const LOCAL_STORAGE_KEY = {
  SUBTITLES: 'storyfile:subtitles',
  VOICE_LANG: 'storyfile:voiceLang',
  VIDEO_QUALITY: 'storyfile:videoQuality'
}

export const SESSION_STORAGE_KEY = {
  INSTRUCTIONS: 'storyfile:instructionsShown',
  INTRO_VIDEO_PLAYED: 'storyfile:introVideoPlayed',
  REFRESH_EVENT: 'storyfile:refreshEvent',
  RECEIVER_INTERACTED: 'storyfile:receiverInteracted',
  TRANSMITTER_VOICE_LANG: 'storyfile:transmitterVoiceLang',
  TRANSMITTER_SUBS_LABEL: 'storyfile:transmitterSubsLabel'
}

const STORAGE_NOT_AVAILABLE_MSG = 'Browser storage is not available: '

class BrowserStorageService {
  constructor(storage) {
    this.storage = storage
  }

  getItem(itemName, defaultValue = null) {
    try {
      const item = this.storage.getItem(itemName) || defaultValue
      try {
        return JSON.parse(item)
      } catch (e) {
        return item
      }
    } catch (e) {
      console.log(STORAGE_NOT_AVAILABLE_MSG, e.message)
      return defaultValue
    }
  }

  setItem(itemName, item) {
    try {
      if (typeof item === 'object') {
        this.storage.setItem(itemName, JSON.stringify(item))
      } else {
        this.storage.setItem(itemName, item)
      }
    } catch (e) {
      console.log(STORAGE_NOT_AVAILABLE_MSG, e.message)
    }
  }

  removeItem(itemName) {
    try {
      this.storage.removeItem(itemName)
    } catch (e) {
      console.log(STORAGE_NOT_AVAILABLE_MSG, e.message)
    }
  }
}

// Handle security error for cases when user has explicitly disabled site storage
let sessionStore = null
let localStore = null
try {
  sessionStore = window.sessionStorage
  localStore = window.localStorage
} catch (e) {
  console.log(STORAGE_NOT_AVAILABLE_MSG, e.message)
}

export const sessionStorageService = new BrowserStorageService(sessionStore)
export const localStorageService = new BrowserStorageService(localStore)
