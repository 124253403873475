import { isTablet, isSafari } from 'react-device-detect'

import { appParamsService } from 'configuration'
import { translate } from 'components/Translation/Translation'
import { COMMON_OFF } from 'locales/translationIds'
import { sessionStorageService, SESSION_STORAGE_KEY } from 'utils/BrowserStorageService'

/** iOS fix on safari browser:
 *
 *   1. We are checking if it's safari
 *
 *    1.1 If TRUE => showAnswerPlayer = false and showCannotAnswerPlayer = false,
 *      we need to delay the rendering of the voice players (answer, cannotAnswer) and only waiting player is loaded into DOM.
 *
 *    1.2 If TRUE but iPad and there is an intro video => showAnswerPlayer = true and showCannotAnswerPlayer = false,
 *      answer and waiting players are loaded into DOM, otherwise intro video is not playing
 *
 *    1.3 If FALSE => showAnswerPlayer = true and showCannotAnswerPlayer = true,
 *      all of the 3 players are loaded into DOM
 */
export const shouldShowVoicePlayers = hasIntroVideo => {
  const showPlayers = {
    showAnswerPlayer: true,
    showCannotAnswerPlayer: true
  }

  if (isSafari) {
    showPlayers.showAnswerPlayer = isTablet && hasIntroVideo
    showPlayers.showCannotAnswerPlayer = false
  }

  return showPlayers
}

// add required props to subtitles - kind and default, default is the first one
export const processSubtitles = (subtitles, subtitlesLabel) =>
  !subtitles || subtitlesLabel === translate(COMMON_OFF)
    ? []
    : subtitles
        .filter(s => s.language === subtitlesLabel)
        .map((s, i) => ({
          ...s,
          kind: 'subtitles',
          src: s.url,
          default: i === 0,
          srcLang: s.language
        }))

export const shouldRePlayIntroVideo = introVideoUrl => {
  if (!appParamsService.hasMultipleIds) return true

  const playedIntroVideos = sessionStorageService.getItem(
    SESSION_STORAGE_KEY.INTRO_VIDEO_PLAYED,
    []
  )

  return !playedIntroVideos.includes(getIntroVideoUrlKey(introVideoUrl))
}

export const getIntroVideoUrlKey = introVideoUrl => new URL(introVideoUrl).pathname

/*
  Chrome & Edge: play() failed because the user didn't interact with the document first.
  Safari & Firefox: The (play/request) method is not allowed by the user agent or 
  the platform in the current context, possibly because the user denied permission. 
  Android WebView: play() can only be initiated by a user gesture.
*/
export const checkForInteractionError = ({ code, name, message }) =>
  code === 0 &&
  name === 'NotAllowedError' &&
  (message.includes('interact with the document first') ||
    message.includes('not allowed by the user agent or the platform in the current context') ||
    message.includes('can only be initiated by a user gesture'))
