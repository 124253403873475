import React, { useContext } from 'react'

import { appParamsService, envConfig } from 'configuration'
import Svg, { arrowThickLeft } from 'components/shared/Svg'
import { IS_FACEBOOK_ANDROID } from 'utils'
import Translation from 'components/Translation/Translation'
import { COMMON_BACK } from 'locales/translationIds'
import FullScreenButton from 'components/FullScreenButton/FullScreenButton'

import VideoScreenContext from '../../../../Context'
import Logo from '../../Logo/Logo'
import ClientLogo from '../../Logo/ClientLogo'
import ShareControl from '../../ShareControl/ShareControl'
import RecordingLabel from '../../RecordingLabel/RecordingLabel'

import styles from './Header.module.css'

const XCOPRI_SAFETY_BANNER_TEXT =
  'XCOPRI (cenobamate tablets) CV is indicated for partial-onset seizures in adults'
const {
  isModeUnbranded,
  isModeMedtronic,
  isModeSanta,
  isModeDefault,
  isModeLife,
  isModeTime,
  isModeXCOPRI,
  showShareControl,
  enableFullScreenButton
} = appParamsService
const shouldRenderLogo = !isModeSanta && !isModeMedtronic && !isModeLife && !isModeUnbranded

const Header = () => {
  const {
    state: { isPlayButtonOverlayVisible, isIntroPlaying, isRecording },
    handleBackButtonPress
  } = useContext(VideoScreenContext)

  // TODO: clean up logic here after PLAY_BUTTON_ON_ERROR is closed
  const renderWhenPlayButtonIsVisible =
    envConfig['PLAY_BUTTON_ON_ERROR'] || !isPlayButtonOverlayVisible
  const shouldRenderShareControl =
    showShareControl &&
    !isModeSanta &&
    !IS_FACEBOOK_ANDROID &&
    renderWhenPlayButtonIsVisible &&
    !isIntroPlaying &&
    !isRecording
  const shouldRenderBackButton =
    handleBackButtonPress &&
    !isModeDefault &&
    !isModeSanta &&
    !isRecording &&
    renderWhenPlayButtonIsVisible &&
    !isIntroPlaying
  const shouldRenderRecordingLabel = isRecording && (isModeDefault || isModeTime)

  return (
    <div className={styles.container}>
      {shouldRenderRecordingLabel && <RecordingLabel />}
      {shouldRenderBackButton && (
        <button className={styles.backButton} onClick={handleBackButtonPress}>
          <Svg className={styles.backButtonIcon} width={50} height={50} path={arrowThickLeft} />
          <div className={styles.backButtonLabel}>
            <Translation id={COMMON_BACK} />
          </div>
        </button>
      )}
      {shouldRenderShareControl && <ShareControl />}
      {isModeXCOPRI && <div className={styles.xcopriISIBanner}>{XCOPRI_SAFETY_BANNER_TEXT}</div>}
      <div className={styles.rightSection}>
        {enableFullScreenButton && <FullScreenButton className={styles.fullScreenButton} />}
        {shouldRenderLogo && (
          <>
            <ClientLogo />
            <Logo />
          </>
        )}
      </div>
    </div>
  )
}

export default Header
