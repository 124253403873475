import React from 'react'

// https://www.zondicons.com/icons.html
export const cheveronRight =
  'M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z'
export const cheveronDown =
  'M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'
export const play = 'M4 4l12 6-12 6z'
export const infoOutline =
  'M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z'
export const arrowOutlineLeft =
  'M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm2 0a8 8 0 1 0 16 0 8 8 0 0 0-16 0zm8-2h5v4h-5v3l-5-5 5-5v3z'
export const arrowOutlineRight =
  'M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z'
export const arrowThinDown =
  'M9 16.172l-6.071-6.071-1.414 1.414L10 20l.707-.707 7.778-7.778-1.414-1.414L11 16.172V0H9z'
export const arrowThinRight =
  'M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z'
export const arrowThinUp =
  'M9 3.828L2.929 9.899 1.515 8.485 10 0l.707.707 7.778 7.778-1.414 1.414L11 3.828V20H9V3.828z'
export const arrowThickLeft = 'M10 13h8V7h-8V2l-8 8 8 8v-5z'
export const checkMark = 'M0 11l2-2 5 5L18 3l2 2L7 18z'
export const close =
  'M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z'

export default function Svg({ className = '', path, viewBox = '0 0 20 20', width, height }) {
  return (
    <svg className={className} viewBox={viewBox} width={width} height={height} fill="currentColor">
      <path d={path} />
    </svg>
  )
}
