import React, { useEffect, useRef } from 'react'

import useAudioVisualizer from './useAudioVisualizer'

const AudioVisualizer = ({ stream }) => {
  const { startStream, stopStream } = useAudioVisualizer()
  const canvasRef = useRef()

  useEffect(() => {
    if (stream) {
      startStream(stream, canvasRef)
    } else {
      stopStream()
    }
  }, [stream])

  return <canvas id="canvas" height="50" width="150" ref={canvasRef}></canvas>
}

export default AudioVisualizer
