import { MessageTypes } from 'remote-control'

const log = () => {} //console.log.bind(console, '[Heartbeat]: ')
const PING_TIMEOUT = 15000

class Heartbeat {
  timeouts = new WeakMap()

  _onMessage = (type, connection) => {
    if (type !== MessageTypes.HEARTBEAT) return
    log(`<<- ${type}`)
    if (this.timeouts.has(connection)) {
      clearTimeout(this.timeouts.get(connection))
    }
    this._ping(connection)
  }

  _ping = connection => {
    setTimeout(() => {
      log(`->> ${MessageTypes.HEARTBEAT}`)
      connection.send({ type: MessageTypes.HEARTBEAT })
    }, PING_TIMEOUT / 3)
    this.timeouts.set(
      connection,
      setTimeout(() => {
        try {
          connection.close()
        } catch (e) {
          log(`Connection already closed`, connection)
        }
      }, PING_TIMEOUT)
    )
  }

  addConnection = (connection, start) => {
    connection.on('data', ({ type }) => this._onMessage(type, connection))
    if (start) {
      this._ping(connection)
    }
  }
}

export default Heartbeat
