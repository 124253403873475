import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import styles from './FullScreenButton.module.css'

const getVisible = () => {
  const fsEnabled =
    document.fullscreenEnabled === undefined ? 'webkitFullscreenEnabled' : 'fullscreenEnabled'
  const fsElement =
    document.fullscreenElement === undefined ? 'webkitFullscreenElement' : 'fullscreenElement'
  return document[fsEnabled] && !document[fsElement]
}

const requestFullscreen = () => {
  const fsReq =
    document.body.requestFullscreen === undefined ? 'webkitRequestFullscreen' : 'requestFullscreen'
  document.body[fsReq]()
}

const FullScreenButton = ({ className }) => {
  const [visible, setVisible] = useState(getVisible())

  const onFullScreenChange = () => setVisible(getVisible())

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange)
    document.addEventListener('webkitfullscreenchange', onFullScreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange)
      document.removeEventListener('webkitfullscreenchange', onFullScreenChange)
    }
  }, [])

  if (!visible) return null

  return <button className={classNames(styles.button, className)} onClick={requestFullscreen} />
}

export default FullScreenButton
