import { useEffect, useState, useRef } from 'react'

import { remoteControlTransmitter, remoteControlReceiver, MessageTypes } from 'remote-control'
import { appParamsService } from 'configuration'

let peer = null
if (appParamsService.rcTransmitter) peer = remoteControlTransmitter
if (appParamsService.rcReceiver) peer = remoteControlReceiver

export default function usePeerConnectionStatus() {
  const [status, setStatus] = useState(peer?.status)

  const broadcastChannelRef = useRef(
    peer
      ? undefined
      : new BroadcastChannel(appParamsService.rcReceiver || appParamsService.rcTransmitter)
  )

  useEffect(() => {
    if (peer) {
      peer.on(MessageTypes.CONNECTION_STATUS, setStatus)
      return () => {
        peer.off(MessageTypes.CONNECTION_STATUS, setStatus)
      }
    }

    const bc = broadcastChannelRef.current
    if (!bc) return

    bc.onmessage = event => {
      const { type, value } = event.data
      if (type === MessageTypes.CONNECTION_STATUS) {
        setStatus(value)
      }
    }

    return () => {
      bc.onmessage = null
    }
  }, [])

  return status
}
