import React from 'react'
import classNames from 'classnames'

import { translate } from 'components/Translation/Translation'
import {
  SCORE_NO_ASKED_QUESTIONS,
  SCORE_NUMBER_OF_QUESTIONS_MET_REQUIREMENTS,
  SCORE_NUMBER_OF_QUESTIONS_DIDNT_MEET_REQUIREMENTS
} from 'locales/translationIds'

import styles from './ScoreGradient.module.css'

const TOTAL_GRADIENT_WIDTH = 250

const ScoreGradient = ({ exceeds, meets, dontMeet }) => {
  const totalMetScore = exceeds + meets
  const totalCells = totalMetScore + dontMeet
  const cellWidth = TOTAL_GRADIENT_WIDTH / totalCells

  return (
    <div className={styles.scoreGradient}>
      {!!totalMetScore && (
        <div
          className={classNames(styles.scoreCell, styles.scoreCellMet)}
          style={{ width: cellWidth * totalMetScore }}
          title={translate(SCORE_NUMBER_OF_QUESTIONS_MET_REQUIREMENTS)}
        >
          {totalMetScore}
        </div>
      )}
      {!!dontMeet && (
        <div
          className={classNames(styles.scoreCell, styles.scoreCellNotMet)}
          style={{ width: cellWidth * dontMeet }}
          title={translate(SCORE_NUMBER_OF_QUESTIONS_DIDNT_MEET_REQUIREMENTS)}
        >
          {dontMeet}
        </div>
      )}
      {!totalMetScore && !dontMeet && (
        <div
          className={classNames(styles.scoreCell, styles.scoreCellEmpty)}
          style={{ width: TOTAL_GRADIENT_WIDTH }}
          title={translate(SCORE_NO_ASKED_QUESTIONS)}
        >
          0
        </div>
      )}
    </div>
  )
}

export default ScoreGradient
