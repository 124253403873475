import Cookie from 'js-cookie'

import { refreshApiToken } from 'api'
import { appParamsService } from 'configuration'

const COOKIE_STORAGE_KEY = 'WebApp.ApiToken'
let REFRESH_AUTH_INTERVAL
export const REFRESH_AUTH_INTERVAL_DURATION = 180000

let AUTH_DATA
let VIDEO_URLS_CACHE = false

class ApiToken {
  constructor(
    accessToken = '',
    refreshToken = '',
    expiresIn = 0,
    refreshExpiresIn = 0,
    ts = 0,
    scope,
    roles = [],
    iam,
    email = ''
  ) {
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.expiresIn = expiresIn
    this.refreshExpiresIn = refreshExpiresIn
    this.ts = ts
    this.scope = scope
    this.roles = roles
    this.iam = iam
    this.email = email
  }

  get isExpired() {
    return +new Date() + REFRESH_AUTH_INTERVAL_DURATION > (this.ts + this.expiresIn) * 1000
  }

  get isRefreshExpired() {
    return +new Date() > (this.ts + this.refreshExpiresIn) * 1000
  }

  get isValid() {
    return !this.isExpired
  }
}

// Workaround if browser cookies/storage is not available, e.g. Allego
const shouldStoreAuthInMemory = () => appParamsService.accessTokenStore === 'none'

export const storeAuthCookie = apiToken => {
  if (shouldStoreAuthInMemory()) {
    AUTH_DATA = apiToken
    return
  }

  Cookie.set(COOKIE_STORAGE_KEY, JSON.stringify(apiToken), { sameSite: 'none', secure: true })
}

const clearAuthCookie = () => {
  Cookie.remove(COOKIE_STORAGE_KEY)
}

export const loadAuthCookie = () => {
  if (shouldStoreAuthInMemory()) {
    return AUTH_DATA
  }

  const tokenStr = Cookie.get(COOKIE_STORAGE_KEY)
  return parseAuth(tokenStr)
}

export const appendAccessToken = urlStr => {
  if (!VIDEO_URLS_CACHE || appParamsService.isStaticApp) {
    return urlStr
  }

  if (VIDEO_URLS_CACHE.hasOwnProperty(urlStr)) {
    return VIDEO_URLS_CACHE[urlStr]
  }

  let accessToken
  if (appParamsService.isPrivate) {
    accessToken = appParamsService.accessToken
  } else {
    const authData = loadAuthCookie()
    accessToken = authData && authData.isValid && authData.accessToken
  }

  try {
    const url = new URL(urlStr)
    url.searchParams.set('access_token', accessToken)
    VIDEO_URLS_CACHE[urlStr] = url.href
    return url.href
  } catch (e) {
    console.error(`Error appending access token to ${urlStr}.`, e)
    return urlStr
  }
}

export const parseAuth = data => {
  if (!data) {
    return
  }

  const { accessToken, refreshToken, expiresIn, refreshExpiresIn, ts, scope, roles, iam, email } =
    typeof data === 'string' ? JSON.parse(data) : data

  return new ApiToken(
    accessToken,
    refreshToken,
    expiresIn,
    refreshExpiresIn,
    ts,
    scope,
    roles,
    iam,
    email
  )
}

export const refreshVideoURLsCache = () => {
  VIDEO_URLS_CACHE = {}
}

export const refreshAuth = async (authData = loadAuthCookie()) => {
  if (!authData) {
    clearAuth()
    return null
  }

  try {
    const refreshRes = await refreshApiToken(authData)

    if (!refreshRes || refreshRes.error) {
      clearAuth()
      return
    }

    const auth = parseAuth(refreshRes.data.data)
    storeAuthCookie(auth)
    refreshVideoURLsCache()
    return auth
  } catch (error) {
    clearAuth()
  }
}

export const setAuthRefreshInterval = () => {
  if (!REFRESH_AUTH_INTERVAL) {
    REFRESH_AUTH_INTERVAL = setInterval(refreshAuth, REFRESH_AUTH_INTERVAL_DURATION)
  }
}

const clearAuth = () => {
  clearAuthCookie()
  VIDEO_URLS_CACHE = false
  REFRESH_AUTH_INTERVAL = clearInterval(REFRESH_AUTH_INTERVAL)
}
