import React from 'react'

import useHexShade from 'hooks/useHexShade'
import { useStoreSelector, SELECT } from 'providers/StoreProvider'
import { loadThemeColor } from 'utils/color'

const BUTTON_CONTENT_LOCALE_MAP = {
  en: (
    <path
      d="M94.39 47.55v-.83c0-4.25-3.34-6.68-9.11-6.68-5.39 0-8.65 1.97-8.65 5.16 0 2.13 1.52 3.57 4.63 4.33l10.24 2.35c5.62 1.37 8.73 2.73 11.15 5.01 2.58 2.35 3.87 5.62 3.87 9.64 0 10.47-8.04 16.92-21.17 16.92S63.8 76.92 63.72 65.62H75.1c.23 5.77 3.49 8.35 10.4 8.35 5.84 0 9.41-2.13 9.41-5.69 0-2.5-1.82-4.25-5.46-5.16l-9.18-2.28c-5.39-1.29-8.73-2.73-11-4.7-2.58-2.12-4.02-5.84-4.02-9.94 0-9.79 7.51-15.63 20.34-15.63 8.19 0 13.96 2.43 17.22 7.21 1.67 2.5 2.5 5.24 2.96 9.79H94.39zM159.57 82.31h-13.66l-15.33-22.92-4.7 4.78v18.14h-11.15V31.69h11.15v18.67l17.07-18.67h14.95l-19.73 19.96 21.4 30.66zM177.42 82.31h-11.38V31.69h11.38V82.3zM200.5 82.31h-11.38V31.69H209c6.91 0 10.55.99 13.81 3.87 3.49 3.11 5.46 7.74 5.46 13.2 0 4.63-1.44 8.65-4.33 11.69-3.04 3.19-6.91 4.63-12.75 4.63h-10.7v17.23zm8.95-26.94c4.48 0 7.21-2.66 7.21-6.98s-2.73-6.98-7.21-6.98h-8.95v13.96h8.95z"
      className="skig"
    />
  ),
  es: (
    <path
      d="M53.6 79.9H43.3V34.1h18c6.3 0 9.6.9 12.5 3.5 3.2 2.8 5 7 5 12 0 4.2-1.3 7.8-3.9 10.6-2.8 2.9-6.3 4.2-11.6 4.2h-9.7v15.5zm8.1-24.4c4.1 0 6.5-2.4 6.5-6.3 0-4-2.5-6.3-6.5-6.3h-8.1v12.7h8.1zM90 70.7l-2.9 9.2H75.9l16-45.9h12.9l14.6 45.9h-11.7l-2.5-9.2H90zm8.1-26.4l-5.6 17.9h10.4l-4.8-17.9zM149.5 48.4v-.8c0-3.9-3-6.1-8.3-6.1-4.9 0-7.8 1.8-7.8 4.7 0 1.9 1.4 3.2 4.2 3.9l9.3 2.1c5.1 1.2 7.9 2.5 10.1 4.5 2.3 2.1 3.5 5.1 3.5 8.7 0 9.5-7.3 15.3-19.2 15.3-12.4 0-19.5-5.9-19.6-16.2H132c.2 5.2 3.2 7.6 9.4 7.6 5.3 0 8.5-1.9 8.5-5.2 0-2.3-1.7-3.9-5-4.7l-8.3-2.1c-4.9-1.2-7.9-2.5-10-4.3-2.3-1.9-3.6-5.3-3.6-9 0-8.9 6.8-14.2 18.4-14.2 7.4 0 12.7 2.2 15.6 6.5 1.5 2.3 2.3 4.7 2.7 8.9h-10.2zM177 70.7l-2.9 9.2h-11.2l16-45.9h12.9l14.6 45.9h-11.7l-2.5-9.2H177zm8.2-26.4l-5.6 17.9H190l-4.8-17.9zM221.4 79.9h-10.1V34.1h20c5.9 0 9.2.8 12 2.8 2.8 2.1 4.4 5.5 4.4 9.6 0 5.6-2.5 9.1-7.6 11.1 4.5 1.4 6.1 3.9 6.5 9.9l.3 6.5c.2 3 .6 4.1 1.9 6h-10.1c-1.2-1-1.6-1.6-1.7-4l-.4-6.3c-.4-5.6-2.9-8.1-8.2-8.1h-6.9v18.3zm10.1-26.4c3.8 0 5.8-1.9 5.8-5.3s-2.1-5.3-5.8-5.3h-10.1v10.6h10.1z"
      className="skig"
    />
  )
}

const SkipIntroIcon = () => {
  const { appLocale } = useStoreSelector(SELECT.LANGUAGE)
  const themeColorPrimary = loadThemeColor('--theme-color-primary')
  const { hex: griadientColor } = useHexShade(-0.2, themeColorPrimary)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 292 114">
      <defs>
        <style>
          {`.skid { fill:url(#c); }
          .skie { fill:none; }
          .skif { fill:${themeColorPrimary}; }
          .skig { fill:#fff; }
          .skih { clip-path:url(#b); }`}
        </style>
        <clipPath id="b">
          <rect width="292" height="114" y="-1.63" className="skie" rx="57" ry="57"></rect>
        </clipPath>
        <linearGradient
          id="c"
          x1="146"
          x2="146"
          y1="811.59"
          y2="718.87"
          gradientTransform="translate(0 -706)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={griadientColor} stopOpacity="0"></stop>
          <stop offset="0.03" stopColor={griadientColor} stopOpacity="0.16"></stop>
          <stop offset="0.1" stopColor={griadientColor} stopOpacity="0.46"></stop>
          <stop offset="0.17" stopColor={griadientColor} stopOpacity="0.69"></stop>
          <stop offset="0.23" stopColor={griadientColor} stopOpacity="0.86"></stop>
          <stop offset="0.29" stopColor={griadientColor} stopOpacity="0.96"></stop>
          <stop offset="0.33" stopColor={griadientColor}></stop>
          <stop offset="0.71" stopColor={griadientColor}></stop>
          <stop offset="0.74" stopColor={griadientColor} stopOpacity="0.95"></stop>
          <stop offset="0.79" stopColor={griadientColor} stopOpacity="0.83"></stop>
          <stop offset="0.86" stopColor={griadientColor} stopOpacity="0.62"></stop>
          <stop offset="0.93" stopColor={griadientColor} stopOpacity="0.33"></stop>
          <stop offset="1" stopColor={griadientColor} stopOpacity="0"></stop>
        </linearGradient>
      </defs>
      <rect width="292" height="114" className="skif" rx="57" ry="57"></rect>
      <g className="skih">
        <path
          d="M15.96 7.04h260.08c8.78 0 15.96 9.25 15.96 20.55v64.23c0 11.3-7.18 20.55-15.96 20.55H15.96C7.18 112.37 0 103.12 0 91.82V27.59C0 16.03 7.18 7.04 15.96 7.04z"
          className="skid"
        ></path>
      </g>
      {BUTTON_CONTENT_LOCALE_MAP[appLocale]}
    </svg>
  )
}

export default SkipIntroIcon
