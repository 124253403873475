import { useCallback, useRef } from 'react'

import { appParamsService } from 'configuration'
import getInternetSpeed from 'utils/getInternetSpeed'
import { localStorageService, LOCAL_STORAGE_KEY } from 'utils/BrowserStorageService'
import { debugModeTime, debugModeTimeEnd, LOG_PREFIX } from 'utils/debugModeLogger'

const THRESHOLD_1080 = 1500
const THRESHOLD_720 = 1100
const THRESHOLD_480 = 300

const { quality: defaultQuality, disableDynamicQuality } = appParamsService

const determineVideoQuality = speed => {
  if (defaultQuality >= 1080 && speed > THRESHOLD_1080) return 1080
  if (defaultQuality >= 720 && speed > THRESHOLD_720) return 720
  if (defaultQuality >= 480 && speed > THRESHOLD_480) return 480
  return 360
}

const useVideoQuality = () => {
  const quality = useRef(defaultQuality)

  const checkQuality = async () => {
    debugModeTime(`${LOG_PREFIX} Checked optimal video quality`)
    if (disableDynamicQuality) {
      console.log(`Dynamic quality detection is disabled`)
      return
    }
    const speed = await getInternetSpeed()
    quality.current = determineVideoQuality(speed)
    localStorageService.setItem(LOCAL_STORAGE_KEY.VIDEO_QUALITY, quality.current)
    console.log(`Dynamic video quality set at: ${quality.current}`)
    debugModeTimeEnd(`${LOG_PREFIX} Checked optimal video quality`)
  }

  const getQuality = useCallback(() => quality.current, [])

  return { getQuality, checkQuality }
}

export default useVideoQuality
