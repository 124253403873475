import { useState, useEffect, useRef } from 'react'
import { flushSync } from 'react-dom'
import RecordRTC, { StereoAudioRecorder } from 'recordrtc' // invokeSaveAsDialog
import { isMobile, isChrome } from 'react-device-detect'

import { googleSpeechRecognizer } from 'api/recognizeSpeech'
import { SOURCE_OF_INTERACTION } from 'containers/VideoScreen/constants'
import { defer, wait } from 'helpers'
import { appParamsService } from 'configuration'
import { translate } from 'components/Translation/Translation'
import { COMMON_COULD_NOT_RECOGNIZE_YOUR_SPEECH } from 'locales/translationIds'
import { postEventToParent, WINDOW_MESSAGES } from 'utils'
import { debugModeTime, debugModeTimeEnd, LOG_PREFIX } from 'utils/debugModeLogger'

import { alertMicrophoneErrorMessage, MIC_ERROR_MESSAGE } from './utils'
import { useMicPermissionsPrompt } from './useMicPermissionsPrompt'
import { postTranscriptSanitize } from 'api'

const { isModeSanta, testBluetoothConfigs, asrVendor, testNewSpeech2Text, persistMicrophone } =
  appParamsService
const isMobileChrome = isMobile && isChrome

const WAIT_FOR_FINAL_TRANSCRIPTION = 2000
const LONG_PRESS_TIMEOUT_DURATION = isMobile ? 100 : 300

// Removes all [.?] punctuation except at the end of the string
const EXTRA_PUNCTUATION_REMOVE_REGEX = /[.?](?=.*[.?])/g
const XCOPRI_STORYFILE_ID = 7137

const useRecognizeSpeech = (
  googleASRTrainingData,
  lang = 'en-US',
  shouldInitialize = true,
  storyfileId = appParamsService.storyFileIds[0]
) => {
  const [mediaStream, setMediaStream] = useState(null)
  const [{ transcription, isRecording, isRecordingInitiated }, setState] = useState({
    committedTranscription: '',
    transcription: '',
    isSessionFinished: false,
    isRecording: false,
    isRecordingInitiated: false,
    provider: ''
  })

  const {
    micPermissionPending,
    clearMicPermissionPending,
    micPermissionGranted,
    setMicPermissionGranted,
    checkForPermissionsPrompt
  } = useMicPermissionsPrompt()

  const recognitionFinalResult = useRef()
  const micStreamRef = useRef()
  const recordAudioRef = useRef()
  const longPressTimeoutRef = useRef()

  const getMicrophone = async checkAvailability => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      let errorMsg = isModeSanta ? MIC_ERROR_MESSAGE.NO_MIC_SANTA : MIC_ERROR_MESSAGE.NO_MIC_DEFAULT

      if (!window.isSecureContext) {
        errorMsg = MIC_ERROR_MESSAGE.NON_SECURE_CONTEXT
      }

      alert(translate(errorMsg))
      return
    }

    if (mediaStream && persistMicrophone) {
      // unmute the microphone
      mediaStream.getAudioTracks().forEach(track => {
        track.enabled = true
      })

      return mediaStream
    }

    try {
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: testBluetoothConfigs
          ? {
              echoCancellation: false
            }
          : true
      })

      if (checkAvailability) {
        micStream.getTracks().forEach(track => {
          track.stop()
        })
        setMediaStream(null)
        return
      }

      setMediaStream(micStream)
      return micStream
    } catch (err) {
      console.error('microphone not available ', err)
      throw err
    }
  }

  useEffect(() => {
    if (!shouldInitialize) {
      return
    }

    googleSpeechRecognizer.connect()

    const loadMicrophone = async () => {
      try {
        await getMicrophone(true)
        setMicPermissionGranted(true)
      } catch (err) {
        clearMicPermissionPending() // Close on denied permissions to not clash with auto-opening AskForm
        setMicPermissionGranted(false)
        alertMicrophoneErrorMessage(err)
      }
    }

    checkForPermissionsPrompt()
    loadMicrophone()
  }, [
    setMicPermissionGranted,
    checkForPermissionsPrompt,
    clearMicPermissionPending,
    shouldInitialize
  ])

  const startListeningAfterDelay = (delay = LONG_PRESS_TIMEOUT_DURATION) => {
    longPressTimeoutRef.current = setTimeout(startListening, delay)
  }

  const recognitionListener = async msg => {
    if (msg.error) {
      setState(prevState => ({
        ...prevState,
        transcription: translate(COMMON_COULD_NOT_RECOGNIZE_YOUR_SPEECH)
      }))

      return
    }

    setState(prevState => {
      const { isSessionFinished = false, committedTranscription = '' } = prevState
      if (isSessionFinished) return prevState

      if (testNewSpeech2Text && (msg.provider === 'Deepgram' || msg.provider === 'Seasalt')) {
        console.log('New utterance:', `[${msg.utterance}]`)

        if (recognitionFinalResult.current && msg.speechFinal) {
          recognitionFinalResult.current.resolve(msg.utterance)
        }

        return {
          ...prevState,
          provider: msg.provider,
          committedTranscription: msg.utterance,
          transcription: msg.utterance
        }
      } else {
        // Google and DeepGram ASR engines return results differently - test for both when making changes
        let newCommittedTranscription = committedTranscription
        if (msg.isFinal && msg.text) {
          newCommittedTranscription = `${newCommittedTranscription} ${msg.text.trim()}`.replace(
            EXTRA_PUNCTUATION_REMOVE_REGEX,
            ''
          )
        }

        if (recognitionFinalResult.current && msg.isFinal) {
          recognitionFinalResult.current.resolve(newCommittedTranscription)
        }

        return {
          ...prevState,
          committedTranscription: newCommittedTranscription,
          transcription: (committedTranscription + msg.text).replace(
            EXTRA_PUNCTUATION_REMOVE_REGEX,
            ''
          )
        }
      }
    })
  }

  const getFinalTranscription = async (transcription, context) => {
    if (context.source !== SOURCE_OF_INTERACTION.VOICE) return transcription

    recognitionFinalResult.current = defer()

    let finalTranscription = await Promise.race([
      recognitionFinalResult.current,
      wait(transcription, WAIT_FOR_FINAL_TRANSCRIPTION)
    ])

    if (!testNewSpeech2Text && storyfileId === XCOPRI_STORYFILE_ID) {
      try {
        const response = await postTranscriptSanitize(storyfileId, finalTranscription)
        finalTranscription = response.data.transcript

        setState(prevState => ({
          ...prevState,
          transcription: finalTranscription
        }))
      } catch (error) {
        // do nothing
      }
    }

    if (testNewSpeech2Text) {
      console.log('Final transcription:', `[${finalTranscription}]`)
    }
    return finalTranscription
  }

  const startListening = async () => {
    postEventToParent({ type: WINDOW_MESSAGES.IS_LISTENING, value: true })
    flushSync(() => {
      setState(prevState => ({ ...prevState, isRecordingInitiated: true }))
    })

    try {
      debugModeTime(`${LOG_PREFIX} Obtained microphone stream`)
      micStreamRef.current = await getMicrophone()
      debugModeTimeEnd(`${LOG_PREFIX} Obtained microphone stream`)
    } catch (e) {
      return
    }

    let recOptions = {
      type: 'audio',
      mimeType: 'audio/webm',
      sampleRate: 44100,
      desiredSampRate: 16000,
      recorderType: StereoAudioRecorder,
      numberOfAudioChannels: 1,
      timeSlice: 500,
      ondataavailable: function (blob) {
        googleSpeechRecognizer.recognize(blob)
      },
      disableLogs: true
    }

    if (testBluetoothConfigs) {
      const audioTrack = micStreamRef.current.getAudioTracks()[0]
      console.log(audioTrack)
      if (audioTrack.getCapabilities) console.log('Capabilities:', audioTrack.getCapabilities())
      console.log('Settings:', audioTrack.getSettings())

      // Only Chrome/Edge specify in label that device is bluetooth
      // let isBluetoothDevice = false
      // if (audioTrack.kind === 'audio' && audioTrack.label.includes('Bluetooth')) {
      //   // @TODO: Can show warning for suboptimal performance
      //   isBluetoothDevice = true
      // }

      const audioTrackSettings = audioTrack.getSettings()
      recOptions.disableLogs = false
      if (!isMobileChrome) {
        recOptions.sampleRate = audioTrackSettings.sampleRate
      }
      // @TODO: Currently broken only for Safari on macOS, needs more research
    }

    recordAudioRef.current = RecordRTC(micStreamRef.current, recOptions)

    googleSpeechRecognizer.start(
      msg => recognitionListener(msg),
      googleASRTrainingData,
      lang,
      asrVendor,
      storyfileId
    )

    debugModeTime(`${LOG_PREFIX} Started recording`)
    recordAudioRef.current.startRecording()
    debugModeTimeEnd(`${LOG_PREFIX} Started recording`)

    setState(prevState => ({ ...prevState, isSessionFinished: false, isRecording: true }))
  }

  const stopListening = () => {
    if (longPressTimeoutRef.current) {
      clearTimeout(longPressTimeoutRef.current)
      longPressTimeoutRef.current = null
    }

    if (recordAudioRef.current) {
      recordAudioRef.current.stopRecording(() => {
        googleSpeechRecognizer.stop()
        console.log('Recording stop')
      })

      // Uncomment to download the audio file for debugging
      // const result = await recordAudioRef.current.getBlob()
      // invokeSaveAsDialog(result)
      recordAudioRef.current.destroy()
      recordAudioRef.current = null
    }

    if (micStreamRef.current) {
      if (persistMicrophone) {
        // mute the microphone
        micStreamRef.current.getAudioTracks().forEach(track => {
          track.enabled = false
        })
      } else {
        micStreamRef.current.getTracks().forEach(track => {
          track.stop()
        })
      }
    }

    postEventToParent({ type: WINDOW_MESSAGES.IS_LISTENING, value: false })
    setState(prevState => ({ ...prevState, isRecording: false, isRecordingInitiated: false }))
  }

  const resetTranscription = () => {
    recognitionFinalResult.current = null
    setState(prevState => ({
      ...prevState,
      committedTranscription: '',
      transcription: '',
      isSessionFinished: true,
      isRecording: false
    }))
  }

  return {
    transcription,
    isRecording,
    isRecordingInitiated,
    startListeningAfterDelay,
    stopListening,
    resetTranscription,
    getFinalTranscription,
    micPermissionGranted,
    micPermissionPending,
    clearMicPermissionPending,
    mediaStream
  }
}

export default useRecognizeSpeech
