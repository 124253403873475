import { useMemo, useContext, isValidElement } from 'react'

import { StoryFileContext } from 'App'
import { translate } from 'components/Translation/Translation'
import {
  COMMON_INSTRUCTIONS,
  COMMON_HAPPY_STORYFILING,
  INSTRUCTIONS_HOLD_DOWN_ANYWHERE,
  INSTRUCTIONS_RED_LIGHT_INDICATES,
  INSTRUCTIONS_RELEASE_WHEN_YOUR_QUESTION,
  INSTRUCTIONS_INTERRUPT_RESPONSE_BY_CLICKING
} from 'locales/translationIds'

const INSTRUCTIONS_DELIMITER = ' $'
const EMPTY_SECTION = ' '

const resolvePageContent = homePageContent => {
  const defaultPageContent = {
    headerText: '',
    instructionsTitle: translate(COMMON_INSTRUCTIONS),
    instructions: (
      <>
        <li>{translate(INSTRUCTIONS_HOLD_DOWN_ANYWHERE)}</li>
        <li>{translate(INSTRUCTIONS_RED_LIGHT_INDICATES)}</li>
        <li>{translate(INSTRUCTIONS_RELEASE_WHEN_YOUR_QUESTION)}</li>
        <li>{translate(INSTRUCTIONS_INTERRUPT_RESPONSE_BY_CLICKING)}</li>
      </>
    ),
    footerText: translate(COMMON_HAPPY_STORYFILING)
  }

  return Object.keys(defaultPageContent).reduce((acc, section) => {
    acc[section] = homePageContent?.[section]
      ? homePageContent[section]
      : defaultPageContent[section]
    return acc
  }, {})
}

export const isSectionEmpty = section => section === EMPTY_SECTION

const usePageContent = () => {
  const { homePageContent } = useContext(StoryFileContext)

  return useMemo(() => {
    const content = resolvePageContent(homePageContent)
    const { instructions } = content
    content.instructions =
      !isSectionEmpty(instructions) && isValidElement(instructions)
        ? instructions
        : instructions.split(INSTRUCTIONS_DELIMITER).map(instruction => <li>{instruction}</li>)

    return content
  }, [homePageContent])
}

export default usePageContent
