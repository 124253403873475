import { appParamsService, envConfig } from 'configuration'
import { URL_PARAMS } from 'constants'

export const generateShareUrl = (transcription, storyFileId) => {
  let url

  if (appParamsService.isModeLife) {
    url = new URL(envConfig.STORYFILE_LIFE_URL + '/interact')
    url.searchParams.set('sfid', storyFileId)
  } else {
    url = new URL(window.location.href)
    url.searchParams.set(URL_PARAMS.uid, storyFileId)
    if (transcription) {
      url.searchParams.set(URL_PARAMS.st, window.btoa(transcription))
    }
  }

  return url.href
}

export const generateQuoteMeta = (transcription, username) =>
  appParamsService.isModeLife
    ? undefined
    : transcription
    ? username + ' - ' + transcription
    : username
