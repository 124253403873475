import Peer from 'peerjs'
import EventEmitter from 'events'

import { appParamsService } from 'configuration'
import { translate } from 'components/Translation/Translation'
import {
  COMMON_CONNECTED_TO,
  COMMON_CONNECTING_TO,
  COMMON_DISCONNECTED
} from 'locales/translationIds'
import { refreshPageWithStoryFile } from 'utils'

import MessageTypes from './MessageTypes'
import Heartbeat from './Heartbeat'

const log = console.log.bind(console, '[RemoteControlReceiver]')
const RECONNECT_TIMEOUT = 3000
export const RECEIVER_STATE = {
  CONNECTING: 0,
  CONNECTED: 1,
  DISCONNECTED: 2
}

class RemoteControlReceiver extends EventEmitter {
  /**
   *
   * @param transmitterId - id of the transmitter we want to connect to
   */
  constructor(transmitterId, config) {
    super()
    this.transmitterId = transmitterId
    this.config = config
    this.peer = new Peer()
    this.peer.on('open', this._connect)
    this.peer.on('error', this._handleError)
    this.status = ''
    this.heartbeat = new Heartbeat()
  }

  _handleError = error => {
    log('Error', error.type, error)
    this._setDisconnected()
    this._reconnect()
  }

  _setConnecting = () => {
    this.status = {
      state: RECEIVER_STATE.CONNECTING,
      label: translate(COMMON_CONNECTING_TO, [this.transmitterId])
    }
    this.emit(MessageTypes.CONNECTION_STATUS, this.status)
  }

  _setConnected = () => {
    this.status = {
      state: RECEIVER_STATE.CONNECTED,
      label: translate(COMMON_CONNECTED_TO, [this.transmitterId])
    }
    this.emit(MessageTypes.CONNECTION_STATUS, this.status)
  }

  _setDisconnected = () => {
    this.status = { state: RECEIVER_STATE.DISCONNECTED, label: translate(COMMON_DISCONNECTED) }
    this.emit(MessageTypes.CONNECTION_STATUS, this.status)
  }

  _reconnect = () => {
    setTimeout(this._connect, RECONNECT_TIMEOUT)
  }

  _connect = () => {
    log('connecting to', this.transmitterId, 'config:', this.config)
    this._setConnecting()

    // @TODO: Debug `Cannot create so many PeerConnections` error
    try {
      this.connection = this.peer.connect(this.transmitterId, {
        ...this.config,
        serialization: 'json'
      })
    } catch (e) {
      console.error('Failed connecting peer.', e)
    }

    if (!this.connection) {
      return
    }

    this.connection.on('open', () => {
      log('connected to', this.transmitterId)
      this._setConnected()
      this.heartbeat.addConnection(this.connection)
      this._send({ type: MessageTypes.RECEIVER_CONNECTED })
    })
    this.connection.on('data', event => {
      //avoid spam
      if (event.type === MessageTypes.HEARTBEAT) {
        return
      } else if (event.type === MessageTypes.RECEIVER_CONNECTED) {
        refreshPageWithStoryFile(event.value)
      }
      log('<- received:', event)
      this.emit(event.type, event.value)
    })
    this.connection.on('close', () => {
      log('disconnected from', this.transmitterId)
      this._setDisconnected()
      this._reconnect()
    })
  }

  _send = message => {
    if (!this.connection) {
      return
    }

    log('-> sending:', message)
    this.connection.send(message)
  }

  sendVideoStarted = () => {
    this._send({ type: MessageTypes.VIDEO_STARTED })
  }

  sendVideoEnded = () => {
    this._send({ type: MessageTypes.VIDEO_ENDED })
  }

  destroyConnection = () => {
    console.log(`Destroying peer ${this}`)
    this.peer.destroy()
    this.connection = null
    this._setDisconnected()
  }
}

export const remoteControlReceiver =
  appParamsService.rcReceiver &&
  (appParamsService.rcWired
    ? undefined
    : new RemoteControlReceiver(appParamsService.rcReceiver, {
        reliable: true
      }))
