import { appParamsService } from 'configuration'
import toggleButtonImage from 'assets/images/BottomDrawer/up-arrow-circle.svg'
import stephen from 'assets/images/BottomDrawer/icon-stephen.png'
import josie from 'assets/images/BottomDrawer/icon-josie.png'
import link from 'assets/images/BottomDrawer/icon-url-link.png'
import videoImg from 'assets/images/BottomDrawer/icon-video.png'
import downloadImg from 'assets/images/BottomDrawer/icon-download.png'
import questionImg from 'assets/images/BottomDrawer/icon-question.png'

import { DRAWER_ACTIONS } from './BottomDrawer'

const { storyFileIds } = appParamsService

const CONFIGS = [
  {
    storyfileIds: [4864, 5582],
    hideCurrentSfFromButtons: false,
    toggleImage: toggleButtonImage,
    buttons: [
      {
        image: josie,
        action: { type: DRAWER_ACTIONS.STORYFILE, value: 5582 },
        tooltip: 'Conversa FAQ StoryFile'
      },
      {
        image: downloadImg,
        action: {
          type: DRAWER_ACTIONS.URL,
          value: 'https://storyfile.com/wp-content/uploads/2022/04/Conversa-Whitepaper-PRICING.pdf'
        },
        tooltip: 'Conversa White Paper'
      },
      {
        image: videoImg,
        action: { type: DRAWER_ACTIONS.URL, value: 'https://www.youtube.com/embed/aCrOPz5-nqo' },
        tooltip: 'Conversa Explained (Video)'
      },
      {
        image: link,
        action: {
          type: DRAWER_ACTIONS.URL,
          value:
            'https://storyfile.com/wp-content/uploads/2022/11/ConversaSalesDecks.pdf?utm_source=SF-ConversaFAQ&utm_medium=pdf&utm_id=conversa-deck'
        },
        tooltip: 'Conversa Presentation'
      },
      {
        image: questionImg,
        action: { type: DRAWER_ACTIONS.URL, value: 'https://storyfile.com/free-conversa-demo/' },
        tooltip: 'Schedule a Demo'
      },
      {
        image: stephen,
        action: { type: DRAWER_ACTIONS.STORYFILE, value: 4864 },
        tooltip: 'Meet a Founder'
      }
    ]
  }
]

export const getBottomDrawerConfig = () =>
  CONFIGS.find(cfg => cfg.storyfileIds.includes(storyFileIds[0]))
