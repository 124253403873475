import React from 'react'

import TextInput from 'components/TextInput/TextInput'
import Translation, { translate } from 'components/Translation/Translation'
import FormButton, { STYLING_TYPES } from 'components/FormButton/FormButton'
import { appParamsService, envConfig } from 'configuration'
import useKeycloak, { KeycloakIDP } from 'hooks/useKeycloak'
import {
  AUTH_COMMON_MEMBER_LOGIN,
  AUTH_COMMON_RESET_HELPER_TEXT,
  AUTH_COMMON_PASSWORD_RESET,
  AUTH_COMMON_BACK_TO_LOGIN,
  AUTH_COMMON_FORGOT_PASSWORD,
  AUTH_COMMON_EMAIL,
  AUTH_COMMON_PASSWORD,
  AUTH_COMMON_SEND,
  AUTH_COMMON_LOGIN
} from 'locales/translationIds'

import MetaMaskForm from '../MetaMaskForm/MetaMaskForm'
import styles from './LoginForm.module.css'
import SSOButton from './SSOButton'
import SingleSignOnButtons from '../SingleSignOnButtons/SingleSignOnButtons'

const { SCORMWrapper, isModeMedtronic, useKeycloakSSO, loginMode } = appParamsService

const resetHelperText = (
  <>
    <br />
    <br /> <Translation id={AUTH_COMMON_RESET_HELPER_TEXT} />
  </>
)

const LoginForm = ({
  onLoginSubmit,
  onCryptoLoginSubmit,
  onResetSubmit,
  showResetForm,
  toggleResetForm,
  loading,
  setLoading,
  errorMsg,
  ssoLoading,
  startSSOLoading,
  startPollingAuthStoryFile,
  onLoginSuccess
}) => {
  const { doLogin, getLoginUrl, initKeycloakInstance } = useKeycloak(
    useKeycloakSSO,
    useKeycloakSSO,
    onLoginSuccess
  )

  const handleSubmit = async e => {
    e.preventDefault()
    if (loading) {
      return
    }

    const { email, password } = e.target.elements
    if (showResetForm) {
      onResetSubmit(email.value)
      return
    }
    onLoginSubmit(email.value, password.value)
  }

  const handleSSOLogin = idp => {
    startSSOLoading()

    if (SCORMWrapper) {
      // Open IDP auth page in a new window since it gets blocked within an iframe
      startPollingAuthStoryFile(600, initKeycloakInstance)
      window.open(getLoginUrl(idp), '_blank')
      return
    }

    doLogin(idp)
  }

  if (isModeMedtronic && useKeycloakSSO) {
    return <SSOButton loading={ssoLoading} onClick={() => handleSSOLogin(KeycloakIDP.Medtronic)} />
  }

  // Walmart SSO
  if (envConfig['ENVIRONMENT'] === 'prod-azure' && useKeycloakSSO) {
    return <SSOButton loading={ssoLoading} onClick={() => handleSSOLogin(KeycloakIDP.Walmart)} />
  }

  const cryptoMode = loginMode === 'crypto'

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.content}>
          <div className={styles.title}>
            <Translation
              id={showResetForm ? AUTH_COMMON_PASSWORD_RESET : AUTH_COMMON_MEMBER_LOGIN}
            />
          </div>
          {!cryptoMode && (
            <p className={styles.helperText}>
              <FormButton
                text={
                  <Translation
                    id={showResetForm ? AUTH_COMMON_BACK_TO_LOGIN : AUTH_COMMON_FORGOT_PASSWORD}
                  />
                }
                styling={STYLING_TYPES.link}
                onClick={toggleResetForm}
                elementId={showResetForm ? 'login-link' : 'forgot-password-link'}
              />
              {showResetForm && resetHelperText}
            </p>
          )}
        </div>
      </div>
      {cryptoMode ? (
        <MetaMaskForm onSubmit={onCryptoLoginSubmit} loading={loading} setLoading={setLoading} />
      ) : (
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.error}>{errorMsg}</div>
            <form onSubmit={handleSubmit}>
              <TextInput
                type="email"
                label={translate(AUTH_COMMON_EMAIL)}
                elementId="email-input"
              />
              {!showResetForm && (
                <TextInput
                  type="password"
                  label={translate(AUTH_COMMON_PASSWORD)}
                  elementId="password-input"
                  autoComplete="current-password"
                />
              )}
              <FormButton
                className={styles.button}
                text={<Translation id={showResetForm ? AUTH_COMMON_SEND : AUTH_COMMON_LOGIN} />}
                type="submit"
                loading={loading}
                elementId={showResetForm ? 'reset-password-btn' : 'login-btn'}
              />
            </form>
            {!showResetForm && useKeycloakSSO && (
              <SingleSignOnButtons
                googleLogin={() => handleSSOLogin(KeycloakIDP.Google)}
                facebookLogin={() => handleSSOLogin(KeycloakIDP.Facebook)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default LoginForm
