import React, { useContext, useCallback } from 'react'

import { appParamsService } from 'configuration'

import AskSantaLayout from './AskSantaLayout/AskSantaLayout'
import DefaultLayout from './DefaultLayout/DefaultLayout'
import ReceiverLayout from './ReceiverLayout/ReceiverLayout'
import VideoScreenContext from '../../Context'
import { ACTION } from '../../hooks/useStateReducer'
import { SOURCE_OF_INTERACTION } from '../../constants'

import styles from './Layout.module.css'

export const LayoutCallbackContext = React.createContext({
  renderAskForm: false,
  openAskForm: () => {},
  closeAskForm: () => {},
  onAskFormSubmitted: () => {},
  onCSClicked: () => {},
  onCSMenuClosed: () => {},
  onAskFormSpaceBarPressed: () => {},
  onSkipButtonClicked: () => {}
})

const Layout = () => {
  const {
    state: {
      isAnswering,
      isWaiting,
      isPlayButtonOverlayVisible,
      isInstructionsVisible,
      isEndEventModalVisible
    },
    ask,
    reset,
    dispatch
  } = useContext(VideoScreenContext)

  const onAskFormSubmitted = transcription => {
    if (transcription.length > 0) {
      ask(transcription, { source: SOURCE_OF_INTERACTION.ASK_FORM })
    }
    dispatch({ type: ACTION.CLOSE_ASK_FORM })
  }

  const onCSClicked = transcription => {
    if (isAnswering) {
      // Eliminate leftover subtitles artifacts from previous answer
      reset()
      setTimeout(
        () => ask(transcription, { source: SOURCE_OF_INTERACTION.CONVERSATION_STARTERS }),
        1000
      )
      return
    }

    ask(transcription, { source: SOURCE_OF_INTERACTION.CONVERSATION_STARTERS })
  }

  const onSkipButtonClicked = () => reset()

  const onCSMenuClosed = useCallback(() => dispatch({ type: ACTION.CLOSE_CS_MENU }), [dispatch])

  const closeAskForm = useCallback(() => dispatch({ type: ACTION.CLOSE_ASK_FORM }), [dispatch])

  const openAskForm = useCallback(
    () => isWaiting && dispatch({ type: ACTION.SHOW_ASK_FORM }),
    [dispatch, isWaiting]
  )

  const onAskFormSpaceBarPressed = useCallback(
    () => dispatch({ type: ACTION.SHOW_ASK_FORM }),
    [dispatch]
  )

  const LayoutComponent = appParamsService.isModeSanta ? AskSantaLayout : DefaultLayout
  const renderAskForm =
    isWaiting && !isPlayButtonOverlayVisible && !isInstructionsVisible && !isEndEventModalVisible

  return (
    <div className={styles.container}>
      {appParamsService.rcReceiver ? (
        <ReceiverLayout />
      ) : (
        <LayoutCallbackContext.Provider
          value={{
            renderAskForm,
            openAskForm,
            closeAskForm,
            onAskFormSubmitted,
            onCSClicked,
            onCSMenuClosed,
            onAskFormSpaceBarPressed,
            onSkipButtonClicked
          }}
        >
          <LayoutComponent />
        </LayoutCallbackContext.Provider>
      )}
    </div>
  )
}

export default Layout
