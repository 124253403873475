import { isMobile, isTablet } from 'react-device-detect'

import { assignBoolValue, assignNumericValue, parseColor } from 'helpers'
import { IS_FACEBOOK_ANDROID } from 'utils'
import { URL_PARAMS } from 'constants'

import {
  generateSessionId,
  parseStoryFileIds,
  parseLanguage,
  extractQueryParameters,
  parseAsrVendor
} from './util'

class AppParamsService {
  constructor() {
    this.clientId = 'webapp'
    // TODO: Access token and session ID are not url parameters and could be exported.
    this.sessionId = undefined
    this.accessToken = undefined
    this.accessTokenStore = 'cookie'
    this.useKeycloakSSO = false

    this.storyFileName = false
    this.storyFileIds = false
    this.storyFileIdToLoad = undefined
    this.hasMultipleIds = false
    this.quality = 1080
    this.isStaticApp = false
    this.isElectron = false
    this.isPrivate = false
    this.defaultSubsLabel = null
    this.sharedTranscription = ''
    this.showTranscription = true
    this.showSpeechVisualizer = false
    this.playerBackground = 'black'
    this.showPreferredOrientation = false
    this.showConversationStarters = null
    this.openConversationStarters = false
    this.groupConvStartersByTopics = false
    this.showInstructions = false
    this.showShareControl = false
    this.showKeyboardButton = false
    this.showBottomDrawer = false
    this.expandBottomDrawer = false
    this.checkpoints = false
    this.language = null
    this.playIntroVideo = false
    this.introVideoId = undefined
    this.openAskFormByDefault = false
    this.videoScale = '1'
    this.SCORMWrapper = false
    this.transcriptionDelay = 0.9
    this.themeColorPrimary = ''
    this.rcTransmitter = null
    this.rcReceiver = null
    this.rcWired = false
    this.rcReceiverWindowEvent = null
    this.disablePlayerDissolve = false
    this.disableDynamicQuality = false
    this.hideWaitingBeforeIntro = false
    this.showWaitingBeforeIntro = false
    this.loginMode = undefined
    this.autoListenDuration = undefined
    this.autoListenEndDuration = 2 // seconds
    this.autoListenInterrupt = true
    this.isWindowALoginPopUp = false
    this.ssoLoading = false
    this.unclickableLogo = false
    this.timeoutVideoDelay = 30
    this.disableTimeoutVideo = false
    this.disablePlayButton = false
    this.debug = false
    this.autoSFSwitch = null
    this.autoSFSwitchHourInterval = 1
    this.enableFullScreenButton = false
    this.unmuteWaitingVideo = false
    this.testBluetoothConfigs = false
    this.showVirtualKeyboard = false
    this.persistMicrophone = false

    this.appMode = null
    this.isModeDefault = false
    this.isModeWeb = false
    this.isModeMobile = false
    this.isModeSanta = false
    this.isModeLife = false
    this.isModeUnbranded = false

    this.isModeTime = false
    this.isModeMedtronic = false
    this.isModeHealthySoil = false
    this.isModePaintForACure = false
    this.isModeServiceNow = false
    this.isModeXCOPRI = false
    this.isModeSafelite = false
    this.isModeNavalAviationMuseum = false

    this.isHoldToTalkV2 = false
    this.testNewSpeech2Text = false
    this.experimentalFeatures = false
    this.customEventsOnVideoStart = false

    // TODO: remove once after video feature is tested:
    this.testAfterVideo = false

    this.asrVendor = null

    this.loadGetParams()
  }

  setClientId(clientId) {
    console.log('set client id', clientId)
    this.clientId = clientId
  }

  getClientId() {
    return this.clientId
  }

  setSessionId(sessionId = generateSessionId()) {
    this.sessionId = sessionId
    return this.sessionId
  }

  getSessionId() {
    return this.sessionId
  }

  enabledCheckpoints() {
    return this.checkpoints
  }

  getSharedTranscription() {
    return this.sharedTranscription
  }

  setSharedTranscription(transcription) {
    this.sharedTranscription = transcription
  }

  applyParamConditions() {
    if (this.isPrivate && !this.storyFileIds) {
      this.isPrivate = false
    }

    if (this.groupConvStartersByTopics) {
      if (this.showConversationStarters === false) {
        return
      }

      this.showConversationStarters = true

      if (this.isModeSanta) {
        this.groupConvStartersByTopics = false
      }
    }

    if (this.autoSFSwitch) {
      if (24 % this.autoSFSwitchHourInterval || this.autoSFSwitchHourInterval < 1) {
        alert('Auto switch step can be 1, 2, 3, 4, 6, 8 or 12 hours.')
        this.autoSFSwitch = null
      }
    }
  }

  loadGetParams() {
    const queryParameters = extractQueryParameters()

    // Set mode params first, so they can still be overridden with individual URL params after, if needed.
    const appMode =
      queryParameters[URL_PARAMS.skin]?.toLowerCase() ||
      queryParameters[URL_PARAMS.mode]?.toLowerCase()
    this.appMode = appMode

    // Generic mode for a set of features different from the minimal exhibit/museum experience
    if (appMode === 'web') {
      this.isModeWeb = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
    }

    // Generic mode optimized for mobile devices
    if (appMode === 'mobile') {
      this.isModeMobile = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
      this.quality = '720'
    }

    if (appMode === 'santa') {
      this.isModeSanta = true
      this.playIntroVideo = true
      this.showConversationStarters = true
    }

    // For now Life and Conversa modes are identical
    if (appMode === 'life' || appMode === 'conversa') {
      this.isModeLife = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
    }

    if (appMode === 'unbranded') {
      this.isModeUnbranded = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
    }

    if (appMode === 'time') {
      this.isModeTime = true
    }

    if (appMode === 'medtronic') {
      this.isModeMedtronic = true
      this.clientId = 'medtronic'
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
    }

    if (appMode === 'healthysoil') {
      this.isModeHealthySoil = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
    }

    if (appMode === 'pfac') {
      this.isModePaintForACure = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
      this.showShareControl = true
    }

    if (appMode === 'servicenow') {
      this.isModeServiceNow = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
      this.themeColorPrimary = '#032D42'
    }

    if (appMode === 'xcopri') {
      this.isModeXCOPRI = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
      this.themeColorPrimary = '#4c2570'
    }

    if (appMode === 'safelite') {
      this.isModeSafelite = true
      this.openAskFormByDefault = true
      this.playIntroVideo = true
      this.defaultSubsLabel = 'en'
      this.themeColorPrimary = '#1574A1'
    }

    if (appMode === 'nnam') {
      this.isModeNavalAviationMuseum = true
      this.playIntroVideo = true
      this.showConversationStarters = true
      this.showKeyboardButton = true
    }

    this.isModeDefault =
      !this.isModeSanta &&
      !this.isModeTime &&
      !this.isModeWeb &&
      !this.isModeMobile &&
      !this.isModeLife &&
      !this.isModeUnbranded &&
      !this.isModeMedtronic &&
      !this.isModeHealthySoil &&
      !this.isModePaintForACure &&
      !this.isModeServiceNow &&
      !this.isModeSafelite &&
      !this.isModeXCOPRI

    // Individual URL params
    if (queryParameters[URL_PARAMS.transcription] === 'false') {
      // string 'false' was before, not break it with 1/0
      this.showTranscription = false
    }
    this.showSpeechVisualizer = assignBoolValue(
      queryParameters[URL_PARAMS.waveform],
      this.showSpeechVisualizer
    )
    this.rcTransmitter = queryParameters[URL_PARAMS.rct]
    this.rcReceiver = queryParameters[URL_PARAMS.rcr]
    this.rcWired = assignBoolValue(queryParameters[URL_PARAMS.rcWired], this.rcWired)
    this.storyFileName = queryParameters[URL_PARAMS.user]
      ? queryParameters[URL_PARAMS.user].replace(/\+/g, ' ')
      : this.storyFileName
    const storyFileIdsData = parseStoryFileIds(queryParameters[URL_PARAMS.uid])
    this.storyFileIds = storyFileIdsData?.storyFileIds || this.storyFileIds
    this.storyFileIdToLoad = storyFileIdsData?.storyFileIdToLoad || this.storyFileIdToLoad
    this.hasMultipleIds = this.storyFileIds?.length > 1
    this.quality = queryParameters[URL_PARAMS.quality] || this.quality
    this.sessionId = queryParameters[URL_PARAMS.sid] || this.sessionId
    this.clientId = queryParameters[URL_PARAMS.cid] || this.clientId
    this.playerBackground = parseColor(queryParameters[URL_PARAMS.bg], this.playerBackground)
    this.themeColorPrimary = parseColor(queryParameters[URL_PARAMS.tcp], this.themeColorPrimary)
    this.showPreferredOrientation =
      isMobile && !isTablet && queryParameters[URL_PARAMS.po] === 'landscape'
    this.language = parseLanguage(
      queryParameters[URL_PARAMS.l],
      !!this.rcTransmitter,
      !!this.rcReceiver
    )
    this.sharedTranscription = queryParameters[URL_PARAMS.st]
      ? window.atob(queryParameters[URL_PARAMS.st])
      : this.sharedTranscription
    this.videoScale = queryParameters[URL_PARAMS.zoom] || this.videoScale
    this.accessTokenStore = queryParameters[URL_PARAMS.ats] || this.accessTokenStore
    this.introVideoId = queryParameters[URL_PARAMS.introId] || this.introVideoId
    this.defaultSubsLabel = queryParameters[URL_PARAMS.subs] || this.defaultSubsLabel
    this.loginMode = queryParameters[URL_PARAMS.lm] || this.loginMode
    this.autoListenDuration = queryParameters[URL_PARAMS.al] || this.autoListenDuration
    this.autoListenEndDuration = queryParameters[URL_PARAMS.al_end] || this.autoListenEndDuration

    this.showConversationStarters = assignBoolValue(
      queryParameters[URL_PARAMS.cs],
      this.showConversationStarters
    )
    this.openConversationStarters = assignBoolValue(
      queryParameters[URL_PARAMS.cs_open],
      this.openConversationStarters
    )
    this.testAfterVideo = assignBoolValue(
      queryParameters[URL_PARAMS.testaftervideo],
      this.testAfterVideo
    )
    this.isStaticApp = assignBoolValue(queryParameters[URL_PARAMS.static], this.isStaticApp)
    this.isElectron = assignBoolValue(queryParameters[URL_PARAMS.electron], this.isElectron)
    this.useKeycloakSSO = assignBoolValue(queryParameters[URL_PARAMS.sso], this.useKeycloakSSO)
    this.playIntroVideo = assignBoolValue(queryParameters[URL_PARAMS.intro], this.playIntroVideo)
    this.checkpoints = assignBoolValue(queryParameters[URL_PARAMS.ch], this.checkpoints)
    this.isPrivate = assignBoolValue(queryParameters[URL_PARAMS.private], this.isPrivate)
    this.showShareControl = assignBoolValue(queryParameters[URL_PARAMS.ssc], this.showShareControl)
    this.showInstructions = assignBoolValue(queryParameters[URL_PARAMS.si], this.showInstructions)
    this.openAskFormByDefault =
      assignBoolValue(queryParameters[URL_PARAMS.key], this.openAskFormByDefault) ||
      IS_FACEBOOK_ANDROID
    this.SCORMWrapper = assignBoolValue(queryParameters[URL_PARAMS.scorm], this.SCORMWrapper)
    this.groupConvStartersByTopics = assignBoolValue(
      queryParameters[URL_PARAMS.topics],
      this.groupConvStartersByTopics
    )
    this.disablePlayerDissolve = assignBoolValue(
      queryParameters[URL_PARAMS.dd],
      this.disableDissolve
    )
    this.disableDynamicQuality = assignBoolValue(
      queryParameters[URL_PARAMS.ddq],
      this.disableDynamicQuality
    )
    this.hideWaitingBeforeIntro = assignBoolValue(
      queryParameters[URL_PARAMS.hwbi],
      this.hideWaitingBeforeIntro
    )
    this.showWaitingBeforeIntro = assignBoolValue(
      queryParameters[URL_PARAMS.swbi],
      this.showWaitingBeforeIntro
    )
    const transcriptionDelay = this.disablePlayerDissolve ? 0.6 : this.transcriptionDelay
    this.transcriptionDelay =
      assignNumericValue(queryParameters[URL_PARAMS.td], transcriptionDelay) * 1000
    this.timeoutVideoDelay = assignNumericValue(
      queryParameters[URL_PARAMS.tvd],
      this.timeoutVideoDelay
    )
    this.disableTimeoutVideo = assignBoolValue(
      queryParameters[URL_PARAMS.dtv],
      this.disableTimeoutVideo
    )
    this.disablePlayButton = assignBoolValue(
      queryParameters[URL_PARAMS.dpb],
      this.disablePlayButton
    )
    this.autoSFSwitch = assignNumericValue(queryParameters[URL_PARAMS.sfsw], this.autoSFSwitch)
    this.autoSFSwitchHourInterval = assignNumericValue(
      queryParameters[URL_PARAMS.sfswi],
      this.autoSFSwitchHourInterval
    )

    this.ssoLoading = assignBoolValue(queryParameters[URL_PARAMS.sso_loading], this.ssoLoading)
    this.isWindowALoginPopUp = assignBoolValue(
      queryParameters[URL_PARAMS.login_popup],
      this.isWindowALoginPopUp
    )
    this.unclickableLogo = assignBoolValue(queryParameters[URL_PARAMS.ulogo], this.unclickableLogo)
    this.unmuteWaitingVideo = assignBoolValue(
      queryParameters[URL_PARAMS.uwv],
      this.unmuteWaitingVideo
    )
    this.debug = assignBoolValue(queryParameters[URL_PARAMS.debug], this.debug)
    this.autoListenInterrupt = assignBoolValue(
      queryParameters[URL_PARAMS.al_interrupt],
      this.autoListenInterrupt
    )
    this.isHoldToTalkV2 = assignBoolValue(
      queryParameters[URL_PARAMS.holdToTalkV2],
      this.isHoldToTalkV2
    )
    this.rcReceiverWindowEvent = assignBoolValue(
      queryParameters[URL_PARAMS.rcReceiverWindowEvent],
      this.rcReceiverWindowEvent
    )
    this.enableFullScreenButton = assignBoolValue(
      queryParameters[URL_PARAMS.fullScreenButton],
      this.enableFullScreenButton
    )
    this.showVirtualKeyboard = assignBoolValue(
      queryParameters[URL_PARAMS.virtualKeyboard],
      this.showVirtualKeyboard
    )
    this.persistMicrophone = assignBoolValue(
      queryParameters[URL_PARAMS.pmic],
      this.persistMicrophone
    )

    this.asrVendor = parseAsrVendor(queryParameters[URL_PARAMS.asrv])

    // Temp/test params
    this.showBottomDrawer = assignBoolValue(
      queryParameters[URL_PARAMS.taskbar],
      this.showBottomDrawer
    )
    this.expandBottomDrawer = assignBoolValue(
      queryParameters[URL_PARAMS.taskbaropen],
      this.expandBottomDrawer
    )
    this.testBluetoothConfigs = assignBoolValue(
      queryParameters[URL_PARAMS.testbluetooth],
      this.testBluetoothConfigs
    )

    this.isHoldToTalkV2 = assignBoolValue(
      queryParameters[URL_PARAMS.holdToTalkV2],
      this.isHoldToTalkV2
    )
    this.testNewSpeech2Text = assignBoolValue(
      queryParameters[URL_PARAMS.testNewSpeech2Text],
      this.testNewSpeech2Text
    )
    this.experimentalFeatures = assignBoolValue(
      queryParameters[URL_PARAMS.experimentalFeatures],
      this.experimentalFeatures
    )
    // @TODO: Remove once onVideoStart flag is added to the events json
    this.customEventsOnVideoStart = assignBoolValue(
      queryParameters[URL_PARAMS.customEventsOnVideoStart],
      this.customEventsOnVideoStart
    )

    // Should always run last, after all params are set
    this.applyParamConditions()
  }
}

const appParamsService = new AppParamsService()

export default appParamsService
