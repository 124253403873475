import React, { useRef, useContext, useEffect, useCallback } from 'react'

import { StoryFileContext } from 'App'
import { logError, postDialogueInteract, getRandomVideo, postSessionStateUpdate } from 'api'
import { appParamsService, envConfig } from 'configuration'
import { firebase } from 'firebase-client'
import { ASK_QUESTION, VIEW_STORYFILE } from 'firebase-client/events'
import usePrevious from 'hooks/usePrevious'
import useRecognizeSpeech from 'hooks/useRecognizeSpeech'
import {
  getPlayingVideoUrl,
  refreshPageWithStoryFile,
  inIframe,
  WINDOW_MESSAGES,
  postEventToParent
} from 'utils'
import { debugModeLogger, debugModeTime, debugModeTimeEnd, LOG_PREFIX } from 'utils/debugModeLogger'
import { getLanguageByTag } from 'shared/utils'
import { MessageTypes } from 'remote-control'
import { sessionStorageService, SESSION_STORAGE_KEY } from 'utils/BrowserStorageService'
import PermissionsReminderPopup from 'components/PermissionsReminderPopup/PermissionsReminderPopup'
import { translate } from 'components/Translation/Translation'
import {
  COMMON_SOMETHING_WENT_WRONG,
  COMMON_YOU_HAVE_COMPLETED_SCENARIO
} from 'locales/translationIds'
import { setCSSVariable } from 'helpers'
import { useStoreDispatch, useStoreSelector, SELECT } from 'providers/StoreProvider'

import {
  BUFFERING,
  VIDEO_STATE,
  SOURCE_OF_INTERACTION,
  MIN_AUTO_LISTEN_DURATION
} from './constants'
import VideoScreenContext from './Context'
import VideoPlayer from './components/VideoPlayer/VideoPlayer'
import PlayButtonOverlay from './components/PlayButtonOverlay/PlayButtonOverlay'
import PreferredOrientationOverlay from './components/PreferredOrientationOverlay/PreferredOrientationOverlay'
import InstructionsOverlay from './components/InstructionsOverlay/InstructionsOverlay'
import EndEventDialogueBox from './components/EndEventDialogueBox/EndEventDialogueBox'
import Layout from './components/Layout/Layout'
import useIdleTimer from './hooks/useIdleTimer'
import useInteractionListener from './hooks/useInteractionListener'
import useStateReducer, { ACTION } from './hooks/useStateReducer'
import useWindowResizer from './hooks/useWindowResizer'
import useRCReceiver from './hooks/useRCReceiver'
import { checkForInteractionError, processSubtitles } from './util'
import handleVideoEndEvent, {
  parseOnEndEvents,
  handleVideoStartEvent,
  willEndEventsTriggerUI,
  END_EVENT_DIALOGUE_TYPES
} from './util/handleVideoEvent'
import { handleVideoEventJson, willJsonEndEventsTriggerUI } from './util/handleVideoEndEvents'
import useVideoQuality from './hooks/useVideoQuality'

const {
  isModeSanta,
  isModeSafelite,
  isStaticApp,
  videoScale,
  openAskFormByDefault,
  clientId,
  transcriptionDelay,
  sharedTranscription,
  showPreferredOrientation,
  autoListenDuration,
  autoListenEndDuration,
  autoListenInterrupt,
  disablePlayerDissolve,
  disableTimeoutVideo,
  sessionId,
  timeoutVideoDelay,
  testAfterVideo,
  experimentalFeatures,
  customEventsOnVideoStart
} = appParamsService

const inkyDebugLogger = console.log.bind(console, '[Debug message]:')

const listenDuration = autoListenDuration * 1000
const listenEndDuration = autoListenEndDuration * 1000
const AUTO_LISTEN_DURATION =
  listenDuration >= MIN_AUTO_LISTEN_DURATION ? listenDuration : MIN_AUTO_LISTEN_DURATION

const shouldUseSpeech = autoListenDuration || !isModeSafelite

const VideoScreen = ({ onBackButtonPress }) => {
  const { tag: voiceLanguageTag } = useStoreSelector(SELECT.LANGUAGE)
  const { language: languageDispatch } = useStoreDispatch()
  const SFConfig = useContext(StoryFileContext)
  const { username, userId } = SFConfig

  useWindowResizer()
  const [
    {
      askOnReady,
      answerVideoUrl,
      answerVideoSubtitles,
      waitingVideoUrl,
      nextWaitingVideoUrl,
      cannotAnswerVideoUrl,
      cannotAnswerVideoSubtitles,
      timeoutVideoUrl,
      timeoutDuration,
      timeoutVideoSubtitles,
      pauseAnswerVideo,
      pauseWaitingVideo,
      playAnswerVideo,
      playCannotAnswerVideo,
      transitionToCannotAnswerPlayer,
      transitionToAnswerPlayer,
      transitionWithoutAnimation,
      playWaitingVideo,
      muteWaitingVideo,
      showAnswerPlayer,
      showCannotAnswerPlayer,
      position,
      onEndEvents,
      endEventDialogueData,
      endEventDialogueType,
      endEventDialogueDescription,
      endEventDialogueUrlButtons,
      onEndEventsJson,
      subtitlesLabel,
      canPlayIntro,
      dynamicCS,
      buffering,
      showAskForm,
      showPlayButtonOverlay,
      showInstructionsOverlay,
      isIntroPlaying,
      isIntroStarted,
      isIdle,
      openCSMenu,
      transcription,
      lastAnsweredTranscription
    },
    dispatch
  ] = useStateReducer(SFConfig)
  const prevPosition = usePrevious(position)
  const {
    transcription: speechTranscription,
    isRecording,
    isRecordingInitiated,
    startListeningAfterDelay,
    stopListening,
    resetTranscription: resetSpeechTranscription,
    getFinalTranscription,
    micPermissionGranted,
    micPermissionPending,
    clearMicPermissionPending,
    mediaStream
  } = useRecognizeSpeech(SFConfig.googleASRTrainingData, voiceLanguageTag, shouldUseSpeech, userId)

  const askSharedTranscriptionOnReady = useRef(askOnReady)
  const videoPlayerRef = useRef()
  const rewindVideoPlayerTimeout = useRef(null)
  const resetTranscriptionTimeout = useRef(false)
  const micPermissionResolved = useRef(false)
  const autoListenTimeoutRef = useRef()
  const hasTimeoutVideoPlayedRef = useRef(false)
  const preferredOrientationClicked = useRef(!showPreferredOrientation)
  const forcePlayerDissolve = useRef(false)
  const hasInteractionError = useRef(false)
  const afterVideoRef = useRef(null)

  const { getQuality, checkQuality } = useVideoQuality()
  const timeoutVideoEnabled = !disableTimeoutVideo && timeoutVideoUrl
  const hasDOMInteraction = useInteractionListener(timeoutVideoEnabled)
  const idleTimer = useIdleTimer(
    hasDOMInteraction && timeoutVideoEnabled,
    position,
    timeoutDuration || timeoutVideoDelay,
    dispatch
  )

  const isWaitingPosition = position === VIDEO_STATE.WAITING
  const renderInstructionsOverlay =
    showInstructionsOverlay &&
    !showPlayButtonOverlay &&
    !askSharedTranscriptionOnReady.current &&
    isWaitingPosition &&
    !endEventDialogueType &&
    !isIntroPlaying
  const showPreferredOrientationOverlay =
    showPreferredOrientation &&
    !showPlayButtonOverlay &&
    !askSharedTranscriptionOnReady.current &&
    isWaitingPosition &&
    !endEventDialogueType &&
    !isIntroPlaying &&
    !renderInstructionsOverlay
  const playTimeoutVideo =
    isIdle &&
    hasDOMInteraction &&
    isWaitingPosition &&
    !showInstructionsOverlay &&
    !showPlayButtonOverlay &&
    !showPreferredOrientationOverlay &&
    !endEventDialogueType &&
    !hasTimeoutVideoPlayedRef.current

  useEffect(() => {
    checkQuality()
    firebase.log(VIEW_STORYFILE, {
      userId,
      username,
      isStaticApp
    })
    setCSSVariable('--vScale', videoScale)

    if (remoteControlReceiver) {
      // @TODO: ReceiverScreen should render VideoScreen, so this listener is not needed in both places
      remoteControlReceiver.once(MessageTypes.ACTIVE_STORYFILE, refreshPageWithStoryFile)
    }

    const bc = broadcastChannelRef.current
    bc?.postMessage({ type: MessageTypes.CONNECTION_STATUS, value: 'connected' })
    window.onbeforeunload = () => {
      bc?.postMessage({
        type: MessageTypes.CONNECTION_STATUS,
        value: 'disconnected'
      })
    }
    return () => {
      clearTimeout(rewindVideoPlayerTimeout.current)
      clearTimeout(resetTranscriptionTimeout.current)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!micPermissionResolved.current) {
      if (micPermissionGranted === false && !showAskForm) {
        dispatch({ type: ACTION.MICROPHONE_ERROR })
      }

      dispatch({ type: ACTION.MICROPHONE_PERMISSION_GRANTED })
    }

    if (micPermissionGranted !== null) {
      micPermissionResolved.current = true
    }
  }, [micPermissionGranted, showAskForm, dispatch])

  useEffect(() => {
    if (!timeoutVideoEnabled || !playTimeoutVideo) return

    const fetchRandomTimeoutVideo = async () => {
      let newTimeoutVideoUrl = timeoutVideoUrl
      try {
        const { data: timeoutVideoData } = await getRandomVideo(userId, 'timeoutvideo')
        newTimeoutVideoUrl = getPlayingVideoUrl(
          `${timeoutVideoData.url}?quality=${getQuality()}`,
          username,
          isStaticApp,
          null,
          userId
        )

        const { data: sessionData } = await postSessionStateUpdate({
          inkActionType: 'timeout',
          timeoutVideoId: timeoutVideoData.id
        })

        if (sessionData?.debugMessage) {
          inkyDebugLogger(sessionData.debugMessage)
        }
      } catch (error) {
        console.log('New timeout video error:', error)
      }

      idleTimer.pause()
      hasTimeoutVideoPlayedRef.current = true
      postEventToParent(WINDOW_MESSAGES.TIMEOUT_VIDEO_START)
      dispatch({
        type: ACTION.START_ANSWERING,
        payload: {
          answerVideoUrl: newTimeoutVideoUrl,
          answerVideoSubtitles: processSubtitles(timeoutVideoSubtitles, subtitlesLabel),
          position: VIDEO_STATE.ANSWERING,
          isIdle: false
        }
      })
    }

    fetchRandomTimeoutVideo()
  }, [
    getQuality,
    userId,
    username,
    playTimeoutVideo,
    timeoutVideoUrl,
    timeoutVideoEnabled,
    timeoutVideoSubtitles,
    subtitlesLabel,
    idleTimer,
    dispatch
  ])

  useEffect(() => {
    if (
      (openAskFormByDefault || micPermissionGranted === false) &&
      prevPosition &&
      prevPosition.current !== VIDEO_STATE.WAITING &&
      isWaitingPosition &&
      !showAskForm
    ) {
      // Show form after a timeout to avoid closing immediately by mobile touch event after interrupting an answer
      setTimeout(() => dispatch({ type: ACTION.SHOW_ASK_FORM }), 300)
    }
  })

  useEffect(() => {
    if (!inIframe) {
      return
    }
    window.addEventListener('message', onPostMessage, false)
    return () => window.removeEventListener('message', onPostMessage, false)
    // This is the only one dependency which relates to the state and is used in ask() -> doAsk() chain
    // eslint-disable-next-line
  }, [subtitlesLabel, position])

  const onPostMessage = e => {
    if (e.data.type === WINDOW_MESSAGES.QUESTION) {
      if (autoListenTimeoutRef.current) {
        stopAutoListen()
      }

      const showTranscription = e.data.showTranscription || true
      ask(e.data.value, { source: SOURCE_OF_INTERACTION.EXTERNAL }, showTranscription)
    }

    if (e.data.type === WINDOW_MESSAGES.LANGUAGE) {
      const language = getLanguageByTag(e.data.value)
      handleVoiceLangChange(language)
    }

    if (e.data.type === WINDOW_MESSAGES.INTERRUPT_ANSWER) {
      reset()
    }
  }

  useEffect(() => {
    window.addEventListener('message', onListeningPostMessage, false)

    const bc = broadcastChannelRef.current
    if (!appParamsService.rcReceiver || !bc) {
      return () => {
        window.removeEventListener('message', onListeningPostMessage, false)
      }
    }

    broadcastChannelRef.current.onmessage = event => {
      const { type, value } = event.data
      switch (type) {
        case MessageTypes.ACTIVE_STORYFILE:
          refreshPageWithStoryFile(value)
          bc.postMessage({ type: MessageTypes.CONNECTION_STATUS, value: 'connected' })
          break
        case MessageTypes.TRANSCRIPTION:
          onTranscriptionHandler(value)
          break
        case MessageTypes.VOICE_LANGUAGE_CHANGED:
          handleVoiceLangChange(value)
          break
        case MessageTypes.SUBTITLES_CHANGED:
          // @TODO: Fix not updating
          handleSubtitlesChange(value)
          break
        case MessageTypes.RESET:
          reset()
          break
        default:
          break
      }
    }

    return () => {
      window.removeEventListener('message', onListeningPostMessage, false)
      bc.onmessage = null
    }

    // eslint-disable-next-line
  }, [position, transcription, showAskForm])

  const onListeningPostMessage = e => {
    if (e.data.type === WINDOW_MESSAGES.START_LISTENING) {
      handleButtonPress(null, 0)
      const autoStopDuration = e.data.listenDuration * 1000 || AUTO_LISTEN_DURATION
      autoListenTimeoutRef.current = setTimeout(stopAutoListen, autoStopDuration)
    }
    if (e.data.type === WINDOW_MESSAGES.STOP_LISTENING) {
      handleButtonRelease()
    }
  }

  const reset = () => {
    if ([VIDEO_STATE.WAITING, VIDEO_STATE.RECORDING].includes(position)) return
    if (afterVideoRef.current) {
      if (afterVideoRef.current.isPlayed) {
        afterVideoRef.current = null
        dispatch({ type: ACTION.RESET_AFTER_VIDEO })
      } else {
        playAfterVideo()
        return
      }
    }

    dispatch({ type: ACTION.RESET })
    postEventToParent(MessageTypes.VIDEO_ENDED)
    // Transition animation between the players is 0.8s, we need to delay the rewinding to prevent flashing of the first frame of the video.
    // Also reset the dissolve ref after the transition is finished.
    rewindVideoPlayerTimeout.current = setTimeout(() => {
      videoPlayerRef.current?.rewind()
      forcePlayerDissolve.current = false
      if (timeoutVideoEnabled) idleTimer.activate()
    }, 850)

    // Renew auto listen. Delay is to wait out the transition to waiting pose

    const eventsWillTriggerUI = experimentalFeatures
      ? willJsonEndEventsTriggerUI(Object.keys(onEndEventsJson || {}))
      : willEndEventsTriggerUI(onEndEvents)
    if (autoListenDuration && !eventsWillTriggerUI) {
      setTimeout(startAutoListen, 500)
    }

    handleEndEvents()
  }

  const doAsk = async (transcription, context) => {
    try {
      if (!transcription) {
        debugModeLogger('No finalTranscription. Resetting')
        resetTranscription(context)
        if (autoListenDuration) {
          startAutoListen()
        }

        return
      }

      checkQuality()
      debugModeTime(`${LOG_PREFIX} Sent dialogue service request`)
      postEventToParent({ type: WINDOW_MESSAGES.ASKED_QUESTION, transcription, sessionId })
      const interactRes = await postDialogueInteract(transcription, getQuality(), voiceLanguageTag)
      debugModeTimeEnd(`${LOG_PREFIX} Sent dialogue service request`)

      const {
        video,
        subtitles,
        conversationStarters,
        newCannotAnswerVideoUrl,
        newCannotAnswerVideoSubtitles,
        newCannotAnswerVideoOnEndEvents,
        newIdleVideoUrl: newWaitingVideoUrl,
        newTimeoutVideoUrl,
        newTimeoutDuration,
        newTimeoutVideoSubtitles,
        playAfterVideo,
        finalMessage,
        postEvents,
        postVideoMessage,
        debugMessage
      } = interactRes.data
      const noAnswerToQuestion = video.id === -1

      if (debugMessage) {
        inkyDebugLogger(debugMessage)
      }

      if (noAnswerToQuestion) {
        dispatch({
          type: ACTION.START_CANNOT_ANSWERING,
          payload: {
            cannotAnswerVideoUrl: getPlayingVideoUrl(
              newCannotAnswerVideoUrl,
              username,
              isStaticApp,
              sessionId,
              userId
            ),
            cannotAnswerVideoSubtitles: newCannotAnswerVideoSubtitles,
            onEndEvents: parseOnEndEvents(newCannotAnswerVideoOnEndEvents)
            // endEventDialogueData: @TODO: Should support end event dialogue messages?
          }
        })
        postEventToParent({
          type: WINDOW_MESSAGES.VIDEO_START_EVENT,
          videoType: 'noAnswerToQuestion'
        })
      } else {
        if (playAfterVideo || testAfterVideo) {
          const afterVideo =
            playAfterVideo ||
            (testAfterVideo && {
              id: 10311,
              transcription: 'I am testing video',
              onEndEvents:
                'showURL_https://support.servicenow.com/kb?id=kb_article_view&sysparm_article=KB0547253',
              isMorph: true,
              url: 'https://content.dev.storyfile.com/api/v1/video/10311?quality=1080',
              subtitles: []
            })

          afterVideoRef.current = { ...afterVideo, isPlayed: false }

          dispatch({
            type: ACTION.PRELOAD_AFTER_VIDEO,
            payload: {
              afterVideoUrl: getPlayingVideoUrl(
                afterVideo.url,
                username,
                isStaticApp,
                null,
                userId
              ),
              afterVideoSubtitles: processSubtitles(afterVideo?.subtitles, subtitlesLabel)
            }
          })
        }

        const events = postEvents || video.onEndEvents
        let onEndEvents
        if (postVideoMessage) {
          onEndEvents = [END_EVENT_DIALOGUE_TYPES.showText]
        } else {
          onEndEvents = parseOnEndEvents(events)
        }

        if (customEventsOnVideoStart) {
          onEndEvents.forEach(event => {
            handleVideoStartEvent(event)
          })
        }

        const endEventText = postVideoMessage || finalMessage
        const endEventDialogueData =
          endEventText?.replaceAll('\\n', ' \n ') || translate(COMMON_YOU_HAVE_COMPLETED_SCENARIO)

        dispatch({
          type: ACTION.START_ANSWERING,
          payload: {
            answerVideoUrl: getPlayingVideoUrl(video.url, username, isStaticApp, null, userId),
            answerVideoSubtitles: processSubtitles(subtitles, subtitlesLabel),
            nextWaitingVideoUrl: getPlayingVideoUrl(
              newWaitingVideoUrl,
              username,
              isStaticApp,
              null,
              userId
            ),
            timeoutVideoUrl:
              getPlayingVideoUrl(newTimeoutVideoUrl, username, isStaticApp, null, userId) ||
              timeoutVideoUrl,
            timeoutDuration: newTimeoutDuration || timeoutDuration,
            timeoutVideoSubtitles: newTimeoutVideoUrl
              ? newTimeoutVideoSubtitles
              : timeoutVideoSubtitles,
            dynamicCS: conversationStarters,
            onEndEvents,
            onEndEventsJson: video.onEndEventsJson,
            endEventDialogueData,
            transitionWithoutAnimation: video.isMorph
          }
        })
        postEventToParent({
          type: WINDOW_MESSAGES.VIDEO_START_EVENT,
          videoType: 'answerToQuestion'
        })
      }

      hasTimeoutVideoPlayedRef.current = false
      resetTranscription(context)
    } catch (error) {
      logError(error.message)
      alert(translate(COMMON_SOMETHING_WENT_WRONG))
      dispatch({ type: ACTION.STOP_BUFFERING, payload: BUFFERING.ANSWER })
      window.location.reload()
    }
  }

  const handleSubtitlesChange = useCallback(
    label => dispatch({ type: ACTION.SUBTITLES_CHANGED, payload: label }),
    [dispatch]
  )

  const handleVoiceLangChange = useCallback(
    language => {
      languageDispatch.set(language)
      postEventToParent({ type: WINDOW_MESSAGES.CHANGED_LANGUAGE, value: language })
    },
    [languageDispatch]
  )

  const onTranscriptionHandler = transcription => {
    if (position === VIDEO_STATE.ANSWERING) {
      // Eliminate leftover subtitles artifacts from previous answer
      reset()
      setTimeout(() => doAsk(transcription), 1000)
      return
    }
    doAsk(transcription)
  }

  const remoteControlReceiver = useRCReceiver(
    onTranscriptionHandler,
    reset,
    handleSubtitlesChange,
    handleVoiceLangChange
  )
  const broadcastChannelRef = useRef(
    !remoteControlReceiver && 'BroadcastChannel' in window
      ? new BroadcastChannel(appParamsService.rcReceiver)
      : undefined
  )

  const resetTranscription = context => {
    if (context?.source === SOURCE_OF_INTERACTION.VOICE) {
      resetSpeechTranscription()
      dispatch({ type: ACTION.SET_TRANSCRIPTION, payload: '' })
      return
    }

    resetTranscriptionTimeout.current = setTimeout(() => {
      resetSpeechTranscription()
      dispatch({ type: ACTION.SET_TRANSCRIPTION, payload: '' })
    }, transcriptionDelay)
  }

  const handleButtonPress = (e, listenDelay) => {
    if (showAskForm) return
    if (isWaitingPosition && shouldUseSpeech) {
      dispatch({ type: ACTION.START_RECORDING })
      startListeningAfterDelay(listenDelay)
    } else if (position === VIDEO_STATE.ANSWERING) {
      if (disablePlayerDissolve || transitionWithoutAnimation) forcePlayerDissolve.current = true
      reset()
    }
  }

  const handleButtonRelease = () => {
    const disableAutoListenInterrupt =
      !!autoListenDuration && !autoListenInterrupt && !!autoListenTimeoutRef.current
    if (showAskForm || disableAutoListenInterrupt) return

    if (position === VIDEO_STATE.RECORDING && shouldUseSpeech) {
      stopListening()

      if (autoListenTimeoutRef.current) {
        clearTimeout(autoListenTimeoutRef.current)
        autoListenTimeoutRef.current = null
      }

      if (!transcription?.length) {
        dispatch({ type: ACTION.STOP_RECORDING })
        resetTranscription() // Clear single word input transcription that came in late
        return
      }

      ask(transcription, { source: SOURCE_OF_INTERACTION.VOICE })
    }
  }

  const ask = async (transcription, context, showTranscription = true) => {
    debugModeLogger('Start asking flow')
    dispatch({
      type: ACTION.START_ASKING,
      payload: showTranscription ? transcription : ''
    })
    firebase.log(ASK_QUESTION, {
      source: context.source,
      clientId,
      userId
    })

    try {
      debugModeTime(`${LOG_PREFIX} Obtained final transcription`)
      const finalTranscription = await getFinalTranscription(transcription, context)
      debugModeTimeEnd(`${LOG_PREFIX} Obtained final transcription`)
      await doAsk(finalTranscription, context)
    } catch (error) {
      logError(error.message)
      alert(translate(COMMON_SOMETHING_WENT_WRONG))
      dispatch({ type: ACTION.STOP_BUFFERING, payload: BUFFERING.ANSWER })
      window.location.reload()
    }
  }

  const startAutoListen = () => {
    if (showAskForm) return
    dispatch({ type: ACTION.START_RECORDING, payload: { isAutoListening: true } })
    startListeningAfterDelay(0)
    autoListenTimeoutRef.current = setTimeout(stopAutoListen, AUTO_LISTEN_DURATION)
  }

  const stopAutoListen = useCallback(
    () => {
      stopListening()
      clearTimeout(autoListenTimeoutRef.current)
      autoListenTimeoutRef.current = null

      if (!transcription?.length) {
        dispatch({ type: ACTION.STOP_RECORDING })
        return
      }

      ask(transcription, { source: SOURCE_OF_INTERACTION.VOICE })
    },

    // eslint-disable-next-line
    [transcription]
  )

  useEffect(() => {
    if (speechTranscription) {
      dispatch({ type: ACTION.SET_TRANSCRIPTION, payload: speechTranscription })
    }

    // On new input, renew autoListen to end after url param value (2s by default)
    if (autoListenTimeoutRef.current) {
      clearTimeout(autoListenTimeoutRef.current)
      autoListenTimeoutRef.current = setTimeout(stopAutoListen, listenEndDuration)
    }
  }, [speechTranscription, dispatch, stopAutoListen])

  const handleEndEvents = () => {
    let actionPayload = { onEndEvents: [], onEndEventsJson: null }

    if (experimentalFeatures) {
      Object.keys(onEndEventsJson || {}).forEach(event => {
        const updatedStateFromEvent = handleVideoEventJson(
          event,
          onEndEventsJson[event],
          { lastAnsweredTranscription },
          onEndEventsJson
        )
        actionPayload = { ...actionPayload, ...updatedStateFromEvent }
      })
    } else {
      onEndEvents.forEach(event => {
        const updatedStateFromEvent = handleVideoEndEvent(
          event,
          { lastAnsweredTranscription },
          onEndEvents
        )
        actionPayload = { ...actionPayload, ...updatedStateFromEvent }
      })
    }

    dispatch({ type: ACTION.END_EVENTS_EXECUTED, payload: actionPayload })
  }

  const playAfterVideo = () => {
    // Trigger end events for initial video as well.
    // Use case is to trigger custom events that fire postMessage but don't affect UI.
    handleEndEvents()

    afterVideoRef.current.isPlayed = true
    dispatch({
      type: ACTION.PLAY_AFTER_VIDEO,
      payload: {
        onEndEvents: parseOnEndEvents(afterVideoRef.current.onEndEvents)
      }
    })
  }

  const onPlayButtonClick = () => {
    // TODO: Remove redundant cases when PLAY_BUTTON_ON_ERROR is closed
    if (envConfig['PLAY_BUTTON_ON_ERROR']) {
      hasInteractionError.current = false
      dispatch({ type: ACTION.PLAY_BUTTON_CLICKED })
    } else if (sharedTranscription) {
      dispatch({ type: ACTION.PLAY_SHARED_TRANSCRIPTION })
      ask(sharedTranscription, { source: SOURCE_OF_INTERACTION.SHARED_TRANSCRIPTION })
    } else if (canPlayIntro) {
      dispatch({ type: ACTION.PLAY_INTRO_VIDEO })
    }
  }

  const shouldRenderSpinner = () => {
    if (isWaitingPosition) {
      return buffering.waiting && isModeSanta
    } else if ([VIDEO_STATE.ANSWERING, VIDEO_STATE.THINKING].includes(position)) {
      return buffering.answer && isModeSanta
    } else {
      return false
    }
  }

  const onAnswerVideoBufferEnded = () => {
    isModeSanta && dispatch({ type: ACTION.STOP_BUFFERING, payload: BUFFERING.ANSWER })
    if (!hasInteractionError.current && position === VIDEO_STATE.ANSWERING && playWaitingVideo) {
      dispatch({ type: ACTION.PLAY_ANSWER_VIDEO })
      dispatch({ type: ACTION.CLOSE_ASK_FORM })
    }
  }

  const onAnswerVideoStarted = () => {
    if (isIntroPlaying) dispatch({ type: ACTION.INTRO_VIDEO_STARTED })
    remoteControlReceiver?.sendVideoStarted()
    broadcastChannelRef.current?.postMessage({ type: MessageTypes.VIDEO_STARTED })
  }

  const onAnswerVideoProgressed = ev => {
    // Fire once at the start of the video
    if (ev.playedSeconds < 1) {
      if (nextWaitingVideoUrl && nextWaitingVideoUrl !== waitingVideoUrl) {
        // Update waiting video url after waiting player has transitioned to hidden
        setTimeout(() => {
          dispatch({ type: ACTION.UPDATE_WAITING_VIDEO_URL })
        }, 1000)
      }
    }
  }

  const onAnswerVideoEnded = () => {
    if (afterVideoRef.current && !afterVideoRef.current.isPlayed) {
      playAfterVideo()
    } else {
      reset()
      remoteControlReceiver?.sendVideoEnded()
      broadcastChannelRef.current?.postMessage({ type: MessageTypes.VIDEO_ENDED })
    }
  }

  const onAnswerVideoBuffer = () =>
    isModeSanta && dispatch({ type: ACTION.START_BUFFERING, payload: BUFFERING.ANSWER })

  const onWaitingVideoBuffer = () =>
    isModeSanta && dispatch({ type: ACTION.START_BUFFERING, payload: BUFFERING.WAITING })

  const onWaitingVideoBufferEnded = () =>
    isModeSanta && dispatch({ type: ACTION.STOP_BUFFERING, payload: BUFFERING.WAITING })

  const onWaitingVideoProgressed = ({ playedSeconds }) => {
    if (askSharedTranscriptionOnReady.current && playedSeconds > 1) {
      askSharedTranscriptionOnReady.current = false
      dispatch({ type: ACTION.START_ASKING, payload: sharedTranscription })
      doAsk(sharedTranscription)
    }
  }

  const onCannotAnswerVideoStarted = () => {
    remoteControlReceiver?.sendVideoStarted()
    broadcastChannelRef.current?.postMessage({ type: MessageTypes.VIDEO_STARTED })
  }

  const onCannotAnswerVideoEnded = () => {
    reset()
    remoteControlReceiver?.sendVideoEnded()
    broadcastChannelRef.current?.postMessage({ type: MessageTypes.VIDEO_ENDED })
  }

  const onCannotAnswerVideoBuffer = () =>
    isModeSanta && dispatch({ type: ACTION.START_BUFFERING, payload: BUFFERING.CANNOT_ANSWER })

  const onCannotAnswerVideoBufferEnded = () => {
    isModeSanta && dispatch({ type: ACTION.STOP_BUFFERING, payload: BUFFERING.CANNOT_ANSWER })
    if (position === VIDEO_STATE.ANSWERING && playWaitingVideo) {
      dispatch({ type: ACTION.PLAY_CANNOT_ANSWER })
    }
  }

  const onGetStartedClick = () => {
    dispatch({ type: ACTION.GET_STARTED_CLICKED })
    sessionStorageService.setItem(SESSION_STORAGE_KEY.INSTRUCTIONS, true)
  }

  const onVideoPlayerError = err => {
    // TODO: Remove PLAY_BUTTON_ON_ERROR when it is closed
    if (envConfig['PLAY_BUTTON_ON_ERROR'] && checkForInteractionError(err)) {
      hasInteractionError.current = true
      dispatch({ type: ACTION.SHOW_PLAY_BUTTON })
    }
  }

  const resetByLayout = () => {
    if (disablePlayerDissolve || transitionWithoutAnimation) forcePlayerDissolve.current = true
    reset()
  }

  return (
    <>
      <VideoPlayer
        ref={videoPlayerRef}
        mediaStream={mediaStream}
        isRecording={isRecording}
        answerVideoUrl={answerVideoUrl}
        answerVideoSubtitles={answerVideoSubtitles}
        waitingVideoUrl={waitingVideoUrl}
        cannotAnswerVideoUrl={cannotAnswerVideoUrl}
        cannotAnswerVideoSubtitles={cannotAnswerVideoSubtitles}
        pauseAnswerVideo={pauseAnswerVideo}
        pauseWaitingVideo={pauseWaitingVideo}
        playAnswerVideo={playAnswerVideo}
        playCannotAnswerVideo={playCannotAnswerVideo}
        transitionToCannotAnswerPlayer={transitionToCannotAnswerPlayer}
        transitionToAnswerPlayer={transitionToAnswerPlayer}
        playWaitingVideo={playWaitingVideo}
        muteWaitingVideo={muteWaitingVideo}
        showAnswerPlayer={showAnswerPlayer}
        showCannotAnswerPlayer={showCannotAnswerPlayer}
        transcription={transcription}
        disableDissolve={transitionWithoutAnimation}
        forceDissolve={forcePlayerDissolve.current}
        showSpinner={shouldRenderSpinner()}
        onAnswerVideoStart={onAnswerVideoStarted}
        onAnswerVideoProgressed={onAnswerVideoProgressed}
        onAnswerVideoEnd={onAnswerVideoEnded}
        onAnswerVideoBuffer={onAnswerVideoBuffer}
        onAnswerVideoBufferEnd={onAnswerVideoBufferEnded}
        onWaitingVideoBuffer={onWaitingVideoBuffer}
        onWaitingVideoBufferEnd={onWaitingVideoBufferEnded}
        onWaitingVideoProgress={onWaitingVideoProgressed}
        onCannotAnswerVideoStart={onCannotAnswerVideoStarted}
        onCannotAnswerVideoEnd={onCannotAnswerVideoEnded}
        onCannotAnswerVideoBuffer={onCannotAnswerVideoBuffer}
        onCannotAnswerVideoBufferEnd={onCannotAnswerVideoBufferEnded}
        onPlayerTouchStart={handleButtonPress}
        onPlayerTouchEnd={handleButtonRelease}
        onVideoPlayerError={onVideoPlayerError}
      />
      {showPlayButtonOverlay && <PlayButtonOverlay onClick={onPlayButtonClick} />}
      {renderInstructionsOverlay && <InstructionsOverlay onStartClick={onGetStartedClick} />}
      {!preferredOrientationClicked.current && (
        <PreferredOrientationOverlay
          isOpen={showPreferredOrientationOverlay}
          onClick={() => (preferredOrientationClicked.current = true)}
        />
      )}
      <VideoScreenContext.Provider
        value={{
          state: {
            isRecording,
            isRecordingInitiated,
            isIntroPlaying,
            isIntroStarted,
            dynamicCS,
            subtitlesLabel,
            openCSMenu,
            showAskForm,
            lastAnsweredTranscription,
            isPlayButtonOverlayVisible: showPlayButtonOverlay,
            isInstructionsVisible: showInstructionsOverlay,
            isEndEventModalVisible: endEventDialogueType,
            isAnswering: position === VIDEO_STATE.ANSWERING,
            isWaiting: isWaitingPosition
          },
          ask,
          reset: resetByLayout,
          handleButtonPress,
          handleButtonRelease,
          handleBackButtonPress: onBackButtonPress,
          handleSubtitlesChange,
          handleVoiceLangChange,
          dispatch
        }}
      >
        <Layout />
      </VideoScreenContext.Provider>
      {endEventDialogueType && (
        <EndEventDialogueBox
          interactData={endEventDialogueData}
          description={endEventDialogueDescription}
          urlButtons={endEventDialogueUrlButtons}
          type={endEventDialogueType}
          dispatch={dispatch}
        />
      )}
      <PermissionsReminderPopup isOpen={micPermissionPending} close={clearMicPermissionPending} />
    </>
  )
}

export default VideoScreen
