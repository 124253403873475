import React, { useState, useEffect, useRef } from 'react'

import { refreshPageWithStoryFile } from 'utils'
import StoryFileLogo from 'containers/AuthScreen/components/StoryFileLogo/StoryFileLogo'
import { MessageTypes, remoteControlReceiver } from 'remote-control'
import { sessionStorageService, SESSION_STORAGE_KEY } from 'utils/BrowserStorageService'
import { appParamsService } from 'configuration'

import ReceiverConnectionStatus from './components/ReceiverConnectionStatus/ReceiverConnectionStatus'
import styles from './ReceiverScreen.module.css'

const ReceiverScreen = () => {
  const [interacted, setInteracted] = useState(
    sessionStorageService.getItem(SESSION_STORAGE_KEY.RECEIVER_INTERACTED)
  )
  const [config, setConfig] = useState(null)

  const broadcastChannelRef = useRef(
    remoteControlReceiver ? undefined : new BroadcastChannel(appParamsService.rcReceiver)
  )

  useEffect(() => {
    if (remoteControlReceiver) {
      remoteControlReceiver.on(MessageTypes.ACTIVE_STORYFILE, onActiveStoryFileReceived)
      return () =>
        remoteControlReceiver.off(MessageTypes.ACTIVE_STORYFILE, onActiveStoryFileReceived)
    }

    const bc = broadcastChannelRef.current
    bc.postMessage({ type: MessageTypes.RECEIVER_CONNECTED })
    bc.postMessage({ type: MessageTypes.CONNECTION_STATUS, value: 'connected' })

    bc.onmessage = event => {
      const { type, value } = event.data
      if (type === MessageTypes.ACTIVE_STORYFILE || type === MessageTypes.RECEIVER_CONNECTED) {
        onActiveStoryFileReceived(value)
      }
    }

    return () => {
      bc.onmessage = null
    }
  }, [])

  useEffect(() => {
    if (config && interacted) refreshPageWithStoryFile(config)
  }, [interacted, config])

  const onActiveStoryFileReceived = initialConfig => setConfig(initialConfig)

  const onInteracted = () => {
    sessionStorageService.setItem(SESSION_STORAGE_KEY.RECEIVER_INTERACTED, true)
    setInteracted(true)
  }

  return (
    <div className={styles.container}>
      <StoryFileLogo />
      <ReceiverConnectionStatus
        hasUserInteracted={interacted}
        onPrimaryButtonClick={onInteracted}
      />
    </div>
  )
}

export default ReceiverScreen
