import React from 'react'
import classNames from 'classnames'

import { appParamsService } from 'configuration'
import serviceNowLogo from 'assets/images/ServiceNow/service-now-logo.png'

import styles from './ClientLogo.module.css'

const { unclickableLogo, isModeServiceNow } = appParamsService
const shouldRenderClientLogo = isModeServiceNow

const getClientLogoURL = () => {
  if (isModeServiceNow) {
    return ''
  }

  return ''
}
const CLIENT_URL = getClientLogoURL()

const ClientLogo = () => {
  if (!shouldRenderClientLogo) {
    return null
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.linkUnclickable]: unclickableLogo || !CLIENT_URL
      })}
    >
      <a href={CLIENT_URL} target="_blank" rel="noopener noreferrer" className={styles.link}>
        {isModeServiceNow && (
          <img className={styles.serviceNowLogo} src={serviceNowLogo} alt="ServiceNow" />
        )}
      </a>
      {shouldRenderClientLogo && <div className={styles.separator} />}
    </div>
  )
}

export default ClientLogo
