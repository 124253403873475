import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import ConversationStartersToggle from 'components/ConversationStarters/components/ConversationStartersToggle/ConversationStartersToggle'
import ConversationStartersHints from 'components/ConversationStarters/components/ConversationStartersHints/ConversationStartersHints'
import Translation from 'components/Translation/Translation'
import {
  COMMON_CONV_STARTERS_HINTS,
  COMMON_CONV_STARTERS_TOGGLE_LABEL
} from 'locales/translationIds'

import styles from './AskSantaConvStartersDropdown.module.css'

const AskSantaConvStartersDropdown = ({
  convStarters,
  isOpen,
  onToggle,
  onItemClick,
  containerClassName
}) => {
  const dropDownRef = useRef(null)

  useEffect(() => {
    // @TODO: Extract to hook
    const onOutsideClick = event => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        onToggle(false)
      }
    }

    document.addEventListener('touchstart', onOutsideClick)
    document.addEventListener('mousedown', onOutsideClick)
    return () => {
      document.removeEventListener('touchstart', onOutsideClick)
      document.removeEventListener('mousedown', onOutsideClick)
    }
  }, [dropDownRef, isOpen, onToggle])

  return (
    <div className={classNames(styles.container, containerClassName)}>
      <ConversationStartersHints tip={<Translation id={COMMON_CONV_STARTERS_HINTS} />} />
      <div className={styles.root} ref={dropDownRef}>
        <ConversationStartersToggle
          toggleClassName={classNames({
            [styles.toggleActive]: isOpen,
            [styles.toggleIdle]: !isOpen
          })}
          label={<Translation id={COMMON_CONV_STARTERS_TOGGLE_LABEL} />}
          onClick={() => onToggle(!isOpen)}
        />
        <div
          className={classNames(styles.dropdownContainer, {
            [styles.showDropdownContainer]: isOpen,
            [styles.hideDropdownContainer]: !isOpen
          })}
        >
          <div className={styles.header} />
          <ul className={styles.questionsList}>
            {convStarters.map(question => (
              <li key={question.id} onClick={() => onItemClick(question.text)}>
                {question.text}
              </li>
            ))}
          </ul>
          <div className={styles.footer} />
        </div>
      </div>
    </div>
  )
}

export default AskSantaConvStartersDropdown
