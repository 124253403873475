import React from 'react'
import classNames from 'classnames'

import Svg, { checkMark } from 'components/shared/Svg'
import resultsIcon from 'assets/images/star-cup-icon.svg'
import Translation from 'components/Translation/Translation'
import {
  SCORE_YOUR_RESULTS,
  COMMON_COMPLETED,
  COMMON_PERCENT,
  SCORE_YOUR_OVERALL_SCORE
} from 'locales/translationIds'

import styles from './SessionScoreHeader.module.css'

const FinalScoreHeader = ({ activityName, finalScore }) => {
  return (
    <div className={styles.container}>
      <div className={styles.resultsContainer}>
        <img className={styles.resultsIcon} src={resultsIcon} alt="" />
        <div>
          <div className={styles.resultsLabel}>
            <Translation id={SCORE_YOUR_RESULTS} />
          </div>
          <div className={styles.activityName}>{activityName}</div>
        </div>
      </div>
      <div className={styles.finalScoreContainer}>
        <div className={styles.finalScore}>
          <div
            className={classNames(styles.scoreValue, {
              [styles.scoreValueSmall]: finalScore === '100'
            })}
          >
            {finalScore}
          </div>
          <div className={styles.scoreValueMetric}>
            <Translation id={COMMON_PERCENT} />
          </div>
        </div>
        <div>
          <div className={styles.completedLabel}>
            {/* @TODO: Make label dynamic based on the final score. Awaiting thresholds from SF Team */}
            <Translation id={COMMON_COMPLETED} />
            <span className={styles.checkMark}>
              <Svg className={styles.checkMarkSVG} path={checkMark} />
            </span>
          </div>
          <div className={styles.overallScoreLabel}>
            <Translation id={SCORE_YOUR_OVERALL_SCORE} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinalScoreHeader
