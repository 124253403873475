import React from 'react'

import styles from './TextInput.module.css'

export default function TextInput({ type, label, elementId, autoComplete = 'off' }) {
  return (
    <input
      id={elementId}
      className={styles.input}
      type={type}
      name={type}
      placeholder={label}
      autoComplete={autoComplete}
    />
  )
}
