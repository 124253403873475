import React, { useState, useEffect, useCallback } from 'react'

import StoryFileLogo from 'containers/AuthScreen/components/StoryFileLogo/StoryFileLogo'
import { postDialogueStart } from 'api'
import { formatStoryFileConfig } from 'utils'
import { loadAuthCookie } from 'api/token-store'
import { appParamsService } from 'configuration'
import Translation from 'components/Translation/Translation'
import { COMMON_SELECT_STORYFILE_TO_INTERACT } from 'locales/translationIds'
import useIframeContentHeight from 'hooks/useIframeContentHeight'

import Card from './components/Card/Card'
import styles from './SelectScreen.module.css'

const { storyFileIdToLoad } = appParamsService
const SS_CONTAINER_ID = 'select-screen-container'

const StoryFiles = ({ storyFiles, setStoryFileConfig }) => {
  useIframeContentHeight(SS_CONTAINER_ID)

  return (
    <div id={SS_CONTAINER_ID} className={styles.container}>
      <StoryFileLogo />
      <div className={styles.headerText}>
        <Translation id={COMMON_SELECT_STORYFILE_TO_INTERACT} />
      </div>
      <div className={styles.cardsContainer}>
        {storyFiles.map(storyFile => (
          <Card key={storyFile.id} storyFile={storyFile} onSelect={setStoryFileConfig} />
        ))}
      </div>
    </div>
  )
}

const SelectScreen = ({ storyFiles, children }) => {
  const [config, setConfig] = useState()
  const auth = loadAuthCookie()

  const setStoryFileConfig = useCallback(
    storyFile => {
      if (!storyFile) {
        return
      }

      postDialogueStart(storyFile.id, auth?.iam)
      setConfig(formatStoryFileConfig(storyFile))
    },
    [auth]
  )

  useEffect(() => {
    if (storyFileIdToLoad) {
      const storyFileToLoad = storyFiles.find(sf => sf.id === storyFileIdToLoad)
      setStoryFileConfig(storyFileToLoad)
    }
  }, [storyFiles, setStoryFileConfig])

  const resetConfig = () => {
    appParamsService.setSharedTranscription('')
    setConfig(null)
  }

  return (
    <>
      {!config ? (
        <StoryFiles storyFiles={storyFiles} setStoryFileConfig={setStoryFileConfig} />
      ) : (
        children(config, { onBackButtonPress: resetConfig })
      )}
    </>
  )
}

export default SelectScreen
