import React, { useState, useContext, useRef, useEffect } from 'react'
import KeyHandler, { KEYPRESS } from 'react-key-handler'
import { Modal, FormControl } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { KeyboardReact as VirtualKeyboard } from 'react-simple-keyboard'

import { StoryFileContext } from 'App'
import Translation, { translate } from 'components/Translation/Translation'
import SettingsMenu from 'components/SettingsMenu/SettingsMenu'
import VideoScreenContext from 'containers/VideoScreen/Context'
import { appParamsService } from 'configuration'
import {
  COMMON_ASK_ASK_FORM_PLACEHOLDER,
  COMMON_ASK_ASK_FORM_PLACEHOLDER_SAFELITE,
  COMMON_ASK_ASK_FORM_BUTTON,
  COMMON_ASK_ASK_FORM_BUTTON_SAFELITE
} from 'locales/translationIds'
import { IS_FACEBOOK_ANDROID, handleKeyPress } from 'utils'

import styles from './AskForm.module.css'

const { isModeSanta, isModeSafelite, showVirtualKeyboard } = appParamsService

const VIRTUAL_KEYBOARD_LAYOUT = {
  default: 'default',
  shift: 'shift'
}

const AskForm = ({ show, onClose = () => {}, onSubmit = () => {}, onSpaceBarPress = () => {} }) => {
  const [text, setText] = useState('')
  const [backdrop, setBackdrop] = useState(!IS_FACEBOOK_ANDROID || 'static')
  const [layout, setLayout] = useState(VIRTUAL_KEYBOARD_LAYOUT.default)

  const keyboardRef = useRef()
  const shouldShowVirtualKeyboard = !isMobile && showVirtualKeyboard

  const {
    state: { isAnswering, subtitlesLabel },
    handleSubtitlesChange,
    handleVoiceLangChange
  } = useContext(VideoScreenContext)
  const { subtitlesInfo: subtitles } = useContext(StoryFileContext)

  useEffect(() => {
    if (show && shouldShowVirtualKeyboard) {
      const modalDialog = document.querySelector('.modal-dialog')
      if (modalDialog) {
        modalDialog.classList.add('modal-dialog-virtual-keyboard')
      }
    }
  }, [show, shouldShowVirtualKeyboard])

  const onVirtualKeyboardChange = keyboardInput => {
    if (!text && !keyboardInput.trim()) {
      return
    }

    setText(keyboardInput)
  }

  const handleShift = () => {
    const newLayoutName =
      layout === VIRTUAL_KEYBOARD_LAYOUT.default
        ? VIRTUAL_KEYBOARD_LAYOUT.shift
        : VIRTUAL_KEYBOARD_LAYOUT.default

    setLayout(newLayoutName)
  }

  const onVirtualKeyboardPress = button => {
    if (button === '{enter}') {
      submit()
    }

    if (button === '{shift}' || button === '{lock}') {
      handleShift()
    }
  }

  const submit = () => {
    onSubmit(text.trim())
    setText('')
  }

  const onModalClose = () => {
    onClose()
    setText('')
  }

  const handleTextInput = e => {
    const input = e.target.value
    if (!text && input === ' ') {
      return
    }

    setText(input)
    if (shouldShowVirtualKeyboard) {
      keyboardRef.current.setInput(input)
    }
  }

  const onModalEntered = () => {
    if (IS_FACEBOOK_ANDROID) {
      setBackdrop(true)
    }

    if (isModeSafelite) {
      setBackdrop('static')
    }
  }

  return (
    <>
      <KeyHandler keyEventName={KEYPRESS} keyValue=" " onKeyHandle={onSpaceBarPress} />
      <Modal
        show={show}
        onHide={onModalClose}
        onEntered={onModalEntered}
        keyboard={!isModeSafelite}
        backdrop={backdrop}
        backdropClassName={styles.modalBackdrop}
      >
        <Modal.Body>
          {!!shouldShowVirtualKeyboard && (
            <div className={styles.virtualKeyboardContainer}>
              <VirtualKeyboard
                keyboardRef={r => (keyboardRef.current = r)}
                layoutName={layout}
                physicalKeyboardHighlight={true}
                onChange={onVirtualKeyboardChange}
                onKeyPress={onVirtualKeyboardPress}
              />
            </div>
          )}
          <FormControl
            id={'ask-form-input'}
            value={text}
            onChange={handleTextInput}
            onKeyDown={e => handleKeyPress(e, 'Enter', submit, !!text.trim())}
            className="custom-input"
            required
            dir="auto"
            autoFocus={!isMobile}
            placeholder={translate(
              isModeSafelite
                ? COMMON_ASK_ASK_FORM_PLACEHOLDER_SAFELITE
                : COMMON_ASK_ASK_FORM_PLACEHOLDER
            )}
          />
          {isModeSafelite && (
            <div className={styles.inputBtnsContainer}>
              <SettingsMenu
                className={styles.settingsMenu}
                disableSubtitlesMenu={isAnswering}
                initialSubtitles={subtitlesLabel}
                subtitles={subtitles}
                onSubtitlesChange={handleSubtitlesChange}
                onVoiceLangChange={handleVoiceLangChange}
              />
              <button
                id="ask-form-btn"
                className={classNames(styles.askQuestionButton, {
                  [styles.askQuestionButtonDisabled]: !text
                })}
                style={{ width: '90px' }}
                onClick={submit}
                disabled={!text}
              >
                <Translation id={COMMON_ASK_ASK_FORM_BUTTON_SAFELITE} />
              </button>
            </div>
          )}
          {!isModeSanta && !isModeSafelite && (
            <Modal.Footer>
              <button
                id="ask-form-btn"
                className={classNames(styles.askQuestionButton, {
                  [styles.askQuestionButtonDisabled]: !text
                })}
                onClick={submit}
                disabled={!text}
              >
                <Translation id={COMMON_ASK_ASK_FORM_BUTTON} />
              </button>
            </Modal.Footer>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AskForm
