export const URL_PARAMS = {
  mode: 'mode',
  skin: 'skin',
  transcription: 'transcription',
  waveform: 'waveform',
  user: 'user',
  uid: 'uid',
  quality: 'quality',
  sid: 'sid',
  cid: 'cid',
  bg: 'bg',
  tcp: 'tcp',
  po: 'po',
  l: 'l',
  st: 'st',
  zoom: 'zoom',
  ats: 'ats',
  introId: 'introId',
  subs: 'subs',
  lm: 'lm',
  al: 'al',
  al_end: 'al_end',
  al_interrupt: 'al_interrupt',
  cs: 'cs',
  cs_open: 'cs_open',
  static: 'static',
  electron: 'electron',
  sso: 'sso',
  intro: 'intro',
  ch: 'ch',
  private: 'private',
  ssc: 'ssc',
  si: 'si',
  taskbar: 'taskbar',
  taskbaropen: 'taskbaropen',
  key: 'key',
  scorm: 'scorm',
  topics: 'topics',
  ddq: 'ddq',
  hwbi: 'hwbi',
  swbi: 'swbi',
  td: 'td',
  tvd: 'tvd',
  testaftervideo: 'testaftervideo',
  rct: 'rct',
  rcr: 'rcr',
  rcWired: 'rcwired',
  rcReceiverWindowEvent: 'rcReceiverWindowEvent',
  sso_loading: 'sso_loading',
  login_popup: 'login_popup',
  ulogo: 'ulogo',
  dd: 'dd',
  dtv: 'dtv',
  dpb: 'dpb',
  debug: 'debug',
  sfsw: 'sfsw',
  sfswi: 'sfswi',
  uwv: 'uwv',
  testbluetooth: 'testbluetooth',
  asrv: 'asrv',
  holdToTalkV2: 'httv2',
  testNewSpeech2Text: 'tests2t',
  experimentalFeatures: 'ef',
  customEventsOnVideoStart: 'ceovs',
  fullScreenButton: 'fsb',
  virtualKeyboard: 'vk',
  persistMicrophone: 'pmic'
}

export const IDLE_EVENTS = [
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange'
]
