import { envConfig } from 'configuration'

const RECONNECT_TIMEOUT_DURATION = 3000
const RECONNECT_TIMEOUT_DURATION_SECONDS = RECONNECT_TIMEOUT_DURATION / 1000

class GoogleSpeechRecognizer {
  async connect() {
    let self = this

    if (!this.connected) {
      this.reconnecting = false
      this.socket = new WebSocket(envConfig.ASR_API)

      this.socket.onmessage = function (message) {
        let msg = JSON.parse(message.data)
        if (self.listener) {
          self.listener(msg.data)
        }
      }
      this.ready = await new Promise((resolve, reject) => {
        let self = this

        this.socket.onopen = function (event) {
          self.connected = true

          console.log('Connected to server for speech2text')

          resolve()
        }

        this.socket.onerror = function (event) {
          self.connected = false

          console.log(
            `Error. Disconnected...Reconnecting in ${RECONNECT_TIMEOUT_DURATION_SECONDS}s`
          )

          self.reconnect()
          reject(event)
        }

        this.socket.onclose = function (event) {
          self.connected = false
          console.log(
            `Disconnected from server...Reconnecting in ${RECONNECT_TIMEOUT_DURATION_SECONDS}s`
          )

          self.reconnect()
          reject(event)
        }
      }).catch(e => console.error(e))
    }

    return this
  }

  reconnect() {
    if (!this.connected && !this.reconnecting) {
      this.reconnecting = true

      setTimeout(() => {
        this.connect()
      }, RECONNECT_TIMEOUT_DURATION)
    }
  }

  async start(listener, trainingData, lang, asrVendor, storyfileId) {
    let opts = { storyfileId }

    await this.ready
    this.listener = listener

    if (this.connected) {
      this.socket.send(
        JSON.stringify({ type: 'RECOGNIZE_START', lang, trainingData, vendor: asrVendor, opts })
      )
    }
  }

  async recognize(blob) {
    await this.ready

    if (this.connected) {
      this.socket.send(blob)
    }
  }

  async stop() {
    await this.ready

    if (this.connected) {
      this.socket.send(JSON.stringify({ type: 'RECOGNIZE_END' }))
    }
  }
}

export const googleSpeechRecognizer = new GoogleSpeechRecognizer()
