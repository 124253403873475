import { useEffect } from 'react'

import { loadAuthCookie, storeAuthCookie, parseAuth } from 'api/token-store'
import { appParamsService, envConfig } from 'configuration'
import { URL_PARAMS } from 'constants'

export const KeycloakIDP = {
  Facebook: 'facebook',
  Google: 'google',
  Medtronic: 'medtronic',
  Walmart: 'walmart'
}

const Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null

let keycloakInstance

export const useKeycloak = (
  shouldInit,
  checkKcToken = false,
  onAuthenticatedCallback = () => {}
) => {
  useEffect(() => {
    if (shouldInit) {
      initKeycloakInstance()
    }
  }, [])

  const initKeycloakInstance = async onAuthenticatedCallback => {
    keycloakInstance = new Keycloak({
      url: envConfig['KEYCLOAK_URL'],
      realm: envConfig['KEYCLOAK_REALM'],
      clientId: envConfig['KEYCLOAK_CLIENT'],
      'ssl-required': 'external',
      'public-client': true
    })

    await init(onAuthenticatedCallback)
  }

  const init = async () => {
    let initConfig = {}
    const initialLocation = window.location.href

    initConfig.redirectUri = `${initialLocation}&${URL_PARAMS.sso_loading}=1${
      appParamsService.SCORMWrapper ? `&${URL_PARAMS.login_popup}=1` : ''
    }`

    if (navigator.cookieEnabled) {
      initConfig.onLoad = 'check-sso'
      initConfig.silentCheckSsoRedirectUri = window.location.origin + '/silent-check-sso.html'
    } else {
      initConfig.onLoad = 'login-required'
      initConfig.flow = 'hybrid'
    }

    const cachedAuth = loadAuthCookie()

    if (cachedAuth) {
      initConfig.token = cachedAuth.accessToken
      initConfig.refreshToken = cachedAuth.refreshToken
    }

    keycloakInstance
      .init(initConfig)
      .then(authenticated => {
        if (!authenticated || !checkKcToken) {
          return
        }

        const token = {
          accessToken: keycloakInstance.token,
          expiresIn: keycloakInstance.tokenParsed?.exp,
          ts: keycloakInstance.tokenParsed?.auth_time,
          scope: keycloakInstance.tokenParsed?.scope,
          roles: keycloakInstance.tokenParsed?.realm_access?.roles,
          refreshToken: keycloakInstance.refreshToken,
          refreshExpiresIn: keycloakInstance.refreshTokenParsed?.exp,
          iam: keycloakInstance.idTokenParsed?.preferred_username,
          email: keycloakInstance.idTokenParsed?.email,
          isIdpLogin: true
        }
        const auth = parseAuth(token)

        storeAuthCookie(auth)
        onAuthenticatedCallback()
      })
      .catch(console.error)
  }

  const doLogin = idp => keycloakInstance.login({ idpHint: idp })

  const getLoginUrl = idp => keycloakInstance.createLoginUrl({ idpHint: idp })

  const doLogout = () => {
    try {
      keycloakInstance?.logout()
    } catch (error) {
      console.warn('Error during keycloak logout')
    }
  }

  return { doLogin, doLogout, getLoginUrl, initKeycloakInstance }
}

export default useKeycloak
