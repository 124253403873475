import { useCallback, useEffect, useRef } from 'react'
import { useIdleTimer as useIdleTimerClient } from 'react-idle-timer'

import { IDLE_EVENTS } from 'constants'

import { VIDEO_STATE } from '../constants'
import { ACTION } from './useStateReducer'

const useIdleTimer = (startTimer, videoState, timeoutDurationSeconds, dispatch) => {
  const idleHandler = useCallback(
    idleState =>
      videoState === VIDEO_STATE.WAITING && dispatch({ type: ACTION.SET_IDLE, payload: idleState }),
    [videoState, dispatch]
  )

  const idleTimerRef = useRef(
    useIdleTimerClient({
      onIdle: () => idleHandler(true),
      onActive: () => idleHandler(false),
      timeout: timeoutDurationSeconds * 1000,
      startManually: true,
      events: IDLE_EVENTS
    })
  )

  useEffect(() => {
    if (startTimer) idleTimerRef.current.start()
  }, [startTimer])

  return idleTimerRef.current
}

export default useIdleTimer
