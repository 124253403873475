import React from 'react'

import Modal from 'components/Modal/Modal'

import styles from './PlayButtonOverlay.module.css'

const PlayButtonOverlay = ({ onClick }) => {
  return (
    <Modal isOpen={true} closeOnOverlayClick={false} className={styles.overlay}>
      <button
        id="play-intro-btn"
        className={styles.playButton}
        onClick={onClick}
        aria-label="Play"
      />
    </Modal>
  )
}

export default PlayButtonOverlay
