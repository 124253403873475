import { useEffect, useState } from 'react'

import { getConversationStarters } from 'api'
import { appParamsService } from 'configuration'

const groupByTopic = questions => {
  let menuItems = []

  questions.forEach(q => {
    if (appParamsService.groupConvStartersByTopics) {
      const isAdded = menuItems.find(topic => topic.text === q.topic.name)

      if (!isAdded) {
        const children = questions
          .filter(question => question.topic.name === q.topic.name)
          .map(child => {
            const { topic, ...rest } = child
            return rest
          })
          .sort((a, b) => a.sort_order - b.sort_order)

        menuItems.push({
          text: q.topic.name,
          id: q.topic.id,
          sortOrder: q.topic.sort_order,
          isTopic: true,
          children
        })
      }
    } else {
      menuItems.push({
        text: q.text,
        id: q.id,
        sortOrder: q.sort_order,
        isTopic: false
      })
    }
  })

  // Requested by SF Team to not group if there's only 1 topic
  if (menuItems.length === 1 && menuItems[0].isTopic) {
    menuItems = menuItems[0].children
  }

  return menuItems.sort((a, b) => a.sortOrder - b.sortOrder)
}

const useConversationStarters = userId => {
  const [convStarters, setConvStarters] = useState([])

  useEffect(() => {
    const fetchConvStarters = async userId => {
      try {
        const {
          data: { conversationStarters }
        } = await getConversationStarters(userId)

        const convStarters = groupByTopic(conversationStarters)
        setConvStarters(convStarters)
      } catch {
        console.log('API unavailable')
      }
    }

    fetchConvStarters(userId)
  }, [userId])

  return convStarters
}

export default useConversationStarters
