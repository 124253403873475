const SLIDING_WINDOW_SIZE = 3

const asKbps = (bytes, millis) => (bytes * 8) / millis

const getDownloadTime = url =>
  new Promise(resolve => {
    const start = new Date().getTime()
    const img = new Image()
    img.onload = () => {
      const timeTaken = new Date().getTime() - start
      resolve(timeTaken)
    }
    img.src = `${url}?t=${start}`
  })

const getAverageSpeed = (last => {
  const lastValues = []
  return currentValue => {
    lastValues.push(currentValue)
    if (lastValues.length > last) {
      lastValues.shift()
    }
    return lastValues.reduce((a, b) => a + b, 0) / lastValues.length
  }
})(SLIDING_WINDOW_SIZE)

const defaultConfig = {
  timesToTest: 1,
  url: 'https://public-storage-bucket-1.s3.amazonaws.com/logo_200kb.jpg',
  size: 208951
}

const getInternetSpeed = async (config = {}) => {
  const { url, size } = { ...defaultConfig, ...config }
  const avgTime = await getDownloadTime(url)
  const speed = asKbps(size, avgTime)
  const averageSpeed = getAverageSpeed(speed)

  // Uncomment for when testing
  // console.log(`Time to download ${size} bytes: ${avgTime} ms.`)
  // console.log(`Speed: ${speed} kbps.`)
  // console.log('Average speed: ', averageSpeed)

  return averageSpeed
}

export default getInternetSpeed
