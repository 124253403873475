import React from 'react'
import classNames from 'classnames'

import styles from './ConversationStartersToggle.module.css'

const ConversationStartersToggle = ({
  children,
  isExpanded,
  onClick,
  label = '',
  toggleClassName,
  toggleLabelClassName
}) => (
  <button
    id="conv-starters-btn"
    className={classNames(styles.toggle, toggleClassName)}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    aria-expanded={isExpanded}
    aria-controls="conv-starters-menu"
  >
    <div className={classNames(styles.toggleLabel, toggleLabelClassName)}>{label}</div>
    {children}
  </button>
)

export default ConversationStartersToggle
