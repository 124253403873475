import React from 'react'

import FormButton from 'components/FormButton/FormButton'
import { RECEIVER_STATE } from 'remote-control/receiver'
import Translation from 'components/Translation/Translation'
import {
  COMMON_ACTIVATE_FROM_TRANSMITTER_DEVICE,
  COMMON_REMOTE_RECEIVER_MODE,
  COMMON_CONTINUE
} from 'locales/translationIds'
import usePeerConnectionStatus from 'hooks/usePeerConnectionStatus'
import { appParamsService } from 'configuration'

import styles from './ReceiverConnectionStatus.module.css'

const ReceiverConnectionStatus = ({ hasUserInteracted, onPrimaryButtonClick }) => {
  const status = usePeerConnectionStatus()

  let isReceiverConnected
  if (appParamsService.rcWired) {
    isReceiverConnected = status === 'connected'
  } else {
    isReceiverConnected = status?.state === RECEIVER_STATE.CONNECTED
  }

  return (
    <div className={styles.container}>
      <b className={styles.header}>
        <Translation id={COMMON_REMOTE_RECEIVER_MODE} />
      </b>
      <div>{status?.label || status}</div>
      <div className={styles.buttonContainer}>
        {hasUserInteracted && isReceiverConnected ? (
          <Translation id={COMMON_ACTIVATE_FROM_TRANSMITTER_DEVICE} />
        ) : (
          <FormButton
            elementId="receiver-status-continue-btn"
            text={<Translation id={COMMON_CONTINUE} />}
            onClick={onPrimaryButtonClick}
            loading={!isReceiverConnected}
            disabled={!isReceiverConnected}
          />
        )}
      </div>
    </div>
  )
}

export default ReceiverConnectionStatus
