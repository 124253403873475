import { useEffect } from 'react'
import { isMobile, isSafari, isTablet } from 'react-device-detect'

import { getInnerWindowHeight, debounce } from 'helpers'

const scrollToDebounced = debounce((x, y) => {
  window.scrollTo(x, y)
}, 300)

const useWindowResizer = () => {
  useEffect(() => {
    getInnerWindowHeight()

    const onResize = () => {
      getInnerWindowHeight()
      // Force browser to show hidden navigation bar and recalculate inner height
      if (isMobile && isSafari && !isTablet) scrollToDebounced(0, 1)
    }

    window.addEventListener('resize', onResize, false)

    return () => window.removeEventListener('resize', onResize, false)
  }, [])
}

export default useWindowResizer
