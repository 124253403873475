import React from 'react'

import Modal from 'components/Modal/Modal'
import Translation from 'components/Translation/Translation'
import { COMMON_GET_STARTED } from 'locales/translationIds'

import usePageContent, { isSectionEmpty } from './hooks/usePageContent'

import styles from './InstructionsOverlay.module.css'

const InstructionsOverlay = ({ onStartClick }) => {
  const { headerText, instructionsTitle: title, instructions, footerText } = usePageContent()

  return (
    <Modal isOpen={true} closeOnOverlayClick={false} className={styles.modalOverlay}>
      <div className={styles.container}>
        <div className={styles.body}>
          {!isSectionEmpty(headerText) && <span className={styles.header}>{headerText}</span>}
          {!isSectionEmpty(title) && <div className={styles.title}>{title}</div>}
          {!isSectionEmpty(instructions) && (
            <div>
              <ol>{instructions}</ol>
            </div>
          )}
          {!isSectionEmpty(footerText) && <div className={styles.footer}>{footerText}</div>}
        </div>
        <button className={styles.startButton} onClick={onStartClick}>
          <Translation id={COMMON_GET_STARTED} />
        </button>
      </div>
    </Modal>
  )
}

export default InstructionsOverlay
