import React, { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import { StoryFileContext } from 'App'
import { appParamsService, envConfig } from 'configuration'
import { IS_FACEBOOK_ANDROID } from 'utils'
import AskForm from 'components/AskForm/AskForm'
import FullScreenButton from 'components/FullScreenButton/FullScreenButton'
import BottomDrawer, { DRAWER_ACTIONS } from 'components/BottomDrawer/BottomDrawer'
import ConversationStarters from 'components/ConversationStarters/ConversationStarters'
import MicIndicator from 'components/MicIndicator/MicIndicator'
import SettingsMenu from 'components/SettingsMenu/SettingsMenu'

import toggleButtonImage from 'assets/images/AskSanta/show_menu_icon.png'
import santaImage from 'assets/images/AskSanta/santa.png'
import elfImage from 'assets/images/AskSanta/elf.png'
import letterImage from 'assets/images/AskSanta/letter.png'
import deerImage from 'assets/images/AskSanta/deer.png'
import northpoleImage from 'assets/images/AskSanta/northpole.png'
import giftImage from 'assets/images/AskSanta/gift.png'

import SkipIntroButton from '../SkipIntroButton/SkipIntroButton'
import { LayoutCallbackContext } from '../Layout'
import VideoScreenContext from '../../../Context'
import { SOURCE_OF_INTERACTION } from '../../../constants'

import styles from './AskSantaLayout.module.css'

const { showConversationStarters, isModeDefault } = appParamsService

const DRAWER_CONFIG = {
  toggleImage: toggleButtonImage,
  buttons: [
    {
      image: santaImage,
      action: { type: DRAWER_ACTIONS.QUESTION, value: 'Ask me a question' }
    },
    {
      image: elfImage,
      action: { type: DRAWER_ACTIONS.QUESTION, value: 'What can I ask you about elves button' }
    },
    {
      image: letterImage,
      action: { type: DRAWER_ACTIONS.QUESTION, value: 'Read me a letter' }
    },
    {
      image: deerImage,
      action: { type: DRAWER_ACTIONS.QUESTION, value: 'What can I ask you about reindeer button' }
    },
    {
      image: northpoleImage,
      action: { type: DRAWER_ACTIONS.QUESTION, value: 'What can I ask you about north pole button' }
    },
    {
      image: giftImage,
      action: { type: DRAWER_ACTIONS.QUESTION, value: 'What can I ask you about gifts button' }
    }
  ]
}

const AskSantaLayout = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const { userId, subtitlesInfo: subtitles } = useContext(StoryFileContext)
  const {
    state: {
      isPlayButtonOverlayVisible,
      isIntroPlaying,
      isIntroStarted,
      isRecording,
      isRecordingInitiated,
      isWaiting,
      isAnswering,
      dynamicCS,
      openCSMenu,
      showAskForm,
      subtitlesLabel
    },
    ask,
    handleButtonPress,
    handleButtonRelease,
    handleSubtitlesChange,
    handleVoiceLangChange
  } = useContext(VideoScreenContext)
  const {
    renderAskForm,
    openAskForm,
    closeAskForm,
    onAskFormSubmitted,
    onCSClicked,
    onCSMenuClosed,
    onAskFormSpaceBarPressed,
    onSkipButtonClicked
  } = useContext(LayoutCallbackContext)

  // TODO: clean up logic here after PLAY_BUTTON_ON_ERROR is closed
  const renderWhenPlayButtonIsVisible =
    envConfig['PLAY_BUTTON_ON_ERROR'] || !isPlayButtonOverlayVisible
  const shouldRenderCS =
    showConversationStarters && renderWhenPlayButtonIsVisible && !isIntroPlaying
  const shouldRenderMic =
    renderWhenPlayButtonIsVisible && !isIntroPlaying && !isModeDefault && !IS_FACEBOOK_ANDROID
  const shouldRenderSettingsMenu = renderWhenPlayButtonIsVisible && !isIntroPlaying
  const shouldRenderFullScreenButton = !isMobile && renderWhenPlayButtonIsVisible && !isIntroPlaying

  const onQuestionClicked = questionText => {
    if (!isWaiting) {
      return
    }
    ask(questionText, { source: SOURCE_OF_INTERACTION.SANTA_MENU }, false)
  }

  const onToggleDrawerClicked = visible => setDrawerVisible(visible)

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        {shouldRenderFullScreenButton && <FullScreenButton className={styles.fullScreenButton} />}
      </div>
      {isIntroStarted && !isPlayButtonOverlayVisible && (
        <SkipIntroButton
          containerClassName={styles.skipIntroButton}
          onClick={onSkipButtonClicked}
          showIcon={false}
        />
      )}
      {renderWhenPlayButtonIsVisible && !isIntroPlaying && (
        <BottomDrawer
          drawerButtonsConfig={DRAWER_CONFIG}
          drawerActions={{
            [DRAWER_ACTIONS.QUESTION]: onQuestionClicked
          }}
          onMenuKeyboardClick={openAskForm}
          onToggleDrawerClick={onToggleDrawerClicked}
          drawerClassName={classNames({ [styles.bottomDrawer]: !isWaiting })}
        />
      )}
      {renderAskForm && (
        <AskForm
          show={showAskForm}
          onClose={closeAskForm}
          onSubmit={onAskFormSubmitted}
          onSpaceBarPress={onAskFormSpaceBarPressed}
        />
      )}
      <div
        className={classNames(styles.section, {
          [styles.sectionMinHeight]: showConversationStarters
        })}
      >
        {shouldRenderCS && (
          <ConversationStarters
            userId={userId}
            dynamicConvStarters={dynamicCS}
            openMenu={openCSMenu}
            onStarterClick={onCSClicked}
            onMenuClose={onCSMenuClosed}
            dropdownContainerClassName={styles.convStartersDropdownContainer}
          />
        )}
        <div className={styles.rightSubSection}>
          {shouldRenderSettingsMenu && (
            <SettingsMenu
              className={classNames(styles.settingsMenu, {
                [styles.settingsMenuHidden]: drawerVisible
              })}
              menuClassName={styles.settingsMenuContainer}
              disableSubtitlesMenu={isAnswering}
              initialSubtitles={subtitlesLabel}
              subtitles={subtitles}
              onSubtitlesChange={handleSubtitlesChange}
              onVoiceLangChange={handleVoiceLangChange}
            />
          )}
          {shouldRenderMic && (
            <MicIndicator
              isActive={isRecording}
              showActiveCircle={isRecordingInitiated}
              indicatorCircleClassName={styles.micIndicatorCircle}
              onTouchStart={handleButtonPress}
              onTouchEnd={handleButtonRelease}
              onMouseDown={handleButtonPress}
              onMouseUp={handleButtonRelease}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AskSantaLayout
