import React, { useRef, useState, useEffect, useContext } from 'react'
import {
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share'
import classNames from 'classnames'

import { postScrapeShareLink } from 'api'
import { StoryFileContext } from 'App'
import Translation from 'components/Translation/Translation'
import {
  COMMON_SHARE_LAST_QUESTION,
  COMMON_SHARE,
  COMMON_COPY,
  COMMON_COPIED,
  COMMON_COPY_LINK
} from 'locales/translationIds'

import VideoScreenContext from '../../../../Context'
import EmailShareButton from './CustomEmailShareButton'
import useEmailButtonProps from './hooks/useEmailButtonProps'
import { generateShareUrl, generateQuoteMeta } from '../util'

import styles from './ShareForm.module.css'

const ShareForm = ({ onCloseClick }) => {
  const { username, userId } = useContext(StoryFileContext)
  const {
    state: { lastAnsweredTranscription: transcription }
  } = useContext(VideoScreenContext)
  const [url, setUrl] = useState(generateShareUrl(transcription, userId))
  const [quote, setQuote] = useState(generateQuoteMeta(transcription, username))
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const linkInputRef = useRef()
  const scrapedUrls = useRef([])
  const { url: postUrl, ...emailProps } = useEmailButtonProps(username)
  const hasTranscription = Boolean(transcription)
  let hideClipBoardMessageTimeout

  useEffect(() => {
    if (!scrapedUrls.current.includes(url)) {
      scrapedUrls.current.push(url)
      postScrapeShareLink(url).catch(err => {})
    }
  }, [url, scrapedUrls])

  useEffect(() => () => clearInterval(hideClipBoardMessageTimeout), [hideClipBoardMessageTimeout])

  const onCheckboxChange = e => {
    const transcript = e.target.checked ? transcription : ''
    setUrl(generateShareUrl(transcript, userId))
    setQuote(generateQuoteMeta(transcript, username))
  }

  const onCopyLinkButtonClick = e => {
    linkInputRef.current.select()
    document.execCommand('copy')
    setIsTooltipVisible(true)
    hideClipBoardMessageTimeout = setTimeout(() => setIsTooltipVisible(false), 1000)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Translation id={COMMON_SHARE} />
        </div>
        {/* eslint-disable-next-line */}
        <a className={styles.closeButton} onClick={onCloseClick} />
      </div>
      <div className={styles.body}>
        <div className={styles.buttonsContainer}>
          <FacebookShareButton url={url} quote={quote} className={styles.shareButton}>
            <FacebookIcon className={styles.shareButtonIcon} round />
            <div className={styles.shareButtonTitle}>Facebook</div>
          </FacebookShareButton>
          <LinkedinShareButton url={url} title={quote} className={styles.shareButton}>
            <LinkedinIcon className={styles.shareButtonIcon} round />
            <div className={styles.shareButtonTitle}>LinkedIn</div>
          </LinkedinShareButton>
          <TwitterShareButton url={url} title={quote} className={styles.shareButton}>
            <TwitterIcon className={styles.shareButtonIcon} round />
            <div className={styles.shareButtonTitle}>Twitter</div>
          </TwitterShareButton>
          <EmailShareButton className={styles.shareButton} {...emailProps} url={url + postUrl}>
            <EmailIcon className={styles.shareButtonIcon} round />
            <div className={styles.shareButtonTitle}>Email</div>
          </EmailShareButton>
        </div>
        <div className={styles.checkboxContainer}>
          <input
            className={styles.checkbox}
            type="checkbox"
            defaultChecked={hasTranscription}
            disabled={!hasTranscription}
            onChange={onCheckboxChange}
          />
          <label
            className={classNames(styles.checkboxLabel, {
              [styles.checkboxLabelDisabled]: !hasTranscription
            })}
          >
            <Translation id={COMMON_SHARE_LAST_QUESTION} />
          </label>
        </div>
        <div className={styles.copyLinkContainer}>
          <input ref={linkInputRef} className={styles.copyLink} readOnly value={url} />
          <div
            className={classNames(styles.tooltip, { [styles.tooltipVisible]: isTooltipVisible })}
          >
            <button
              className={classNames(styles.copyLinkButton, styles.copyLinkButtonRipple)}
              onClick={onCopyLinkButtonClick}
            >
              <Translation id={COMMON_COPY} />
            </button>
            <span className={styles.tooltipText}>
              <Translation id={COMMON_COPIED} />
            </span>
          </div>
          <div className={styles.copyLinkTitle}>
            <Translation id={COMMON_COPY_LINK} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareForm
