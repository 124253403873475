import React from 'react'
import classNames from 'classnames'

import Translation from 'components/Translation/Translation'
import { COMMON_CONV_STARTERS_NO_HINTS } from 'locales/translationIds'
import Svg, { play, infoOutline } from 'components/shared/Svg'

import ConvStartersDropdownTopic from './ConvStartersDropdownTopic'

import styles from './ConvStartersList.module.css'

const PlayIcon = () => (
  <span className={styles.playIcon}>
    <Svg width={20} height={20} path={play} />
  </span>
)

const InfoIcon = () => (
  <span className={styles.hintIcon}>
    <Svg width={20} height={20} path={infoOutline} />
  </span>
)

const ConvStartersList = ({ convStarters, dynamicConvStarters, onItemClick, isOpen }) => {
  const renderConvStarters = q =>
    q.isTopic ? (
      <ConvStartersDropdownTopic
        key={`${q.id}-${q.text}`}
        topic={q}
        isMenuExpanded={isOpen}
        onQuestionClick={onItemClick}
        icon={<PlayIcon />}
      />
    ) : (
      <div key={q.id} className={styles.item}>
        <button
          onClick={() => onItemClick(q.text)}
          className={classNames(styles.questionButton, styles.border)}
          tabIndex={isOpen ? 0 : -1}
        >
          <PlayIcon />
          {q.text}
        </button>
      </div>
    )

  const renderDynamicConvStarters = (q, i) =>
    q.type === 'question' || q.type === 'option' ? (
      <div key={i} className={classNames(styles.item)}>
        <button
          onClick={() => onItemClick(q.value)}
          className={classNames(styles.questionButton, styles.border)}
          tabIndex={isOpen ? 0 : -1}
        >
          <PlayIcon />
          {q.value}
        </button>
      </div>
    ) : (
      <div className={classNames(styles.item, styles.questionButton, styles.hint)} key={i}>
        <InfoIcon />
        {q.value}
      </div>
    )

  const renderListItems = () => {
    if (dynamicConvStarters?.length) {
      return dynamicConvStarters.map(renderDynamicConvStarters)
    }

    if (convStarters?.length) {
      return convStarters.map(renderConvStarters)
    }

    return (
      <div className={styles.emptyMenuText}>
        <Translation id={COMMON_CONV_STARTERS_NO_HINTS} />
      </div>
    )
  }

  return <div className={styles.questionsList}>{renderListItems()}</div>
}

export default ConvStartersList
