import React from 'react'

import Translation from 'components/Translation/Translation'
import { COMMON_LISTENING } from 'locales/translationIds'

import styles from './RecordingLabel.module.css'

const RecordingLabel = () => (
  <div className={styles.container}>
    <span className={styles.recordingDot}></span>
    <span>
      <Translation id={COMMON_LISTENING} />
    </span>
  </div>
)

export default RecordingLabel
