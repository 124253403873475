import { DEFAULT_LANGUAGE } from 'shared/constants'
import { getLanguageByTag } from 'shared/utils'
import {
  localStorageService,
  LOCAL_STORAGE_KEY,
  sessionStorageService,
  SESSION_STORAGE_KEY
} from 'utils/BrowserStorageService'

export const generateSessionId = (t = 32) => {
  // source: https://github.com/ai/nanoid
  let e = ''
  let r = crypto.getRandomValues(new Uint8Array(t))
  for (; t--; ) {
    let n = 63 & r[t]
    e += n < 36 ? n.toString(36) : n < 62 ? (n - 26).toString(36).toUpperCase() : n < 63 ? '_' : '-'
  }
  return e
}

export const extractQueryParameters = () => {
  const { search } = window.location
  if (search.includes('preset=') && !window.__APP_PARAMS_CONFIG__)
    console.error('App params config is specified but not hydrated into html.')

  const query = window.__APP_PARAMS_CONFIG__ || search
  const queryParameterStrings = query.substring(1).split('&')
  let queryParameters = {}

  if (queryParameterStrings) {
    queryParameterStrings.forEach(queryParamString => {
      const splitQueryParamString = queryParamString.split('=')
      queryParameters[splitQueryParamString[0]] = decodeURIComponent(splitQueryParamString[1])
    })
  }

  return queryParameters
}

export const parseStoryFileIds = param => {
  if (!param) {
    return
  }

  const data = param.split('_') // split the collection of ids and the id to load
  const ids = data[0].split(';')
  return {
    storyFileIds: ids.map(id => Number(id)),
    storyFileIdToLoad: Number(data[1])
  }
}

export const parseLanguage = (inputTag, isTransmitter, isReceiver) => {
  let tag
  const { tag: defaultTag } = DEFAULT_LANGUAGE
  switch (true) {
    case isReceiver:
      tag = inputTag || defaultTag
      break
    case isTransmitter:
      tag =
        sessionStorageService.getItem(SESSION_STORAGE_KEY.TRANSMITTER_VOICE_LANG)?.tag || defaultTag
      break
    default:
      tag = inputTag || localStorageService.getItem(LOCAL_STORAGE_KEY.VOICE_LANG)?.tag || defaultTag
  }

  const language = getLanguageByTag(tag)
  console.log(`Now using '${language.name}' as input language.`)
  return language
}

export const parseAsrVendor = param => {
  let paramUpper = param?.toUpperCase()
  const validVendors = new Set(['GOOGLE', 'DEEPGRAM', 'SEASALT'])
  if (validVendors.has(paramUpper)) {
    return paramUpper
  } else {
    return
  }
}
