import React, { useEffect } from 'react'

import SessionScoreHeader from './components/SessionScoreHeader'
import ScoreGradient from './components/ScoreGradient'
import { appParamsService } from 'configuration'
import { postEventToParent, WINDOW_MESSAGES } from 'utils'
import Translation from 'components/Translation/Translation'
import { COMMON_POSSIBLE, COMMON_YOU_CAN_CLOSE_THIS_BROWSER } from 'locales/translationIds'

import styles from './EndEventSessionScore.module.css'

const COMPLETED_PERCENTAGE = 80 // Topic with a percentage score greater than this is considered completed

const TopicScore = ({ name, interactions }) => {
  return (
    <div className={styles.topic}>
      <div className={styles.topicName}>{name}</div>
      <div className={styles.topicScoreContainer}>
        <ScoreGradient
          exceeds={interactions.exceeds}
          meets={interactions.meets}
          dontMeet={interactions.dontMeet}
          maxScore={interactions.maxScore}
        />
        <div className={styles.topicPointsContainer}>
          <div>
            <span className={styles.topicScore}>{interactions.score}</span>
            <span className={styles.topicScoreText}> of </span>
            <span className={styles.topicMaxScore}>{interactions.maxScore}</span>
          </div>
          <div className={styles.topicScoreText}>
            <Translation id={COMMON_POSSIBLE} />
          </div>
        </div>
      </div>
    </div>
  )
}

const formatScore = score => (score && Number(score).toFixed(0)) || '0'

const EndEventSessionScore = ({ data }) => {
  useEffect(() => {
    if (data && appParamsService.SCORMWrapper) {
      postEventToParent({
        type: WINDOW_MESSAGES.SCORM_FUNC_CALL,
        func: 'setFinalScore',
        value: formatScore(data.finalScorePercentage)
      })

      const isNotCompleted = !!data.topics.filter(
        ({ interactions }) =>
          (interactions.score / interactions.maxScore) * 100 < COMPLETED_PERCENTAGE
      ).length

      postEventToParent({
        type: WINDOW_MESSAGES.SCORM_FUNC_CALL,
        func: 'setCompletion',
        value: isNotCompleted ? 'incomplete' : 'completed'
      })
    }
  }, [data])

  const renderTopicScore = ({ name, interactions }, index) => (
    <TopicScore key={`${name}-${index}`} name={name} interactions={interactions} />
  )

  return (
    <div className={styles.container}>
      <SessionScoreHeader
        activityName={data.activity.name}
        finalScore={formatScore(data.finalScorePercentage)}
      />
      {data.topics.map(renderTopicScore)}

      {data.finalMessage && (
        <div className={styles.finalMessage}>{data.finalMessage.replaceAll('\\n', ' \n ')}</div>
      )}
      <div className={styles.footerText}>
        <Translation id={COMMON_YOU_CAN_CLOSE_THIS_BROWSER} />
      </div>
    </div>
  )
}

export default EndEventSessionScore
