import { sessionStorageService, SESSION_STORAGE_KEY } from 'utils/BrowserStorageService'
import { postEventToParent, WINDOW_MESSAGES } from 'utils'
import { URL_PARAMS } from 'constants'
import { appParamsService } from 'configuration'

const SEPARATOR = '_' // reserved symbol in the video on_end_event column to separate event name and its params
const SPLIT_END_EVENTS_BY = ';' // reserved symbol by which multiple video events are separated

export const END_EVENT_DIALOGUE_TYPES = {
  endStoryfile: 'endStoryfile',
  showUrl: 'showUrl',
  showText: 'showText'
}

const SHOW_URL_PARAMS_PREFIXES = {
  Message: 'message',
  Button: ['button1', 'button2', 'button3'],
  URL: ['URL1', 'URL2', 'URL3']
}

const VIDEO_END_EVENTS = {
  refresh: refreshToStoryfileIdHandler, // e.g. refresh_16
  endStoryfile: endStoryfileHandler,
  openConvStarters: openConvStartersHandler,
  showURL: showURLHandler,
  showText: showTextHandler
}

const { customEventsOnVideoStart } = appParamsService

export default function handleVideoEndEvent(event, state, onEndEvents) {
  const [eventName, ...rest] = event.split(SEPARATOR)

  let params = rest
  if (eventName !== 'refresh') {
    // all handlers except 'refresh' expect one param, so we join them back to support SEPARATOR in the param string
    params = [rest.join(SEPARATOR)]
  }

  if (customEventsOnVideoStart) {
    // exclude custom events since they are triggered at the start of the video
    // @TODO: Refactor once onVideoStart flag is added to the events json
    const isCustomEvent = !Object.keys(VIDEO_END_EVENTS).includes(eventName)
    if (isCustomEvent) {
      return {}
    }
  }

  console.log(`Video end event ${eventName}`)
  postEventToParent({ type: WINDOW_MESSAGES.VIDEO_END_EVENT, eventName, params })

  const handler = VIDEO_END_EVENTS[eventName]
  if (!handler) {
    return
  }

  return handler(state, onEndEvents, ...params)
}

export function handleVideoStartEvent(event) {
  const eventName = event.split(SEPARATOR)[0]
  const isCustomEvent = !Object.keys(VIDEO_END_EVENTS).includes(eventName)
  if (isCustomEvent) {
    console.log(`Video start event ${eventName}`)
    postEventToParent({ type: WINDOW_MESSAGES.VIDEO_START_EVENT, eventName })
  }
}

export function refreshToStoryfileIdHandler(state, onEndEvents, uid, videoTrigger) {
  sessionStorageService.setItem(SESSION_STORAGE_KEY.REFRESH_EVENT, true)

  if (!uid) {
    window.location.reload()
    return
  }

  const searchParams = new URLSearchParams(window.location.search)

  // In case of multiple ids, we want to preserve them
  // and append the uid to load with a '_' separator
  const uidParam = searchParams.get(URL_PARAMS.uid)
  if (uidParam && uidParam.split(';').length > 1) {
    const uids = uidParam.split('_')[0] // clear previously set '_' uid
    searchParams.set(URL_PARAMS.uid, uids + `_${uid}`)
  } else {
    searchParams.set(URL_PARAMS.uid, uid)
  }
  searchParams.delete(URL_PARAMS.user) // in case app was initially loaded with a 'user' param

  if (videoTrigger) {
    if (!isNaN(videoTrigger)) {
      searchParams.set(URL_PARAMS.intro, 1)
      searchParams.set(URL_PARAMS.introId, videoTrigger)
    } else if (videoTrigger === 'asr') {
      searchParams.set(URL_PARAMS.st, window.btoa(state.lastAnsweredTranscription))
    } else {
      searchParams.set(URL_PARAMS.st, window.btoa(videoTrigger))
    }
  } else {
    searchParams.delete(URL_PARAMS.intro)
    searchParams.delete(URL_PARAMS.introId)
    searchParams.delete(URL_PARAMS.st)
  }

  window.location.search = searchParams.toString()
}

export const willEndEventsTriggerUI = (events = []) => {
  let hasUIEffects = false
  events.forEach(event => {
    const [eventName, ...rest] = event.split(SEPARATOR)
    const handler = VIDEO_END_EVENTS[eventName]
    if (handler) {
      hasUIEffects = true
    }
  })

  return hasUIEffects
}

export const parseOnEndEvents = events => (events ? events.split(SPLIT_END_EVENTS_BY) : [])

function openConvStartersHandler() {
  const newState = { openCSMenu: true }
  return newState
}

function endStoryfileHandler() {
  const newState = { endEventDialogueType: END_EVENT_DIALOGUE_TYPES.endStoryfile }
  return newState
}

function showURLHandler(state, onEndEvents, params) {
  const split = params.split(SEPARATOR)

  const findValueForPrefix = prefix => {
    const index = split.findIndex(str => str === prefix)
    return index === -1 ? null : split[index + 1]
  }

  const message = findValueForPrefix(SHOW_URL_PARAMS_PREFIXES.Message)
  const urlButtons = SHOW_URL_PARAMS_PREFIXES.Button.map((button, i) => {
    const buttonText = findValueForPrefix(button)
    const urlText = findValueForPrefix(SHOW_URL_PARAMS_PREFIXES.URL[i])
    let decodedUrl = urlText

    try {
      decodedUrl = window.atob(urlText)
    } catch (error) {
      // Do nothing for non encoded urls
    }

    return urlText ? { text: buttonText, url: decodedUrl } : null
  }).filter(Boolean)

  const newState = {
    endEventDialogueType: END_EVENT_DIALOGUE_TYPES.showUrl,
    endEventDialogueDescription: message,
    endEventDialogueUrlButtons: urlButtons
  }
  return newState
}

function showTextHandler(state, onEndEvents, text) {
  const newState = {
    endEventDialogueType: END_EVENT_DIALOGUE_TYPES.showText
  }

  if (text) {
    newState.endEventDialogueData = text
  }

  return newState
}
