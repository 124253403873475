import React, { useContext, useMemo } from 'react'
import classNames from 'classnames'

import { StoryFileContext } from 'App'
import { appParamsService, envConfig } from 'configuration'
import ConversationStarters from 'components/ConversationStarters/ConversationStarters'
import SettingsMenu from 'components/SettingsMenu/SettingsMenu'
import KeyboardButton from 'components/KeyboardButton/KeyboardButton'
import MicIndicator from 'components/MicIndicator/MicIndicator'
import BottomDrawer, { DRAWER_ACTIONS } from 'components/BottomDrawer/BottomDrawer'
import { getBottomDrawerConfig } from 'components/BottomDrawer/bottomDrawerConfig'
import FormButton from 'components/FormButton/FormButton'
import { IS_FACEBOOK_ANDROID } from 'utils'

import { refreshToStoryfileIdHandler } from '../../../../util/handleVideoEvent'
import { LayoutCallbackContext } from '../../Layout'
import VideoScreenContext from '../../../../Context'
import { SOURCE_OF_INTERACTION } from '../../../../constants'

import styles from './Footer.module.css'

const XCOPRI_SAFETY_INFO_QUESTION =
  'What warnings, precautions, and risks are associated with XCOPRI?'
const {
  isModeTime,
  isModeDefault,
  isModeSafelite,
  isModeXCOPRI,
  showKeyboardButton,
  showConversationStarters,
  showBottomDrawer,
  expandBottomDrawer
} = appParamsService

const Footer = () => {
  const {
    state: {
      isPlayButtonOverlayVisible,
      isIntroPlaying,
      isRecording,
      isRecordingInitiated,
      isAnswering,
      isWaiting,
      dynamicCS,
      openCSMenu,
      subtitlesLabel,
      showAskForm
    },
    ask,
    handleButtonPress,
    handleButtonRelease,
    handleSubtitlesChange,
    handleVoiceLangChange
  } = useContext(VideoScreenContext)
  const { onCSMenuClosed, onCSClicked, openAskForm } = useContext(LayoutCallbackContext)
  const { userId, subtitlesInfo: subtitles } = useContext(StoryFileContext)

  const bottomDrawerConfig = useMemo(getBottomDrawerConfig, [])

  // TODO: clean up logic here after PLAY_BUTTON_ON_ERROR is closed
  const renderWhenPlayButtonIsVisible =
    envConfig['PLAY_BUTTON_ON_ERROR'] || !isPlayButtonOverlayVisible
  const shouldRenderMic =
    renderWhenPlayButtonIsVisible &&
    !isIntroPlaying &&
    !isModeDefault &&
    !IS_FACEBOOK_ANDROID &&
    !isModeSafelite
  const shouldRenderSettingsMenu =
    !isModeDefault &&
    !isModeSafelite &&
    !isModeTime &&
    renderWhenPlayButtonIsVisible &&
    !isIntroPlaying
  const shouldRenderBottomDrawer = showBottomDrawer && bottomDrawerConfig && !isIntroPlaying
  const shouldRenderXCOPRIButton =
    isModeXCOPRI && !isIntroPlaying && !isRecording && !isAnswering && !showAskForm

  const onQuestionClicked = questionText => {
    if (!isWaiting) {
      return
    }
    ask(questionText, { source: SOURCE_OF_INTERACTION.SANTA_MENU }, false)
  }

  const onStoryFileSelect = uid => {
    refreshToStoryfileIdHandler(undefined, undefined, uid)
  }

  const onURLClick = url => window.open(url)

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerMinHeight]: showConversationStarters
      })}
    >
      {showConversationStarters && renderWhenPlayButtonIsVisible && !isIntroPlaying && (
        <ConversationStarters
          userId={userId}
          dynamicConvStarters={dynamicCS}
          openMenu={openCSMenu}
          onStarterClick={onCSClicked}
          onMenuClose={onCSMenuClosed}
          dropdownContainerClassName={styles.convStartersDropdownContainer}
        />
      )}
      {shouldRenderBottomDrawer && (
        <BottomDrawer
          drawerButtonsConfig={bottomDrawerConfig}
          drawerActions={{
            [DRAWER_ACTIONS.QUESTION]: onQuestionClicked,
            [DRAWER_ACTIONS.STORYFILE]: onStoryFileSelect,
            [DRAWER_ACTIONS.URL]: onURLClick
          }}
          toggleButtonLabel="Links"
          opened={expandBottomDrawer}
        />
      )}
      <div className={styles.rightSection}>
        {shouldRenderXCOPRIButton && (
          <FormButton
            className={styles.xcopriISIButton}
            text="IMPORTANT SAFETY INFORMATION"
            onClick={() => onCSClicked(XCOPRI_SAFETY_INFO_QUESTION)}
          />
        )}
        {shouldRenderSettingsMenu && (
          <SettingsMenu
            className={styles.settingsMenu}
            disableSubtitlesMenu={isAnswering}
            initialSubtitles={subtitlesLabel}
            subtitles={subtitles}
            onSubtitlesChange={handleSubtitlesChange}
            onVoiceLangChange={handleVoiceLangChange}
          />
        )}
        {showKeyboardButton && renderWhenPlayButtonIsVisible && !isIntroPlaying && (
          <KeyboardButton
            onClick={openAskForm}
            containerClassName={styles.keyboardButtonContainer}
          />
        )}
        {shouldRenderMic && (
          <MicIndicator
            isActive={isRecording}
            showActiveCircle={isRecordingInitiated}
            containerClassName={classNames(styles.micIndicatorContainer, {
              [styles.micIndicatorContainerHidden]: isModeTime && isAnswering
            })}
            indicatorClassName={classNames({
              [styles.micIndicator]: isModeTime
            })}
            indicatorCircleClassName={styles.micIndicatorCircle}
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
          />
        )}
      </div>
    </div>
  )
}

export default Footer
