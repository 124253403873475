import { useReducer } from 'react'
import { isSafari } from 'react-device-detect'

import { appParamsService, envConfig } from 'configuration'
import { translate } from 'components/Translation/Translation'
import { COMMON_OFF, COMMON_YOU_HAVE_COMPLETED_SCENARIO } from 'locales/translationIds'
import {
  sessionStorageService,
  localStorageService,
  SESSION_STORAGE_KEY,
  LOCAL_STORAGE_KEY
} from 'utils/BrowserStorageService'
import { parseOnEndEvents } from 'containers/VideoScreen/util/handleVideoEvent'

import {
  getIntroVideoUrlKey,
  shouldShowVoicePlayers,
  shouldRePlayIntroVideo,
  processSubtitles
} from '../util'
import { VIDEO_STATE } from '../constants'

const {
  hasMultipleIds,
  hideWaitingBeforeIntro,
  showWaitingBeforeIntro,
  openAskFormByDefault,
  rcReceiver,
  showInstructions,
  playIntroVideo,
  disablePlayButton,
  defaultSubsLabel,
  unmuteWaitingVideo
} = appParamsService

let staticCannotAnswerVideoUrl = null
let staticCannotAnswerVideoSubtitles = []
let staticCannotAnswerOnEndEvents = []

export const ACTION = {
  INTRO_VIDEO_STARTED: 'INTRO_VIDEO_STARTED',
  RESET: 'RESET',
  END_EVENTS_EXECUTED: 'END_EVENTS_EXECUTED',
  START_ASKING: 'START_ASKING',
  START_ANSWERING: 'START_ANSWERING',
  START_CANNOT_ANSWERING: 'START_CANNOT_ANSWERING',
  START_RECORDING: 'START_RECORDING',
  STOP_RECORDING: 'STOP_RECORDING',
  SET_IDLE: 'SET_IDLE',
  MICROPHONE_ERROR: 'MICROPHONE_ERROR',
  MICROPHONE_PERMISSION_GRANTED: 'MICROPHONE_PERMISSION_GRANTED',
  PLAY_CANNOT_ANSWER: 'PLAY_CANNOT_ANSWER',
  PLAY_ANSWER_VIDEO: 'PLAY_ANSWER_VIDEO',
  PLAY_INTRO_VIDEO: 'PLAY_INTRO_VIDEO',
  PLAY_AFTER_VIDEO: 'PLAY_AFTER_VIDEO',
  PRELOAD_AFTER_VIDEO: 'PRELOAD_AFTER_VIDEO',
  RESET_AFTER_VIDEO: 'RESET_AFTER_VIDEO',
  UPDATE_WAITING_VIDEO_URL: 'UPDATE_WAITING_VIDEO_URL',
  PLAY_SHARED_TRANSCRIPTION: 'PLAY_SHARED_TRANSCRIPTION',
  PLAY_BUTTON_CLICKED: 'PLAY_BUTTON_CLICKED',
  SHOW_ASK_FORM: 'SHOW_ASK_FORM',
  SHOW_PLAY_BUTTON: 'SHOW_PLAY_BUTTON',
  START_BUFFERING: 'START_BUFFERING',
  STOP_BUFFERING: 'STOP_BUFFERING',
  CLOSE_ASK_FORM: 'CLOSE_ASK_FORM',
  CLOSE_CS_MENU: 'CLOSE_CS_MENU',
  SUBTITLES_CHANGED: 'SUBTITLES_CHANGED',
  GET_STARTED_CLICKED: 'GET_STARTED_CLICKED',
  SET_TRANSCRIPTION: 'SET_TRANSCRIPTION',
  RESET_END_DIALOGUE_BOX: 'RESET_END_DIALOGUE_BOX'
}

const initialState = {
  askOnReady: false,
  position: VIDEO_STATE.WAITING,
  transcription: '',
  committedTranscription: '',
  lastAnsweredTranscription: '',
  showAskForm: false,
  canPlayIntro: false,
  answerVideoUrl: null,
  cannotAnswerVideoUrl: null,
  waitingVideoUrl: null,
  nextWaitingVideoUrl: null,
  timeoutVideoUrl: null,
  timeoutDuration: null,
  timeoutVideoSubtitles: null,
  introVideoUrl: null,
  introVideoSubtitles: [],
  cannotAnswerVideoSubtitles: [],
  answerVideoSubtitles: [],
  pauseAnswerVideo: false,
  pauseWaitingVideo: isSafari,
  playAnswerVideo: false,
  playCannotAnswerVideo: false,
  playWaitingVideo: true,
  muteWaitingVideo: true,
  transitionToAnswerPlayer: false,
  transitionToCannotAnswerPlayer: false,
  transitionWithoutAnimation: false,
  showAnswerPlayer: false,
  showCannotAnswerPlayer: false,
  showPlayButtonOverlay: false,
  showInstructionsOverlay: false,
  isIntroPlaying: false,
  isIntroStarted: false,
  onEndEvents: [],
  onEndEventsJson: null,
  googleASRTrainingData: undefined,
  dynamicCS: undefined,
  endEventDialogueType: '',
  endEventDialogueDescription: undefined,
  endEventDialogueUrlButtons: [],
  openCSMenu: false,
  buffering: {
    answer: false,
    waiting: false,
    cannotAnswer: false
  },
  isAutoListening: false,
  isIdle: false
}

const init = config => {
  const sharedTranscription = appParamsService.getSharedTranscription()
  // TODO: we can remove hidePlayButtonAfterRefreshEvent logic after PLAY_BUTTON_ON_ERROR is closed
  // TODO: we can remove disable play button functionality after PLAY_BUTTON_ON_ERROR is closed
  // TODO: we can remove hide waiting before intro functionality after PLAY_BUTTON_ON_ERROR is closed
  // TODO: we can remove initializaiton askOnReady from here - move in VideoScreen directly
  const hidePlayButtonAfterRefreshEvent =
    sessionStorageService.getItem(SESSION_STORAGE_KEY.REFRESH_EVENT) && !isSafari // exclude Safari since play button is required there
  let askOnReady = hidePlayButtonAfterRefreshEvent && sharedTranscription
  sessionStorageService.removeItem(SESSION_STORAGE_KEY.REFRESH_EVENT)

  staticCannotAnswerVideoUrl = config.cannotAnswerVideoUrl
  staticCannotAnswerVideoSubtitles = config.cannotAnswerVideoSubtitles
  staticCannotAnswerOnEndEvents = parseOnEndEvents(config.cannotAnswerVideoOnEndEvents)
  let canPlayIntro =
    config.introVideoUrl &&
    playIntroVideo &&
    !askOnReady &&
    shouldRePlayIntroVideo(config.introVideoUrl)
  let showIntroPlayer =
    (hideWaitingBeforeIntro || hidePlayButtonAfterRefreshEvent || disablePlayButton) && canPlayIntro
  let showPlayButtonOverlay =
    (canPlayIntro || sharedTranscription) && !hidePlayButtonAfterRefreshEvent && !disablePlayButton
  let showAskForm = !canPlayIntro && openAskFormByDefault
  let showVoicePlayers = shouldShowVoicePlayers(showPlayButtonOverlay)
  if (showIntroPlayer) showVoicePlayers.showAnswerPlayer = true

  let isIntroPlaying = showIntroPlayer && (hidePlayButtonAfterRefreshEvent || disablePlayButton)
  const subtitlesLabel =
    defaultSubsLabel ||
    (!rcReceiver && localStorageService.getItem(LOCAL_STORAGE_KEY.SUBTITLES)) ||
    translate(COMMON_OFF)
  let pauseAnswerVideo = showIntroPlayer && !hidePlayButtonAfterRefreshEvent && !disablePlayButton
  let playWaitingVideo = !showIntroPlayer

  // Copy logic until we remove the feature flag. TODO: Clean up after PLAY_BUTTON_ON_ERROR is closed.
  if (envConfig['PLAY_BUTTON_ON_ERROR']) {
    askOnReady = !!sharedTranscription
    canPlayIntro =
      config.introVideoUrl &&
      playIntroVideo &&
      !askOnReady &&
      shouldRePlayIntroVideo(config.introVideoUrl)
    showIntroPlayer = canPlayIntro
    showPlayButtonOverlay = false
    showAskForm = !canPlayIntro && openAskFormByDefault
    // TODO: adjust shouldShowVoicePlayers logic after PLAY_BUTTON_ON_ERROR is closed.
    showVoicePlayers = shouldShowVoicePlayers(showPlayButtonOverlay)
    if (showIntroPlayer) showVoicePlayers.showAnswerPlayer = true
    isIntroPlaying = showIntroPlayer
    pauseAnswerVideo = false
    playWaitingVideo = !showIntroPlayer || showWaitingBeforeIntro

    if (showIntroPlayer && hasMultipleIds) {
      const playedIntroVideos = sessionStorageService.getItem(
        SESSION_STORAGE_KEY.INTRO_VIDEO_PLAYED,
        []
      )
      sessionStorageService.setItem(SESSION_STORAGE_KEY.INTRO_VIDEO_PLAYED, [
        ...playedIntroVideos,
        getIntroVideoUrlKey(config.introVideoUrl)
      ])
    }
  }

  return {
    ...initialState,
    askOnReady,
    canPlayIntro,
    subtitlesLabel,
    showPlayButtonOverlay,
    showAskForm,
    showInstructionsOverlay:
      showInstructions && !sessionStorageService.getItem(SESSION_STORAGE_KEY.INSTRUCTIONS),
    position: isIntroPlaying ? VIDEO_STATE.ANSWERING : VIDEO_STATE.WAITING,
    isIntroPlaying,
    playAnswerVideo: showIntroPlayer,
    transitionToAnswerPlayer: showIntroPlayer,
    pauseAnswerVideo,
    playWaitingVideo,
    answerVideoUrl: showIntroPlayer ? config.introVideoUrl : null,
    answerVideoSubtitles: showIntroPlayer
      ? processSubtitles(config.introVideoSubtitles, subtitlesLabel)
      : [],
    cannotAnswerVideoUrl: config.cannotAnswerVideoUrl,
    waitingVideoUrl: config.waitingVideoUrl,
    timeoutVideoUrl: config.timeoutVideoUrl,
    timeoutDuration: config.timeoutDuration,
    timeoutVideoSubtitles: config.timeoutVideoSubtitles,
    introVideoUrl: config.introVideoUrl,
    introVideoSubtitles: config.introVideoSubtitles,
    cannotAnswerVideoSubtitles: processSubtitles(config.cannotAnswerVideoSubtitles, subtitlesLabel),
    googleASRTrainingData: config.googleASRTrainingData,
    endEventDialogueData: translate(COMMON_YOU_HAVE_COMPLETED_SCENARIO),
    ...showVoicePlayers
  }
}

const reducer = (state, action) => {
  // TODO: remove PLAY_BUTTON_ON_ERROR when is closed
  // if (envConfig['PLAY_BUTTON_ON_ERROR']) console.log(action.type, action, state)
  switch (action.type) {
    case ACTION.START_RECORDING:
      return {
        ...state,
        position: VIDEO_STATE.RECORDING,
        showAnswerPlayer: true,
        showCannotAnswerPlayer: true,
        isAutoListening: action.payload?.isAutoListening || false
      }
    case ACTION.RESET:
      return {
        ...state,
        playWaitingVideo: true,
        playAnswerVideo: false,
        playCannotAnswerVideo: false,
        isIntroPlaying: false,
        isIntroStarted: false,
        position: VIDEO_STATE.WAITING,
        transcription: '',
        committedTranscription: '',
        answerVideoSubtitles: [],
        transitionToCannotAnswerPlayer: false,
        transitionToAnswerPlayer: false,
        transitionWithoutAnimation: false,
        muteWaitingVideo: !unmuteWaitingVideo,
        buffering: {
          answer: false,
          waiting: false,
          cannotAnswer: false
        }
      }
    case ACTION.END_EVENTS_EXECUTED:
      return { ...state, ...action.payload }
    case ACTION.MICROPHONE_ERROR:
      return { ...state, showAskForm: !state.canPlayIntro }
    case ACTION.MICROPHONE_PERMISSION_GRANTED:
      return {
        ...state,
        pauseWaitingVideo: false
      }
    case ACTION.START_ASKING:
      return {
        ...state,
        position: VIDEO_STATE.THINKING,
        transcription: action.payload,
        buffering: { ...state.buffering, answer: true },
        showAnswerPlayer: true,
        showCannotAnswerPlayer: true,
        isAutoListening: false
      }
    case ACTION.PLAY_ANSWER_VIDEO:
      return {
        ...state,
        playAnswerVideo: true,
        playWaitingVideo: false,
        transitionToAnswerPlayer: true
      }
    case ACTION.PLAY_AFTER_VIDEO:
      return {
        ...state,
        showCannotAnswerPlayer: true,
        playCannotAnswerVideo: true,
        playAnswerVideo: false,
        playWaitingVideo: false,
        transitionToCannotAnswerPlayer: true,
        transitionToAnswerPlayer: false,
        transitionWithoutAnimation: true,
        ...action.payload
      }
    case ACTION.PRELOAD_AFTER_VIDEO: {
      staticCannotAnswerVideoUrl = state.cannotAnswerVideoUrl
      staticCannotAnswerVideoSubtitles = state.cannotAnswerVideoSubtitles
      return {
        ...state,
        cannotAnswerVideoUrl: action.payload.afterVideoUrl,
        cannotAnswerVideoSubtitles: action.payload.afterVideoSubtitles
      }
    }
    case ACTION.RESET_AFTER_VIDEO:
      return {
        ...state,
        cannotAnswerVideoUrl: staticCannotAnswerVideoUrl,
        cannotAnswerVideoSubtitles: staticCannotAnswerVideoSubtitles,
        transitionWithoutAnimation: false,
        // Detach video player from DOM to fix the issue:
        // After video is not being played if it has been already played and interrupted
        showCannotAnswerPlayer: false
      }
    case ACTION.STOP_RECORDING:
      return { ...state, position: VIDEO_STATE.WAITING, isAutoListening: false }
    case ACTION.PLAY_CANNOT_ANSWER:
      return {
        ...state,
        showCannotAnswerPlayer: true,
        playCannotAnswerVideo: true,
        playWaitingVideo: false,
        transitionToCannotAnswerPlayer: true
      }
    case ACTION.START_CANNOT_ANSWERING:
      return {
        ...state,
        cannotAnswerVideoUrl: action.payload.cannotAnswerVideoUrl || staticCannotAnswerVideoUrl,
        cannotAnswerVideoSubtitles: processSubtitles(
          action.payload.cannotAnswerVideoSubtitles || staticCannotAnswerVideoSubtitles,
          state.subtitlesLabel
        ),
        onEndEvents: action.payload.onEndEvents.length
          ? action.payload.onEndEvents
          : staticCannotAnswerOnEndEvents,
        playCannotAnswerVideo: true,
        playWaitingVideo: true,
        playAnswerVideo: false,
        position: VIDEO_STATE.ANSWERING,
        committedTranscription: '',
        lastAnsweredTranscription: '',
        buffering: { ...state.buffering, answer: false }
      }
    case ACTION.START_ANSWERING:
      return {
        ...state,
        playAnswerVideo: true,
        playWaitingVideo: true,
        playCannotAnswerVideo: false,
        committedTranscription: '',
        lastAnsweredTranscription: state.transcription,
        position: VIDEO_STATE.ANSWERING,
        ...action.payload
      }
    case ACTION.PLAY_INTRO_VIDEO: {
      // TODO: we don't need this effect anymore clean up after PLAY_BUTTON_ON_ERROR
      const newState = {
        ...state,
        showPlayButtonOverlay: false,
        position: VIDEO_STATE.ANSWERING,
        pauseAnswerVideo: false,
        playWaitingVideo: false,
        playCannotAnswerVideo: false,
        isIntroPlaying: true,
        showAnswerPlayer: true,
        showCannotAnswerPlayer: true
      }

      if (!hideWaitingBeforeIntro) {
        newState.answerVideoUrl = state.introVideoUrl
        newState.answerVideoSubtitles = processSubtitles(
          state.introVideoSubtitles,
          state.subtitlesLabel
        )
        newState.playAnswerVideo = true
        newState.playWaitingVideo = true
        newState.buffering = { ...state.buffering, answer: true }
      }

      if (hasMultipleIds) {
        const playedIntroVideos = sessionStorageService.getItem(
          SESSION_STORAGE_KEY.INTRO_VIDEO_PLAYED,
          []
        )
        sessionStorageService.setItem(SESSION_STORAGE_KEY.INTRO_VIDEO_PLAYED, [
          ...playedIntroVideos,
          getIntroVideoUrlKey(state.introVideoUrl)
        ])
      }
      return newState
    }
    case ACTION.UPDATE_WAITING_VIDEO_URL:
      return {
        ...state,
        waitingVideoUrl: state.nextWaitingVideoUrl
      }
    case ACTION.PLAY_SHARED_TRANSCRIPTION:
      // TODO: we don't need this effect anymore, clean up when closing PLAY_BUTTON_ON_ERROR
      return {
        ...state,
        showPlayButtonOverlay: false,
        showAnswerPlayer: true,
        showCannotAnswerPlayer: true,
        isIntroPlaying: true
      }
    case ACTION.SHOW_ASK_FORM:
      return {
        ...state,
        showAskForm: true
      }
    case ACTION.START_BUFFERING:
      return {
        ...state,
        buffering: { ...state.buffering, [action.payload]: true }
      }
    case ACTION.STOP_BUFFERING:
      return {
        ...state,
        buffering: { ...state.buffering, [action.payload]: false }
      }
    case ACTION.CLOSE_ASK_FORM:
      return {
        ...state,
        showAskForm: false
      }
    case ACTION.CLOSE_CS_MENU:
      return {
        ...state,
        openCSMenu: false
      }
    case ACTION.SUBTITLES_CHANGED:
      return {
        ...state,
        subtitlesLabel: action.payload,
        /* Resetting audible video players in order to invalidate their configs.
           https://github.com/cookpete/react-player/issues/1162 */
        answerVideoUrl: null,
        cannotAnswerVideoUrl: null
      }
    case ACTION.GET_STARTED_CLICKED:
      return {
        ...state,
        showInstructionsOverlay: false
      }
    case ACTION.SET_TRANSCRIPTION:
      return {
        ...state,
        transcription: action.payload
      }
    case ACTION.SET_IDLE:
      return {
        ...state,
        isIdle: action.payload
      }
    case ACTION.RESET_END_DIALOGUE_BOX:
      return {
        ...state,
        endEventDialogueType: '',
        endEventDialogueDescription: undefined,
        endEventDialogueUrlButtons: []
      }
    case ACTION.SHOW_PLAY_BUTTON: {
      const newState = {
        ...state,
        showPlayButtonOverlay: true,
        pauseAnswerVideo: true
      }

      if (showWaitingBeforeIntro && state.isIntroPlaying) {
        newState.playWaitingVideo = true
        newState.playAnswerVideo = false
        newState.isIntroPlaying = true
        newState.position = VIDEO_STATE.WAITING
      }

      if (!state.answerVideoUrl) {
        newState.playCannotAnswerVideo = false
      }
      return newState
    }
    case ACTION.PLAY_BUTTON_CLICKED: {
      const newState = {
        ...state,
        showPlayButtonOverlay: false,
        pauseAnswerVideo: false
      }

      if (showWaitingBeforeIntro && state.isIntroPlaying) {
        newState.position = VIDEO_STATE.ANSWERING
        newState.playWaitingVideo = false
        newState.isIntroPlaying = true
        newState.showAnswerPlayer = true
        newState.showCannotAnswerPlayer = true
        newState.playAnswerVideo = true
        newState.playWaitingVideo = true
        newState.buffering = { ...state.buffering, answer: true }
      }

      if (!state.answerVideoUrl) {
        newState.position = VIDEO_STATE.ANSWERING
        newState.playCannotAnswerVideo = true
        newState.playWaitingVideo = false
        newState.showCannotAnswerPlayer = true
        newState.transitionToCannotAnswerPlayer = true
      }
      return newState
    }
    case ACTION.INTRO_VIDEO_STARTED:
      return {
        ...state,
        isIntroStarted: true
      }
    default:
      throw new Error(`Action is not defined: ${action.type}`)
  }
}

const useStateReducer = SFConfig => useReducer(reducer, SFConfig, init)

export default useStateReducer
