const MessageTypes = {
  ACTIVE_STORYFILE: 'active_storyfile',
  CONNECTION_STATUS: 'connection_status',
  HEARTBEAT: '♥',
  RECEIVER_CONNECTED: 'receiver_connected',
  RESET: 'reset',
  SUBTITLES_CHANGED: 'subtitles_changed',
  TRANSCRIPTION: 'transcription',
  VIDEO_STARTED: 'video_started',
  VIDEO_ENDED: 'video_ended',
  VOICE_LANGUAGE_CHANGED: 'voice_language_changed'
}

export default MessageTypes
