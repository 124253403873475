import React from 'react'
import classNames from 'classnames'

import { appParamsService } from 'configuration'
import Translation, { translate } from 'components/Translation/Translation'
import { COMMON_POWERED_BY } from 'locales/translationIds'

import styles from './Logo.module.css'

const { unclickableLogo, isModeTime, isModeServiceNow } = appParamsService
const shouldRenderLogoImage = !isModeServiceNow

const SF_TEXT = 'CONVERSA'
const companyEl = isModeTime ? <div>{SF_TEXT}</div> : <span>{SF_TEXT}</span>

const Logo = () => (
  <div className={classNames(styles.container, { [styles.linkUnclickable]: unclickableLogo })}>
    <a
      href="https://storyfile.com"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}
      aria-label={`${translate(COMMON_POWERED_BY)} ${SF_TEXT}`}
    >
      <div className={styles.text} aria-hidden="true">
        <Translation id={COMMON_POWERED_BY} /> {companyEl}
      </div>
      {shouldRenderLogoImage && <div className={styles.image} aria-hidden="true" />}
    </a>
  </div>
)

export default Logo
