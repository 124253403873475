import React from 'react'
import crypto from 'crypto'
import { toast } from 'react-toastify'
import { ethers } from 'ethers'

import FormButton from 'components/FormButton/FormButton'
import Translation, { translate } from 'components/Translation/Translation'
import {
  AUTH_METAMASK_PLEASE_LOGIN_WITH_ETHEREUM_WALLET,
  AUTH_METAMASK_CLICK_ON_METAMASK,
  AUTH_METAMASK_LOGIN_THROUGH_METAMASK,
  AUTH_METAMASK_NOT_INSTALLED_ON_BROWSER,
  AUTH_METAMASK_SUCCESSFULLY_CONNECTED,
  AUTH_METAMASK_UNEXPECTED_ERROR
} from 'locales/translationIds'
import metaMaskLogo from 'assets/images/metamask-text-logo.svg'

import styles from './MetaMaskForm.module.css'

const MetaMaskForm = ({ onSubmit, loading, setLoading }) => {
  const onConnectMetamask = async () => {
    if (!window.ethereum) {
      toast.error(translate(AUTH_METAMASK_NOT_INSTALLED_ON_BROWSER))
      return
    }

    setLoading(true)
    const delay = time => new Promise(resolve => setTimeout(resolve, time))

    try {
      if (window.ethereum.networkVersion !== 1) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }]
        })
        await delay(500) // Browser needs some time after the switch
      }

      await window.ethereum.request({ method: 'eth_requestAccounts' })

      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const nonce = crypto.randomBytes(16).toString('base64')
      const message = `Signing nonce: ${nonce}`
      const signature = await signer.signMessage(message)
      const address = await signer.getAddress()

      await onSubmit(signature, message, address)

      toast.success(translate(AUTH_METAMASK_SUCCESSFULLY_CONNECTED))
    } catch (error) {
      toast.error(translate(AUTH_METAMASK_UNEXPECTED_ERROR))
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.metamaskLogoContainer}>
      <div>
        <Translation id={AUTH_METAMASK_PLEASE_LOGIN_WITH_ETHEREUM_WALLET} />
      </div>
      <div>
        <Translation id={AUTH_METAMASK_CLICK_ON_METAMASK} />
      </div>
      <img
        alt="Metamask logo"
        width={200}
        height={200}
        src={metaMaskLogo}
        className={styles.metamaskLogo}
        onClick={onConnectMetamask}
      />
      <FormButton
        className={styles.metaMaskButton}
        text={<Translation id={AUTH_METAMASK_LOGIN_THROUGH_METAMASK} />}
        onClick={onConnectMetamask}
        loading={loading}
      />
    </div>
  )
}

export default MetaMaskForm
