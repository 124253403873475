import React from 'react'

const contextValue = {
  state: {
    isPlayButtonOverlayVisible: false,
    isInstructionsVisible: false,
    isEndEventModalVisible: false,
    isIntroPlaying: false,
    isIntroStarted: false,
    isRecording: false,
    isAnswering: false,
    isWaiting: false,
    lastAnsweredTranscription: '',
    dynamicCS: undefined,
    openCSMenu: false,
    showAskForm: false,
    isAutoListening: false
  },
  dispatch: () => {},
  ask: () => {},
  reset: () => {},
  handleButtonPress: () => {},
  handleButtonRelease: () => {},
  handleBackButtonPress: () => {},
  handleSubtitlesChange: () => {},
  handleVoiceLangChange: () => {}
}

const VideoScreenContext = React.createContext(contextValue)

export default VideoScreenContext
