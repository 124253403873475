import React, { useContext } from 'react'

import { appParamsService } from 'configuration'
import AskForm from 'components/AskForm/AskForm'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import { LayoutCallbackContext } from '../Layout'
import SkipIntroButton from '../SkipIntroButton/SkipIntroButton'
import VideoScreenContext from '../../../Context'

import styles from './DefaultLayout.module.css'

const { isModeWeb, isModeMobile, isModeUnbranded, isModeHealthySoil, isModeXCOPRI } =
  appParamsService

const DefaultLayout = () => {
  const {
    state: { isIntroStarted, isPlayButtonOverlayVisible, showAskForm }
  } = useContext(VideoScreenContext)
  const {
    renderAskForm,
    closeAskForm,
    onAskFormSubmitted,
    onAskFormSpaceBarPressed,
    onSkipButtonClicked
  } = useContext(LayoutCallbackContext)

  const shouldRenderSkipButton =
    isIntroStarted &&
    !isPlayButtonOverlayVisible &&
    (isModeWeb || isModeMobile || isModeUnbranded || isModeHealthySoil || isModeXCOPRI)

  return (
    <div className={styles.container}>
      <Header />
      {renderAskForm && (
        <AskForm
          show={showAskForm}
          onClose={closeAskForm}
          onSubmit={onAskFormSubmitted}
          onSpaceBarPress={onAskFormSpaceBarPressed}
        />
      )}
      {shouldRenderSkipButton && <SkipIntroButton onClick={onSkipButtonClicked} />}
      <Footer />
    </div>
  )
}

export default DefaultLayout
