import React from 'react'
import classNames from 'classnames'

import Translation from 'components/Translation/Translation'
import { COMMON_LOADING_APP } from 'locales/translationIds'

import styles from './LoadingLogo.module.css'

export default function LoadingLogo() {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.spinner, styles.spinnerAnimation)}>
        <div className={styles.loadingLogo} />
      </div>
      <div>
        <Translation id={COMMON_LOADING_APP} />
      </div>
    </div>
  )
}
