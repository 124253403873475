import React from 'react'

import { appParamsService } from 'configuration'
import Translation from 'components/Translation/Translation'
import FullScreenButton from 'components/FullScreenButton/FullScreenButton'
import { COMMON_RECEIVER_STATUS } from 'locales/translationIds'
import usePeerConnectionStatus from 'hooks/usePeerConnectionStatus'

import Logo from '../Logo/Logo'
import styles from './ReceiverLayout.module.css'

const { rcWired, rcReceiver, enableFullScreenButton } = appParamsService

const ReceiverLayout = () => {
  const status = usePeerConnectionStatus()

  let shouldShowReceiverDisconnect
  if (rcWired) {
    shouldShowReceiverDisconnect = status === 'disconnected'
  } else {
    shouldShowReceiverDisconnect = rcReceiver && status?.state !== 1
  }

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        {enableFullScreenButton && <FullScreenButton className={styles.fullScreenButton} />}
        <Logo />
      </div>
      {shouldShowReceiverDisconnect && (
        <div className={styles.receiverStatus}>
          <Translation id={COMMON_RECEIVER_STATUS} /> {status?.label || status}
        </div>
      )}
      <div />
    </div>
  )
}

export default ReceiverLayout
