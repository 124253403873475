import React, { useState } from 'react'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

import { appParamsService } from 'configuration'

import styles from './BottomDrawer.module.css'

const { storyFileIds } = appParamsService

export const DRAWER_ACTIONS = {
  QUESTION: 'question',
  URL: 'url',
  STORYFILE: 'storyfile'
}

const BottomDrawer = ({
  drawerButtonsConfig,
  drawerActions,
  onMenuKeyboardClick,
  onToggleDrawerClick = () => {},
  toggleButtonLabel = '',
  drawerClassName = '',
  opened = false
}) => {
  const [visible, setVisibility] = useState(opened)

  const shouldHideCurrentSf = drawerButtonsConfig.hideCurrentSfFromButtons

  const toggleDrawer = () => {
    setVisibility(!visible)
    onToggleDrawerClick(!visible)
  }

  const showTooltip = tooltipIndex => {
    document.getElementById(`tooltip-${tooltipIndex}`)?.classList.add(styles.tooltipVisible)
  }

  const hideTooltip = tooltipIndex => {
    document.getElementById(`tooltip-${tooltipIndex}`)?.classList.remove(styles.tooltipVisible)
  }

  const onButtonClick = (buttonActionType, buttonActionValue) => {
    drawerActions[buttonActionType](buttonActionValue)

    if (buttonActionType === DRAWER_ACTIONS.QUESTION) {
      setVisibility(false)
    }
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerHidden]: !visible,
        [styles.containerVisible]: visible
      })}
    >
      <button
        style={{ backgroundImage: `url('${drawerButtonsConfig.toggleImage}')` }}
        className={classNames(styles.toggleButton, {
          [styles.toggleButtonHidden]: !visible,
          [styles.toggleButtonVisible]: visible
        })}
        onClick={toggleDrawer}
      >
        <div
          className={classNames(styles.toggleLabel, {
            [styles.toggleLabelHidden]: visible,
            [styles.toggleLabelVisible]: !visible
          })}
        >
          {toggleButtonLabel}
        </div>
      </button>
      <div className={classNames(styles.drawer, drawerClassName)}>
        {drawerButtonsConfig.buttons.map((btn, i) => {
          if (
            shouldHideCurrentSf &&
            DRAWER_ACTIONS.STORYFILE === btn.action.type &&
            btn.action.value === storyFileIds[0]
          ) {
            return null
          }

          return (
            <div
              key={`${btn.image}-${i}`}
              className={styles.drawerButtonContainer}
              onMouseEnter={!isMobile ? () => showTooltip(i) : null}
              onMouseLeave={!isMobile ? () => hideTooltip(i) : null}
              onContextMenu={e => e.preventDefault()}
            >
              <button
                style={{ backgroundImage: `url('${btn.image}')` }}
                className={styles.drawerButton}
                onClick={() => onButtonClick(btn.action.type, btn.action.value)}
              />
              {btn.tooltip && (
                <div id={`tooltip-${i}`} className={classNames(styles.tooltip)} role="tooltip">
                  {btn.tooltip}
                </div>
              )}
            </div>
          )
        })}
        {onMenuKeyboardClick && (
          <div className={styles.drawerButtonContainer}>
            <button className={styles.drawerButtonKeyboard} onClick={onMenuKeyboardClick} />
          </div>
        )}
      </div>
    </div>
  )
}

export default BottomDrawer
