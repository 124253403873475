import React from 'react'
import classNames from 'classnames'

import FormButton, { STYLING_TYPES } from 'components/FormButton/FormButton'
import Translation from 'components/Translation/Translation'
import { COMMON_OPEN_LINK_BELOW_IN_NEW_TAB, COMMON_YES, COMMON_CLOSE } from 'locales/translationIds'
import { appParamsService } from 'configuration'
import Svg, { close } from 'components/shared/Svg'

import styles from './EndEventMessage.module.css'

const { isModeSafelite } = appParamsService

const EndEventMessage = ({
  data,
  isEventShowURL,
  isEventEndStoryfile,
  resetDialogueBox,
  urlButtons,
  description
}) => {
  const shouldShowCloseButton = urlButtons.length <= 2

  return (
    <div className={classNames(styles.container, styles.messageContainer)}>
      {!isModeSafelite && <div className={styles.header}>Message</div>}
      {!isEventEndStoryfile && (
        <button
          onClick={resetDialogueBox}
          className={classNames(styles.closeButton, {
            [styles.closeButtonColorWhite]: !isModeSafelite
          })}
        >
          <Svg path={close} width={20} height={20} />
        </button>
      )}
      <div className={styles.textContainer}>
        {isEventShowURL
          ? description || <Translation id={COMMON_OPEN_LINK_BELOW_IN_NEW_TAB} />
          : data}
      </div>
      <div className={styles.buttonsContainer}>
        {isEventShowURL && (
          <>
            {shouldShowCloseButton ? (
              <FormButton
                className={styles.button}
                text={<Translation id={COMMON_CLOSE} />}
                onClick={resetDialogueBox}
                styling={STYLING_TYPES.default}
              />
            ) : null}
            {urlButtons.map((button, i) => (
              <FormButton
                key={`${i}-${button.text}`}
                className={styles.button}
                text={button.text || <Translation id={COMMON_YES} />}
                onClick={() => window.open(button.url, '_blank')}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default EndEventMessage
