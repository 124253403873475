import { appParamsService } from 'configuration'

export const LOG_PREFIX = '[Debug mode]:'

export const debugModeLogger = appParamsService.debug
  ? console.log.bind(console, LOG_PREFIX)
  : () => {}

export const debugModeTime = appParamsService.debug ? console.time : () => {}

export const debugModeTimeEnd = appParamsService.debug ? console.timeEnd : () => {}
