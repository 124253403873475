import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { appParamsService } from 'configuration'

import styles from './Modal.module.css'

const { isModeSafelite } = appParamsService

const Modal = ({ isOpen, onClose = () => {}, closeOnOverlayClick = true, children, className }) => {
  const [show, setShow] = useState(isOpen)

  const onModalClick = event => {
    if (closeOnOverlayClick && event.target === event.currentTarget) {
      setShow(false)
      onClose()
    }
  }

  useEffect(() => {
    setShow(isOpen)
  }, [isOpen])

  return (
    <div
      onClick={onModalClick}
      className={classNames(styles.overlay, className, {
        [styles.overlaySafeliteEF]: isModeSafelite,
        [styles.overlayVisible]: show
      })}
    >
      {show && children}
    </div>
  )
}

export default Modal
