import React, { useEffect } from 'react'
import classNames from 'classnames'
import { isMobile, isIOS } from 'react-device-detect'

import { postEventToParent, WINDOW_MESSAGES } from 'utils'
import { appParamsService } from 'configuration'

import MicIndicatorIcon from './MicIndicatorIcon/MicIndicatorIcon'
import MicIndicatorCircle from './MicIndicatorCircle/MicIndicatorCircle'

import styles from './MicIndicator.module.css'

const { isModeTime } = appParamsService

const MicIndicator = ({
  isActive,
  showActiveCircle,
  containerClassName,
  indicatorClassName,
  indicatorActiveClassName,
  indicatorIconClassName,
  indicatorCircleClassName,
  indicatorTextContainerClassName,
  indicatorTextClassName,
  innerDotClassName,
  middleDotClassName,
  outerDotClassName,
  onTouchStart = () => {},
  onTouchEnd = () => {},
  onMouseDown = () => {},
  onMouseUp = () => {}
}) => {
  useEffect(() => {
    postEventToParent(WINDOW_MESSAGES.MIC_VISIBLE)

    // Send a 'mic_hidden' event for cases when iframe reloads and parent page doesn't (i.e. for goodbye videos)
    // For reference of implementation https://stackoverflow.com/a/39085062/11236523
    const onUnmount = () => {
      postEventToParent(WINDOW_MESSAGES.MIC_HIDDEN)
    }

    window.addEventListener('beforeunload', onUnmount)

    return () => {
      onUnmount()
      window.removeEventListener('beforeunload', onUnmount)
    }
  }, [])

  const onContextMenu = e => e.preventDefault()

  const handleOnMouseDown = () => {
    if (isMobile && isIOS) return
    onMouseDown()
  }

  const handleOnMouseUp = () => {
    if (isMobile && isIOS) return
    onMouseUp()
  }

  return (
    <div
      id="hold-to-talk-btn"
      className={classNames(styles.container, containerClassName)}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onContextMenu={onContextMenu}
    >
      <button
        className={classNames(styles.indicator, indicatorClassName, {
          [indicatorActiveClassName]: isActive
        })}
      >
        {!isModeTime && (
          <MicIndicatorIcon
            className={indicatorIconClassName}
            isActive={isActive}
            innerDotClassName={innerDotClassName}
            middleDotClassName={middleDotClassName}
            outerDotClassName={outerDotClassName}
            textContainerClassName={indicatorTextContainerClassName}
            textClassName={indicatorTextClassName}
          />
        )}
      </button>
      <MicIndicatorCircle
        showActiveCircle={showActiveCircle}
        indicatorCircleClassName={indicatorCircleClassName}
      />
    </div>
  )
}

export default MicIndicator
