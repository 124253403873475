import axios from 'axios'

import { appParamsService } from 'configuration'

export const CHECKPOINT_EVENT = {
  ANSWER_START: 'ANSWER:START',
  ANSWER_END: 'ANSWER:END',
  ANSWER_PROGRESS: 'ANSWER:PROGRESS',
  WAITING_START: 'WAITING_POSE:START',
  CANNOT_ANSWER_START: 'CANNOT_ANSWER:START',
  CANNOT_ANSWER_PROGRESS: 'CANNOT_ANSWER:PROGRESS'
}

const BASE_LOCAL_CHECKPOINT_URL = 'http://localhost:8003/'
const axiosInstances = axios.create({
  baseURL: BASE_LOCAL_CHECKPOINT_URL,
  timeout: 1000 * 30
})

class CheckpointService {
  async publish(payload) {
    if (appParamsService.enabledCheckpoints()) {
      let body = { ...payload, userSession: appParamsService.getSessionId() }
      axiosInstances.post('/checkpoint', body).catch(error => {
        console.error(`Could not send checkpoint type=${body.event}`)
      })
    }
  }
}

export const checkpointService = new CheckpointService()
