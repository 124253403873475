import React, { useEffect, Suspense } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'

import VideoScreen from 'containers/VideoScreen/VideoScreen'
import AuthScreen from 'containers/AuthScreen/AuthScreen'
import SelectScreen from 'containers/SelectScreen/SelectScreen'
import ReceiverScreen from 'containers/ReceiverScreen/ReceiverScreen'
import Translation from 'components/Translation/Translation'
import LoadingLogo from 'components/LoadingLogo/LoadingLogo'
import { appParamsService } from 'configuration'
import { AUTH_COMMON_AUTH_COMPLETED } from 'locales/translationIds'
import { useSession } from 'useSession'
import { formatStoryFileConfig, reloadWithMode } from 'utils'

import './styles/components.css'
import { remoteControlReceiver, remoteControlTransmitter } from 'remote-control'
import 'react-toastify/dist/ReactToastify.css'
import 'react-simple-keyboard/build/css/index.css'

const { isModeDefault, isModeMobile, isModeWeb } = appParamsService
const inAllowedMode = isModeDefault || isModeMobile || isModeWeb

// @TODO: Refactor App.js and code split all different screens
const TransmitterScreen = React.lazy(() => import('containers/TransmitterScreen/TransmitterScreen'))

export const StoryFileContext = React.createContext({
  configLoaded: false,
  cannotAnswerVideoUrl: undefined,
  waitingVideoUrl: undefined,
  introVideoUrl: undefined,
  quality: undefined,
  username: undefined,
  userId: undefined,
  googleASRTrainingData: undefined,
  homePageContent: undefined,
  subtitlesInfo: undefined,
  thumbnail: undefined,
  avatar: undefined,
  bio: undefined
})

const App = () => {
  const [
    { authorized, doesStoryFileExist, isStoryFilePrivate, ssoLoading },
    configs,
    startPublicSession,
    startSSOLoading,
    startPollingAuthStoryFile
  ] = useSession()

  useEffect(() => {
    // Using onbeforeunload, since useEffect's return did not fire in all cases
    window.onbeforeunload = () => {
      remoteControlReceiver?.destroyConnection()
      remoteControlTransmitter?.destroyConnection()
    }
  }, [])

  const onLoginSuccess = () => {
    startPublicSession()
  }

  const renderVideoScreen = (config, videoScreenProps = {}) => (
    <StoryFileContext.Provider value={{ ...config }}>
      <VideoScreen {...videoScreenProps} />
    </StoryFileContext.Provider>
  )

  if (authorized === null) {
    return <LoadingLogo />
  }

  // @TODO: Temp screen, improve
  if (appParamsService.isWindowALoginPopUp) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Translation id={AUTH_COMMON_AUTH_COMPLETED} />
      </div>
    )
  }

  if (authorized && doesStoryFileExist) {
    if (appParamsService.rcTransmitter) {
      return (
        <Suspense fallback={<LoadingLogo />}>
          <TransmitterScreen transmitId={appParamsService.rcTransmitter} storyFiles={configs} />
        </Suspense>
      )
    }

    const isModeAllowed =
      inAllowedMode || configs.every(sf => sf.skins.includes(appParamsService.appMode))

    if (!isModeAllowed) {
      reloadWithMode()
      return
    }

    if (configs.length > 1) {
      return <SelectScreen storyFiles={configs}>{renderVideoScreen}</SelectScreen>
    }

    return renderVideoScreen(formatStoryFileConfig(configs[0]))
  }

  if (appParamsService.rcReceiver) {
    return <ReceiverScreen />
  }

  return (
    <>
      <AuthScreen
        onLoginSuccess={onLoginSuccess}
        isSFPrivate={isStoryFilePrivate}
        doesSFExist={doesStoryFileExist}
        ssoLoading={ssoLoading}
        startSSOLoading={startSSOLoading}
        startPollingAuthStoryFile={startPollingAuthStoryFile}
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default Sentry.withProfiler(App, { name: 'Exhibit App' })
