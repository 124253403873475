const languageIsoCodes = {
  ab: {
    isoName: 'Abkhazian',
    nativeName: 'аҧсуа бызшәа, аҧсшәа'
  },
  aa: {
    isoName: 'Afar',
    nativeName: 'Afaraf'
  },
  af: {
    isoName: 'Afrikaans',
    nativeName: 'Afrikaans'
  },
  ak: {
    isoName: 'Akan',
    nativeName: 'Akan'
  },
  sq: {
    isoName: 'Albanian',
    nativeName: 'Shqip'
  },
  am: {
    isoName: 'Amharic',
    nativeName: 'አማርኛ'
  },
  ar: {
    isoName: 'Arabic',
    nativeName: 'العربية'
  },
  an: {
    isoName: 'Aragonese',
    nativeName: 'aragonés'
  },
  hy: {
    isoName: 'Armenian',
    nativeName: 'Հայերեն'
  },
  as: {
    isoName: 'Assamese',
    nativeName: 'অসমীয়া'
  },
  av: {
    isoName: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ'
  },
  ae: {
    isoName: 'Avestan',
    nativeName: 'avesta'
  },
  ay: {
    isoName: 'Aymara',
    nativeName: 'aymar aru'
  },
  az: {
    isoName: 'Azerbaijani',
    nativeName: 'azərbaycan dili, تۆرکجه'
  },
  bm: {
    isoName: 'Bambara',
    nativeName: 'bamanankan'
  },
  ba: {
    isoName: 'Bashkir',
    nativeName: 'башҡорт теле'
  },
  eu: {
    isoName: 'Basque',
    nativeName: 'euskara, euskera'
  },
  be: {
    isoName: 'Belarusian',
    nativeName: 'беларуская мова'
  },
  bn: {
    isoName: 'Bengali',
    nativeName: 'বাংলা'
  },
  bi: {
    isoName: 'Bislama',
    nativeName: 'Bislama'
  },
  bs: {
    isoName: 'Bosnian',
    nativeName: 'bosanski jezik'
  },
  br: {
    isoName: 'Breton',
    nativeName: 'brezhoneg'
  },
  bg: {
    isoName: 'Bulgarian',
    nativeName: 'български език'
  },
  my: {
    isoName: 'Burmese',
    nativeName: 'ဗမာစာ'
  },
  ca: {
    isoName: 'Catalan, Valencian',
    nativeName: 'català, valencià'
  },
  ch: {
    isoName: 'Chamorro',
    nativeName: 'Chamoru'
  },
  ce: {
    isoName: 'Chechen',
    nativeName: 'нохчийн мотт'
  },
  ny: {
    isoName: 'Chichewa, Chewa, Nyanja',
    nativeName: 'chiCheŵa, chinyanja'
  },
  zh: {
    isoName: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語'
  },
  cv: {
    isoName: 'Chuvash',
    nativeName: 'чӑваш чӗлхи'
  },
  kw: {
    isoName: 'Cornish',
    nativeName: 'Kernewek'
  },
  co: {
    isoName: 'Corsican',
    nativeName: 'corsu, lingua corsa'
  },
  cr: {
    isoName: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ'
  },
  hr: {
    isoName: 'Croatian',
    nativeName: 'hrvatski jezik'
  },
  cs: {
    isoName: 'Czech',
    nativeName: 'čeština, český jazyk'
  },
  da: {
    isoName: 'Danish',
    nativeName: 'dansk'
  },
  dv: {
    isoName: 'Divehi, Dhivehi, Maldivian',
    nativeName: 'ދިވެހި'
  },
  nl: {
    isoName: 'Dutch, Flemish',
    nativeName: 'Nederlands, Vlaams'
  },
  dz: {
    isoName: 'Dzongkha',
    nativeName: 'རྫོང་ཁ'
  },
  en: {
    isoName: 'English',
    nativeName: 'English'
  },
  eo: {
    isoName: 'Esperanto',
    nativeName: 'Esperanto'
  },
  et: {
    isoName: 'Estonian',
    nativeName: 'eesti, eesti keel'
  },
  ee: {
    isoName: 'Ewe',
    nativeName: 'Eʋegbe'
  },
  fo: {
    isoName: 'Faroese',
    nativeName: 'føroyskt'
  },
  fj: {
    isoName: 'Fijian',
    nativeName: 'vosa Vakaviti'
  },
  fi: {
    isoName: 'Finnish',
    nativeName: 'suomi, suomen kieli'
  },
  fr: {
    isoName: 'French',
    nativeName: 'français, langue française'
  },
  ff: {
    isoName: 'Fulah',
    nativeName: 'Fulfulde, Pulaar, Pular'
  },
  gl: {
    isoName: 'Galician',
    nativeName: 'Galego'
  },
  ka: {
    isoName: 'Georgian',
    nativeName: 'ქართული'
  },
  de: {
    isoName: 'German',
    nativeName: 'Deutsch'
  },
  el: {
    isoName: 'Greek, Modern (1453–)',
    nativeName: 'ελληνικά'
  },
  gn: {
    isoName: 'Guarani',
    nativeName: "Avañe'ẽ"
  },
  gu: {
    isoName: 'Gujarati',
    nativeName: 'ગુજરાતી'
  },
  ht: {
    isoName: 'Haitian, Haitian Creole',
    nativeName: 'Kreyòl ayisyen'
  },
  ha: {
    isoName: 'Hausa',
    nativeName: '(Hausa) هَوُسَ'
  },
  he: {
    isoName: 'Hebrew',
    nativeName: 'עברית'
  },
  hz: {
    isoName: 'Herero',
    nativeName: 'Otjiherero'
  },
  hi: {
    isoName: 'Hindi',
    nativeName: 'हिन्दी, हिंदी'
  },
  ho: {
    isoName: 'Hiri Motu',
    nativeName: 'Hiri Motu'
  },
  hu: {
    isoName: 'Hungarian',
    nativeName: 'magyar'
  },
  ia: {
    isoName: 'Interlingua (International Auxiliary Language Association)',
    nativeName: 'Interlingua'
  },
  id: {
    isoName: 'Indonesian',
    nativeName: 'Bahasa Indonesia'
  },
  ie: {
    isoName: 'Interlingue, Occidental',
    nativeName: '(originally:) Occidental, (after WWII:) Interlingue'
  },
  ga: {
    isoName: 'Irish',
    nativeName: 'Gaeilge'
  },
  ig: {
    isoName: 'Igbo',
    nativeName: 'Asụsụ Igbo'
  },
  ik: {
    isoName: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun'
  },
  io: {
    isoName: 'Ido',
    nativeName: 'Ido'
  },
  is: {
    isoName: 'Icelandic',
    nativeName: 'Íslenska'
  },
  it: {
    isoName: 'Italian',
    nativeName: 'Italiano'
  },
  iu: {
    isoName: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ'
  },
  ja: {
    isoName: 'Japanese',
    nativeName: '日本語 (にほんご)'
  },
  jv: {
    isoName: 'Javanese',
    nativeName: 'ꦧꦱꦗꦮ, Basa Jawa'
  },
  kl: {
    isoName: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii'
  },
  kn: {
    isoName: 'Kannada',
    nativeName: 'ಕನ್ನಡ'
  },
  kr: {
    isoName: 'Kanuri',
    nativeName: 'Kanuri'
  },
  ks: {
    isoName: 'Kashmiri',
    nativeName: 'कश्मीरी, كشميري‎'
  },
  kk: {
    isoName: 'Kazakh',
    nativeName: 'қазақ тілі'
  },
  km: {
    isoName: 'Central Khmer',
    nativeName: 'ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ'
  },
  ki: {
    isoName: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ'
  },
  rw: {
    isoName: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda'
  },
  ky: {
    isoName: 'Kirghiz, Kyrgyz',
    nativeName: 'Кыргызча, Кыргыз тили'
  },
  kv: {
    isoName: 'Komi',
    nativeName: 'коми кыв'
  },
  kg: {
    isoName: 'Kongo',
    nativeName: 'Kikongo'
  },
  ko: {
    isoName: 'Korean',
    nativeName: '한국어'
  },
  ku: {
    isoName: 'Kurdish',
    nativeName: 'Kurdî, کوردی‎'
  },
  kj: {
    isoName: 'Kuanyama, Kwanyama',
    nativeName: 'Kuanyama'
  },
  la: {
    isoName: 'Latin',
    nativeName: 'latine, lingua latina'
  },
  lb: {
    isoName: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch'
  },
  lg: {
    isoName: 'Ganda',
    nativeName: 'Luganda'
  },
  li: {
    isoName: 'Limburgan, Limburger, Limburgish',
    nativeName: 'Limburgs'
  },
  ln: {
    isoName: 'Lingala',
    nativeName: 'Lingála'
  },
  lo: {
    isoName: 'Lao',
    nativeName: 'ພາສາລາວ'
  },
  lt: {
    isoName: 'Lithuanian',
    nativeName: 'lietuvių kalba'
  },
  lu: {
    isoName: 'Luba-Katanga',
    nativeName: 'Kiluba'
  },
  lv: {
    isoName: 'Latvian',
    nativeName: 'latviešu valoda'
  },
  gv: {
    isoName: 'Manx',
    nativeName: 'Gaelg, Gailck'
  },
  mk: {
    isoName: 'Macedonian',
    nativeName: 'македонски јазик'
  },
  mg: {
    isoName: 'Malagasy',
    nativeName: 'fiteny malagasy'
  },
  ms: {
    isoName: 'Malay',
    nativeName: 'Bahasa Melayu, بهاس ملايو‎'
  },
  ml: {
    isoName: 'Malayalam',
    nativeName: 'മലയാളം'
  },
  mt: {
    isoName: 'Maltese',
    nativeName: 'Malti'
  },
  mi: {
    isoName: 'Maori',
    nativeName: 'te reo Māori'
  },
  mr: {
    isoName: 'Marathi',
    nativeName: 'मराठी'
  },
  mh: {
    isoName: 'Marshallese',
    nativeName: 'Kajin M̧ajeļ'
  },
  mn: {
    isoName: 'Mongolian',
    nativeName: 'Монгол хэл'
  },
  na: {
    isoName: 'Nauru',
    nativeName: 'Dorerin Naoero'
  },
  nv: {
    isoName: 'Navajo, Navaho',
    nativeName: 'Diné bizaad'
  },
  nd: {
    isoName: 'North Ndebele',
    nativeName: 'isiNdebele'
  },
  ne: {
    isoName: 'Nepali',
    nativeName: 'नेपाली'
  },
  ng: {
    isoName: 'Ndonga',
    nativeName: 'Owambo'
  },
  nb: {
    isoName: 'Norwegian Bokmål',
    nativeName: 'Norsk Bokmål'
  },
  nn: {
    isoName: 'Norwegian Nynorsk',
    nativeName: 'Norsk Nynorsk'
  },
  no: {
    isoName: 'Norwegian',
    nativeName: 'Norsk'
  },
  ii: {
    isoName: 'Sichuan Yi, Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop'
  },
  nr: {
    isoName: 'South Ndebele',
    nativeName: 'isiNdebele'
  },
  oc: {
    isoName: 'Occitan',
    nativeName: "occitan, lenga d'òc"
  },
  oj: {
    isoName: 'Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
  },
  cu: {
    isoName: 'Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ'
  },
  om: {
    isoName: 'Oromo',
    nativeName: 'Afaan Oromoo'
  },
  or: {
    isoName: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ'
  },
  os: {
    isoName: 'Ossetian, Ossetic',
    nativeName: 'ирон æвзаг'
  },
  pa: {
    isoName: 'Punjabi, Panjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎'
  },
  pi: {
    isoName: 'Pali',
    nativeName: 'पालि, पाळि'
  },
  fa: {
    isoName: 'Persian',
    nativeName: 'فارسی'
  },
  pl: {
    isoName: 'Polish',
    nativeName: 'język polski, polszczyzna'
  },
  ps: {
    isoName: 'Pashto, Pushto',
    nativeName: 'پښتو'
  },
  pt: {
    isoName: 'Portuguese',
    nativeName: 'Português'
  },
  qu: {
    isoName: 'Quechua',
    nativeName: 'Runa Simi, Kichwa'
  },
  rm: {
    isoName: 'Romansh',
    nativeName: 'Rumantsch Grischun'
  },
  rn: {
    isoName: 'Rundi',
    nativeName: 'Ikirundi'
  },
  ro: {
    isoName: 'Romanian, Moldavian, Moldovan',
    nativeName: 'Română, Moldovenească'
  },
  ru: {
    isoName: 'Russian',
    nativeName: 'русский'
  },
  sa: {
    isoName: 'Sanskrit',
    nativeName: 'संस्कृतम्, 𑌸𑌂𑌸𑍍𑌕𑍃𑌤𑌮𑍍'
  },
  sc: {
    isoName: 'Sardinian',
    nativeName: 'sardu'
  },
  sd: {
    isoName: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎'
  },
  se: {
    isoName: 'Northern Sami',
    nativeName: 'Davvisámegiella'
  },
  sm: {
    isoName: 'Samoan',
    nativeName: "gagana fa'a Samoa"
  },
  sg: {
    isoName: 'Sango',
    nativeName: 'yângâ tî sängö'
  },
  sr: {
    isoName: 'Serbian',
    nativeName: 'српски језик'
  },
  gd: {
    isoName: 'Gaelic, Scottish Gaelic',
    nativeName: 'Gàidhlig'
  },
  sn: {
    isoName: 'Shona',
    nativeName: 'chiShona'
  },
  si: {
    isoName: 'Sinhala, Sinhalese',
    nativeName: 'සිංහල'
  },
  sk: {
    isoName: 'Slovak',
    nativeName: 'Slovenčina, Slovenský jazyk'
  },
  sl: {
    isoName: 'Slovenian',
    nativeName: 'Slovenski jezik, Slovenščina'
  },
  so: {
    isoName: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali'
  },
  st: {
    isoName: 'Southern Sotho',
    nativeName: 'Sesotho'
  },
  es: {
    isoName: 'Spanish, Castilian',
    nativeName: 'Español'
  },
  su: {
    isoName: 'Sundanese',
    nativeName: 'Basa Sunda'
  },
  sw: {
    isoName: 'Swahili',
    nativeName: 'Kiswahili'
  },
  ss: {
    isoName: 'Swati',
    nativeName: 'SiSwati'
  },
  sv: {
    isoName: 'Swedish',
    nativeName: 'Svenska'
  },
  ta: {
    isoName: 'Tamil',
    nativeName: 'தமிழ்'
  },
  te: {
    isoName: 'Telugu',
    nativeName: 'తెలుగు'
  },
  tg: {
    isoName: 'Tajik',
    nativeName: 'тоҷикӣ, toçikī, تاجیکی‎'
  },
  th: {
    isoName: 'Thai',
    nativeName: 'ไทย'
  },
  ti: {
    isoName: 'Tigrinya',
    nativeName: 'ትግርኛ'
  },
  bo: {
    isoName: 'Tibetan',
    nativeName: 'བོད་ཡིག'
  },
  tk: {
    isoName: 'Turkmen',
    nativeName: 'Türkmen, Түркмен'
  },
  tl: {
    isoName: 'Tagalog',
    nativeName: 'Wikang Tagalog'
  },
  tn: {
    isoName: 'Tswana',
    nativeName: 'Setswana'
  },
  to: {
    isoName: 'Tonga (Tonga Islands)',
    nativeName: 'Faka Tonga'
  },
  tr: {
    isoName: 'Turkish',
    nativeName: 'Türkçe'
  },
  ts: {
    isoName: 'Tsonga',
    nativeName: 'Xitsonga'
  },
  tt: {
    isoName: 'Tatar',
    nativeName: 'татар теле, tatar tele'
  },
  tw: {
    isoName: 'Twi',
    nativeName: 'Twi'
  },
  ty: {
    isoName: 'Tahitian',
    nativeName: 'Reo Tahiti'
  },
  ug: {
    isoName: 'Uighur, Uyghur',
    nativeName: 'ئۇيغۇرچە‎, Uyghurche'
  },
  uk: {
    isoName: 'Ukrainian',
    nativeName: 'Українська'
  },
  ur: {
    isoName: 'Urdu',
    nativeName: 'اردو'
  },
  uz: {
    isoName: 'Uzbek',
    nativeName: 'Oʻzbek, Ўзбек, أۇزبېك‎'
  },
  ve: {
    isoName: 'Venda',
    nativeName: 'Tshivenḓa'
  },
  vi: {
    isoName: 'Vietnamese',
    nativeName: 'Tiếng Việt'
  },
  vo: {
    isoName: 'Volapük',
    nativeName: 'Volapük'
  },
  wa: {
    isoName: 'Walloon',
    nativeName: 'Walon'
  },
  cy: {
    isoName: 'Welsh',
    nativeName: 'Cymraeg'
  },
  wo: {
    isoName: 'Wolof',
    nativeName: 'Wollof'
  },
  fy: {
    isoName: 'Western Frisian',
    nativeName: 'Frysk'
  },
  xh: {
    isoName: 'Xhosa',
    nativeName: 'isiXhosa'
  },
  yi: {
    isoName: 'Yiddish',
    nativeName: 'ייִדיש'
  },
  yo: {
    isoName: 'Yoruba',
    nativeName: 'Yorùbá'
  },
  za: {
    isoName: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh'
  },
  zu: {
    isoName: 'Zulu',
    nativeName: 'isiZulu'
  }
}

export default languageIsoCodes
