import { useState, useRef, useCallback } from 'react'
import { isSafari } from 'react-device-detect'

export const useMicPermissionsPrompt = () => {
  const [micPermissionPending, setMicPermissionPending] = useState(false)

  const [micPermissionGranted, setMicPermissionGranted] = useState(null)
  const micPermissionGrantedRef = useRef(micPermissionGranted)
  micPermissionGrantedRef.current = micPermissionGranted

  const checkForPermissionsPrompt = useCallback(() => {
    if (!isSafari) {
      return
    }

    const checkForGrantedPermissions = () => {
      if (micPermissionGrantedRef.current === null) {
        setMicPermissionPending(true)
      }
    }

    // If permissions were not granted within timeout,
    // user most likely got a permissions prompt - they haven't allowed the app to always use the mic.
    // Show them the reminder popup.
    setTimeout(checkForGrantedPermissions, 2500)
  }, [])

  const clearMicPermissionPending = useCallback(() => setMicPermissionPending(false), [])

  return {
    micPermissionPending,
    clearMicPermissionPending,
    micPermissionGranted,
    setMicPermissionGranted,
    checkForPermissionsPrompt
  }
}
