import * as Sentry from '@sentry/react'

import { translate } from 'components/Translation/Translation'
import {
  ERROR_MIC_NOT_AVAILABLE_OR_ALREADY_USE,
  ERROR_MIC_NOT_ENABLED,
  ERROR_MIC_NOT_FOUND,
  ERROR_NO_MIC_DEFAULT,
  ERROR_NON_SECURE_CONTEXT,
  ERROR_NO_MIC_SANTA
} from 'locales/translationIds'
import { IS_FACEBOOK_ANDROID } from 'utils'

export const MIC_ERROR_MESSAGE = {
  GENERAL: ERROR_MIC_NOT_AVAILABLE_OR_ALREADY_USE,
  NOT_FOUND: ERROR_MIC_NOT_FOUND,
  REJECTED: ERROR_MIC_NOT_ENABLED,
  NO_MIC_DEFAULT: ERROR_NO_MIC_DEFAULT,
  NON_SECURE_CONTEXT: ERROR_NON_SECURE_CONTEXT,
  NO_MIC_SANTA: ERROR_NO_MIC_SANTA
}

export const alertMicrophoneErrorMessage = error => {
  if (IS_FACEBOOK_ANDROID) {
    return
  }
  const errorStr = `${error}`
  let errorMessage

  if (errorStr.includes('Permission denied') || errorStr.includes('NotAllowedError')) {
    errorMessage = MIC_ERROR_MESSAGE.REJECTED
  } else if (errorStr.includes('NotFoundError')) {
    errorMessage = MIC_ERROR_MESSAGE.NOT_FOUND
  } else if (errorStr.includes('NotReadableError')) {
    errorMessage = MIC_ERROR_MESSAGE.GENERAL
  } else {
    Sentry.captureException(error)
    errorMessage = MIC_ERROR_MESSAGE.GENERAL
  }

  alert(`${translate(errorMessage)} [${error?.name}]`)
}
