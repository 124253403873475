import { useState, useEffect, useCallback } from 'react'

import { authStoryFile, postDialogueStart } from 'api'
import {
  loadAuthCookie,
  refreshAuth,
  setAuthRefreshInterval,
  refreshVideoURLsCache
} from 'api/token-store'
import { appParamsService } from 'configuration'
import { translate } from 'components/Translation/Translation'
import { COMMON_ALERT_FACEBOOK_ANDROID } from 'locales/translationIds'
import { firebase } from 'firebase-client'
import { postEventToParent, WINDOW_MESSAGES, IS_FACEBOOK_ANDROID, loadVideoQuality } from 'utils'

let TOKEN_LISTENER_TIMEOUT
const TOKEN_LISTENER_TIMEOUT_DURATION = 5000

const { hasMultipleIds, storyFileName, storyFileIds, SCORMWrapper, useKeycloakSSO } =
  appParamsService
const videoQuality = loadVideoQuality()

export function useSession() {
  const [session, setSession] = useState({
    authorized: null,
    doesStoryFileExist: null,
    isStoryFilePrivate: null,
    ssoLoading: appParamsService.ssoLoading
  })
  const [configs, setConfigs] = useState()

  const startPollingAuthStoryFile = async (maxAttempts = 10, reInitKeycloak = () => {}) => {
    const TIME_BETWEEN_REQUESTS = 2000
    const MAX_ATTEMPTS = maxAttempts

    let attempts = 0
    let authResArr

    return new Promise((resolve, reject) => {
      const pollingInterval = setInterval(async () => {
        reInitKeycloak()

        const auth = loadAuthCookie()

        if (attempts < MAX_ATTEMPTS) {
          try {
            authResArr = await authStoryFile(
              auth?.accessToken,
              storyFileIds[0],
              storyFileName,
              videoQuality
            )

            clearInterval(pollingInterval)
            setSession(prevState => ({ ...prevState, ssoLoading: false }))
            resolve(authResArr)
          } catch (error) {
            attempts++

            if (attempts === MAX_ATTEMPTS) {
              clearInterval(pollingInterval)
              reject()
            }
          }
        }
      }, TIME_BETWEEN_REQUESTS)
    })
  }

  const startPublicSession = useCallback(async () => {
    try {
      const auth = await refreshAuth()
      let authResArr

      if (useKeycloakSSO && auth === null) {
        setSession(prevState => ({ ...prevState, doesStoryFileExist: true, authorized: false }))
        return
      }

      setAuthRefreshInterval()

      if (hasMultipleIds) {
        authResArr = await Promise.all(
          storyFileIds.map(
            async id => await authStoryFile(auth?.accessToken, id, undefined, videoQuality)
          )
        )
      } else {
        if (session.ssoLoading) {
          // Need to be polling until account is ready
          authResArr = [await startPollingAuthStoryFile()]
        } else {
          authResArr = [
            await authStoryFile(auth?.accessToken, storyFileIds[0], storyFileName, videoQuality)
          ]
        }
      }

      const notFound = authResArr.some(res => res.status === 404 || res.statusText === 'Not Found')
      if (notFound) {
        setSession(prevState => ({ ...prevState, authorized: false, doesStoryFileExist: false }))
        return
      }

      const configs = authResArr.map(res => res.data)
      setConfigs(configs)

      if (!hasMultipleIds && !appParamsService.getSessionId()) {
        await postDialogueStart(configs[0].id, auth?.iam)
      }

      setSession(prevState => ({
        ...prevState,
        authorized: true,
        doesStoryFileExist: true
      }))
    } catch (error) {
      console.error(error)
      const isUserLoggedIn = loadAuthCookie()?.isValid
      setSession({
        authorized: false,
        doesStoryFileExist: true,
        isStoryFilePrivate: isUserLoggedIn
      })
    }
  }, [])

  const startPrivateSession = useCallback(async (accessToken, iam) => {
    try {
      const response = await authStoryFile(
        accessToken,
        storyFileIds[0],
        storyFileName,
        videoQuality
      )
      const storyFile = response.data

      setConfigs([storyFile])
      if (!appParamsService.getSessionId()) {
        await postDialogueStart(storyFile.id, iam)
      }
      setSession(prevState => ({ ...prevState, authorized: true, doesStoryFileExist: true }))
    } catch (error) {
      console.error('Authorization failed: ', error)
      setSession(prevState => ({ ...prevState, authorized: false, isStoryFilePrivate: true }))
    }
  }, [])

  useEffect(() => {
    if (!storyFileName && !storyFileIds) {
      setSession(prevState => ({ ...prevState, authorized: false }))
      return
    }

    firebase.registerPerformance()

    const onParentMessage = ({ data }) => {
      // @TODO: Also check for origins where possible
      if (data.type === 'token') {
        if (TOKEN_LISTENER_TIMEOUT) {
          clearTimeout(TOKEN_LISTENER_TIMEOUT)
          TOKEN_LISTENER_TIMEOUT = null
        }

        refreshVideoURLsCache()
        if (!appParamsService.accessToken) {
          startPrivateSession(data.value, data.iam)
        }
        appParamsService.accessToken = data.value
      }

      if (data.type === 'scorm_learner_id') {
        const currentClientId = appParamsService.getClientId()
        appParamsService.setClientId(`${currentClientId}:${data.value}`)
      }
    }
    window.addEventListener('message', onParentMessage, false)

    if (SCORMWrapper) {
      postEventToParent({
        type: WINDOW_MESSAGES.SCORM_FUNC_CALL,
        func: 'postLearnerIdToIframe',
        value: 'Logging learner id '
      })
    }

    if (appParamsService.isPrivate) {
      postEventToParent(WINDOW_MESSAGES.TOKEN_REQUEST)
      TOKEN_LISTENER_TIMEOUT = setTimeout(() => {
        console.error('NOT AUTHORIZED')
        setSession(prevState => ({ ...prevState, authorized: false }))
      }, TOKEN_LISTENER_TIMEOUT_DURATION)
    } else if (useKeycloakSSO) {
      if (loadAuthCookie() === undefined) {
        setSession(prevState => ({ ...prevState, doesStoryFileExist: true, authorized: false }))
      } else {
        startPublicSession()
      }
    } else {
      startPublicSession()
    }

    return () => {
      window.removeEventListener('message', onParentMessage, false)
    }
  }, [startPublicSession, startPrivateSession])

  useEffect(() => {
    if (IS_FACEBOOK_ANDROID) {
      setTimeout(() => alert(translate(COMMON_ALERT_FACEBOOK_ANDROID)), 200)
    }
  }, [configs])

  const startSSOLoading = () => setSession(prevState => ({ ...prevState, ssoLoading: true }))

  return [session, configs, startPublicSession, startSSOLoading, startPollingAuthStoryFile]
}
