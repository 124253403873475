import { browserName, isAndroid } from 'react-device-detect'

import { appParamsService, envConfig } from 'configuration'
import { URL_PARAMS } from 'constants'
import { translate } from 'components/Translation/Translation'
import {
  localStorageService,
  sessionStorageService,
  LOCAL_STORAGE_KEY,
  SESSION_STORAGE_KEY
} from 'utils/BrowserStorageService'
import { COMMON_OFF } from 'locales/translationIds'

export const IS_FACEBOOK_ANDROID = browserName === 'Facebook' && isAndroid

export const WINDOW_MESSAGES = {
  WEBAPP_LOADED: 'webapp_loaded',
  IFRAME_HEIGHT: 'storyfile-iframe-height',
  TOKEN_REQUEST: 'token_request',
  SCORM_FUNC_CALL: 'scorm_func_call',
  MIC_VISIBLE: 'mic_visible',
  MIC_HIDDEN: 'mic_hidden',
  VIDEO_END_EVENT: 'video_end_event',
  VIDEO_START_EVENT: 'video_start_event',
  IS_LISTENING: 'is_listening',
  QUESTION: 'question',
  ASKED_QUESTION: 'asked_question',
  LANGUAGE: 'language',
  CHANGED_LANGUAGE: 'changed_language',
  START_LISTENING: 'startListening',
  STOP_LISTENING: 'stopListening',
  INTERRUPT_ANSWER: 'interruptAnswer',
  SESSION_ID: 'sessionId',
  TIMEOUT_VIDEO_START: 'timeout_video_start',
  SPAWN_RECEIVER_WINDOW: 'spawn_receiver_window'
}

const BASE_LOCAL_VIDEO_URL = 'http://localhost:8001/videos/'
const BASE_LOCAL_ELECTRON_VIDEO_URL = 'http://localhost:8001/api/videos/'

export const getPlayingVideoUrl = (url, username, isStaticApp, sessionId, userId = null) => {
  if (!url) {
    return url
  }

  if (isStaticApp) {
    const a = url.split('/')
    const path = a[a.length - 1].split('?')[0]
    const localPath = path.endsWith('.mp4') ? path : path + '.mp4'

    // we will add timestamp as parameter
    // who guarantees the url of the video will not be the same as the previous one
    const timestamp = +new Date()
    return appParamsService.isElectron
      ? `${BASE_LOCAL_ELECTRON_VIDEO_URL}${userId} - ${username}/${localPath}?timestamp=${timestamp}`
      : `${BASE_LOCAL_VIDEO_URL}${username}/${localPath}?timestamp=${timestamp}`
  }

  try {
    const newUrl = new URL(url)
    if (sessionId) {
      newUrl.searchParams.set('sessionId', sessionId)
    }

    return newUrl.href
  } catch (e) {
    console.log(`Error parsing url ${url}`, e)
    return url
  }
}

export const getPlayingVideo = url => {
  if (!url) {
    return url
  }

  const a = url.split('/')
  const path = a[a.length - 1].split('?')[0]

  return path
}

export const inIframe = window.self !== window.top

// Used when embedded as iframe, to send events to parent page
export const postEventToParent = data => {
  window.parent.postMessage(data, '*')
}

export const loadVideoQuality = () => {
  const { quality: defaultQuality, disableDynamicQuality } = appParamsService
  const cachedVideoQuality = localStorageService.getItem(LOCAL_STORAGE_KEY.VIDEO_QUALITY)

  return !disableDynamicQuality && cachedVideoQuality && cachedVideoQuality <= defaultQuality
    ? cachedVideoQuality
    : defaultQuality
}

export const formatStoryFileConfig = ({
  introVideo,
  cannotAnswerVideo,
  waitingVideo,
  timeoutVideo,
  username,
  id,
  ...rest
}) => {
  const { introVideoId, isStaticApp, sessionId } = appParamsService
  const videoQuality = loadVideoQuality()

  // @TODO: Endpoint that would accept videoId and return videoUrl + videoSubtitles
  const introVideoUrl = introVideoId
    ? `${envConfig.CONTENT_API_URL}/v1/video/${introVideoId}?quality=${videoQuality}`
    : introVideo

  return {
    configLoaded: true,
    cannotAnswerVideoUrl: getPlayingVideoUrl(
      cannotAnswerVideo,
      username,
      isStaticApp,
      sessionId,
      id
    ),
    waitingVideoUrl: getPlayingVideoUrl(waitingVideo, username, isStaticApp, null, id),
    timeoutVideoUrl: getPlayingVideoUrl(timeoutVideo, username, isStaticApp, null, id),
    introVideoUrl: getPlayingVideoUrl(introVideoUrl, username, isStaticApp, null, id),
    userId: id,
    quality: videoQuality,
    username,
    ...rest
  }
}

export const loadDefaultTransmitterSubsLabel = () =>
  sessionStorageService.getItem(SESSION_STORAGE_KEY.TRANSMITTER_SUBS_LABEL) || translate(COMMON_OFF)

export const refreshPageWithStoryFile = ({ storyFileId = null, subtitlesLabel, lang }) => {
  const newUrl = new URL(window.location.href)
  const currentId = Number(newUrl.searchParams.get(URL_PARAMS.uid))
  const currentSubs = newUrl.searchParams.get(URL_PARAMS.subs)
  const currentLang = newUrl.searchParams.get(URL_PARAMS.l)
  const hasStoryFileChanged = currentId !== Number(storyFileId)
  const hasSettingsChanged = currentSubs !== subtitlesLabel || currentLang !== lang
  const hasUrlParamsChanged = hasStoryFileChanged || hasSettingsChanged

  if (!hasUrlParamsChanged) return

  if (storyFileId) {
    newUrl.searchParams.set(URL_PARAMS.uid, storyFileId)
    newUrl.searchParams.set(URL_PARAMS.subs, subtitlesLabel)
    newUrl.searchParams.set(URL_PARAMS.l, lang)

    window.location.href = newUrl.href
  } else if (hasStoryFileChanged) {
    newUrl.searchParams.delete(URL_PARAMS.uid)
    window.location.href = newUrl.href
  } else if (hasSettingsChanged) {
    newUrl.searchParams.set(URL_PARAMS.l, lang)
    newUrl.searchParams.set(URL_PARAMS.subs, subtitlesLabel)

    window.location.href = newUrl.href
  }
}

export const reloadWithMode = (skin = 'web') => {
  const newUrl = new URL(window.location.href)
  newUrl.searchParams.delete(URL_PARAMS.mode)
  newUrl.searchParams.delete(URL_PARAMS.skin)
  newUrl.searchParams.set(URL_PARAMS.skin, skin)

  window.location.href = newUrl.href
}

export const handleKeyPress = (event, keyValue, callback, condition = true) => {
  if (event.key === keyValue && condition) {
    event.preventDefault()
    callback()
  }
}
