import React, { useState } from 'react'
import classNames from 'classnames'

import Svg, { cheveronDown, cheveronRight } from 'components/shared/Svg'
import Translation, { translate } from 'components/Translation/Translation'
import {
  COMMON_CONV_STARTERS_QUESTIONS,
  COMMON_CONV_STARTERS_QUESTIONS_SHORT
} from 'locales/translationIds'
import { handleKeyPress } from 'utils'

import styles from './ConvStartersDropdownTopic.module.css'

const ConvStartersDropdownTopic = ({ topic, onQuestionClick, isMenuExpanded, icon }) => {
  const [isTopicExpanded, setIsTopicExpanded] = useState(false)

  const questionCount = topic.children.length
  return (
    <>
      <button
        className={classNames(styles.topic, styles.topicButton, {
          [styles.topicButtonExpanded]: isTopicExpanded
        })}
        onClick={() => setIsTopicExpanded(!isTopicExpanded)}
        tabIndex={isMenuExpanded ? 0 : -1}
        aria-expanded={isTopicExpanded}
        aria-label={`${topic.text} ${questionCount} ${translate(COMMON_CONV_STARTERS_QUESTIONS)}`}
        onKeyDown={e => handleKeyPress(e, 'Enter', () => setIsTopicExpanded(!isTopicExpanded))}
      >
        <div className={styles.topicHeader} aria-hidden="true">
          <div className={styles.topicText}>
            <span className={styles.topicIconContainer}>
              <Svg
                className={styles.topicIcon}
                width={30}
                height={30}
                path={isTopicExpanded ? cheveronDown : cheveronRight}
              />
            </span>
            {topic.text}
          </div>
          <div className={styles.topicCount}>
            <div className={styles.topicQuestionIcon} />
            <div>
              {questionCount}
              <div className={styles.topicQuestionCountLabel}>
                <span className={styles.fullCountLabel}>
                  <Translation id={COMMON_CONV_STARTERS_QUESTIONS} />
                </span>
                <span className={styles.shortCountLabel}>
                  <Translation id={COMMON_CONV_STARTERS_QUESTIONS_SHORT} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </button>
      <div
        className={classNames(styles.topic, styles.topicMenu, {
          [styles.childVisible]: isTopicExpanded,
          [styles.childHidden]: !isTopicExpanded
        })}
      >
        {topic.children.map((child, i) => (
          <button
            className={classNames(styles.item, styles.topicQuestion)}
            key={child.id}
            onClick={() => onQuestionClick(child.text)}
            tabIndex={isMenuExpanded ? 0 : -1}
          >
            {icon}
            {child.text}
          </button>
        ))}
      </div>
    </>
  )
}

export default ConvStartersDropdownTopic
