import React, { useEffect, useState } from 'react'

import Modal from 'components/Modal/Modal'
import Spinner from 'components/Spinner/Spinner'
import { getSessionScore } from 'api'

import EndEventMessage from './components/EndEventMessage/EndEventMessage'
import EndEventSessionScore from './components/EndEventSessionScore/EndEventSessionScore'
import { ACTION } from '../../hooks/useStateReducer'
import { END_EVENT_DIALOGUE_TYPES } from '../../util/handleVideoEvent'

const EndEventDialogueBox = ({ interactData, type, dispatch, description, urlButtons }) => {
  const isEventEndStoryfile = type === END_EVENT_DIALOGUE_TYPES.endStoryfile
  const [sessionScoreData, setSessionScoreData] = useState({})
  const [loading, setLoading] = useState(isEventEndStoryfile)

  useEffect(() => {
    if (isEventEndStoryfile) {
      const fetchSessionScore = async () => {
        try {
          const response = await getSessionScore()
          setSessionScoreData(response.data)
        } catch (e) {
          console.error('No activity data available for this session.')
        }
        setLoading(false)
      }

      fetchSessionScore()
    }
  }, [isEventEndStoryfile])

  const resetDialogueBox = () => dispatch({ type: ACTION.RESET_END_DIALOGUE_BOX })

  return (
    <Modal isOpen={true} closeOnOverlayClick={!isEventEndStoryfile} onClose={resetDialogueBox}>
      {loading ? (
        <Spinner />
      ) : isEventEndStoryfile && sessionScoreData.activity ? (
        <EndEventSessionScore data={sessionScoreData} />
      ) : (
        <EndEventMessage
          data={interactData}
          description={description}
          urlButtons={urlButtons}
          isEventShowURL={type === END_EVENT_DIALOGUE_TYPES.showUrl}
          isEventEndStoryfile={isEventEndStoryfile}
          resetDialogueBox={resetDialogueBox}
        />
      )}
    </Modal>
  )
}

export default EndEventDialogueBox
