const VIEW_STORYFILE = 'view_storyfile',
  ENGAGE_INTERACT_BUTTON = 'storyfile_engage_interact_button',
  ENGAGE_HINTS_BUTTON = 'storyfile_engage_hints_button',
  ENGAGE_ASK_FORM = 'storyfile_engage_ask_form',
  ENGAGE_SHARED_TRANSCRIPTION = 'storyfile_engage_shared_transcription',
  ENGAGE_EXTERNAL = 'storyfile_engage_external',
  ASK_QUESTION = 'ask_question'

export {
  ASK_QUESTION,
  ENGAGE_INTERACT_BUTTON,
  ENGAGE_HINTS_BUTTON,
  ENGAGE_ASK_FORM,
  ENGAGE_SHARED_TRANSCRIPTION,
  ENGAGE_EXTERNAL,
  VIEW_STORYFILE
}
