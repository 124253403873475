import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { getCSSVariable } from 'helpers'

import styles from './MicIndicatorCircle.module.css'

const MicIndicatorCircle = ({ showActiveCircle, indicatorCircleClassName }) => {
  const motionVariants = useRef()

  useEffect(() => {
    motionVariants.current = {
      hidden: {
        height: getCSSVariable('--mic-indicator-circle-height'),
        width: getCSSVariable('--mic-indicator-circle-width'),
        transitionEnd: { visibility: 'hidden' }
      },
      visible: {
        height: getCSSVariable('--mic-indicator-circle-active-height'),
        width: getCSSVariable('--mic-indicator-circle-active-width'),
        visibility: 'visible'
      }
    }
  }, [])

  return (
    <motion.span
      className={classNames(styles.indicatorCircle, indicatorCircleClassName)}
      initial="hidden"
      animate={showActiveCircle ? 'visible' : 'hidden'}
      variants={motionVariants.current}
      transition={{ duration: 0.2 }}
    />
  )
}

export default React.memo(MicIndicatorCircle)
