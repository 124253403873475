// DATA SOURCE: https://cloud.google.com/speech-to-text/docs/languages

const DEFAULT_LANGUAGE = {
  tag: 'en-US',
  name: 'English (US)',
  appLocale: 'en',
  isDefault: true
}

const LANGUAGES = [
  {
    tag: 'ar-EG',
    name: 'Arabic',
    appLocale: 'en'
  },
  {
    tag: 'zh', // has more variants
    name: 'Chinese (S)',
    appLocale: 'en'
  },
  {
    tag: 'zh-TW', // has more variants
    name: 'Chinese (T)',
    appLocale: 'en'
  },
  {
    tag: 'nl-NL',
    name: 'Dutch',
    appLocale: 'en'
  },
  {
    tag: 'en-AU',
    name: 'English (AU)',
    appLocale: 'en'
  },
  DEFAULT_LANGUAGE,
  {
    tag: 'en-GB',
    name: 'English (UK)',
    appLocale: 'en'
  },
  {
    tag: 'fr-FR', // has more variants
    name: 'French',
    appLocale: 'en'
  },
  {
    tag: 'de-DE',
    name: 'German',
    appLocale: 'en'
  },
  {
    tag: 'it-IT',
    name: 'Italian',
    appLocale: 'en'
  },
  {
    tag: 'ja-JP',
    name: 'Japanese',
    appLocale: 'en'
  },
  {
    tag: 'ko-KR',
    name: 'Korean',
    appLocale: 'en'
  },
  {
    tag: 'pl-PL',
    name: 'Polish',
    appLocale: 'en'
  },
  {
    tag: 'pt-PT', // has more variants
    name: 'Portuguese',
    appLocale: 'en'
  },
  {
    tag: 'ru-RU',
    name: 'Russian',
    appLocale: 'en'
  },
  {
    tag: 'es-ES',
    name: 'Spanish',
    appLocale: 'es'
  },
  {
    tag: 'es-US',
    name: 'Spanish (US)',
    appLocale: 'es'
  },
  {
    tag: 'th-TH',
    name: 'Thai',
    appLocale: 'en'
  },
  {
    tag: 'tr-TR',
    name: 'Turkish',
    appLocale: 'en'
  },
  {
    tag: 'uk-UA',
    name: 'Ukrainian',
    appLocale: 'en'
  }
]

// Temporary support language variants without adding them to UI menu.
// @TODO: Unify language and variants data when new UI designs are decided.
const LANGUAGE_VARIANTS = [
  {
    name: 'Arabic',
    appLocale: 'en',
    variants: [
      'ar-DZ',
      'ar-BH',
      'ar-EG',
      'ar-IQ',
      'ar-IL',
      'ar-JO',
      'ar-KW',
      'ar-LB',
      'ar-MR',
      'ar-MA',
      'ar-OM',
      'ar-QA',
      'ar-SA',
      'ar-PS',
      'ar-TN',
      'ar-AE',
      'ar-YE'
    ]
  },
  {
    name: 'English',
    appLocale: 'en',
    variants: [
      'en-CA',
      'en-GH',
      'en-HK',
      'en-IN',
      'en-IE',
      'en-KE',
      'en-NZ',
      'en-NG',
      'en-PK',
      'en-PH',
      'en-SG',
      'en-ZA',
      'en-TZ'
    ]
  }
]

module.exports = { DEFAULT_LANGUAGE, LANGUAGES, LANGUAGE_VARIANTS }
