import React from 'react'
import classNames from 'classnames'

import SkipIntroIcon from './SkipIntroIcon'

import styles from './SkipIntroButton.module.css'

const SkipIntroButton = ({ onClick = () => {}, containerClassName, showIcon = true }) => (
  <div
    id="skip-intro-btn"
    onClick={onClick}
    className={classNames(styles.skipIntroButton, containerClassName)}
  >
    {showIcon && <SkipIntroIcon />}
  </div>
)

export default SkipIntroButton
