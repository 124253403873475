import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Modal from 'components/Modal/Modal'
import Svg, { arrowThinRight } from 'components/shared/Svg'
import Translation from 'components/Translation/Translation'
import { COMMON_ROTATE_YOUR_DEVICE, COMMON_CONTINUE } from 'locales/translationIds'

import RotatePhoneIcon from './RotatePhoneIcon'

import styles from './PreferredOrientationOverlay.module.css'

const PreferredOrientationOverlay = ({ isOpen, onClick = () => {} }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(isOpen)
  }, [setIsVisible, isOpen])

  const onClicked = () => {
    onClick()
    setIsVisible(false)
  }

  return (
    <Modal isOpen={isVisible} closeOnOverlayClick={false} className={styles.overlay}>
      <div
        className={classNames(styles.container, {
          [styles.containerVisible]: isVisible
        })}
      >
        <RotatePhoneIcon className={styles.icon} />
        <div className={styles.text}>
          <Translation id={COMMON_ROTATE_YOUR_DEVICE} />
        </div>
        <button className={styles.button} onClick={onClicked}>
          <div className={styles.buttonText}>
            <Translation id={COMMON_CONTINUE} />
          </div>
          <Svg className={styles.buttonIcon} path={arrowThinRight} height={18} width={20} />
        </button>
      </div>
    </Modal>
  )
}

export default PreferredOrientationOverlay
