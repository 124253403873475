import React, { useEffect } from 'react'

import { appParamsService } from 'configuration'
import { convertHexToRGB, hexShade } from 'utils/color'
import { setCSSVariable } from 'helpers'

const AskSantaStyles = React.lazy(() => import('styles/santaStyles'))
const TimeMagazineStyles = React.lazy(() => import('styles/timeStyles'))
const MedtronicStyles = React.lazy(() => import('styles/medtronicStyles'))
const HealthysoilStyles = React.lazy(() => import('styles/healthysoilStyles'))
const PaintForACureStyles = React.lazy(() => import('styles/pfacStyles'))
const SafeliteStyles = React.lazy(() => import('styles/safeliteStyles'))
const NavalAviationMuseumStyles = React.lazy(() => import('styles/navalAviationMuseum'))

const {
  isModeSanta,
  isModeTime,
  isModeMedtronic,
  isModeHealthySoil,
  isModePaintForACure,
  isModeSafelite,
  isModeNavalAviationMuseum,
  themeColorPrimary
} = appParamsService

const ThemeProvider = ({ children }) => {
  useEffect(() => {
    if (themeColorPrimary) {
      const themeColorPrimaryRGB = convertHexToRGB(themeColorPrimary)
      const { hex: lightColorHex } = hexShade(0.4, themeColorPrimary)
      const { hex: darkColorHex } = hexShade(-0.5, themeColorPrimary)
      setCSSVariable('--theme-color-primary', themeColorPrimary)
      setCSSVariable('--theme-color-primary-rgb', themeColorPrimaryRGB)
      setCSSVariable('--theme-color-primary-light', lightColorHex)
      setCSSVariable('--theme-color-primary-dark', darkColorHex)
    }
  }, [])

  const stylesSelector = () => {
    if (isModeSanta) {
      return <AskSantaStyles />
    }

    // modeTime to be removed in the future
    if (isModeTime) {
      return <TimeMagazineStyles />
    }

    if (isModeMedtronic) {
      return <MedtronicStyles />
    }

    if (isModeHealthySoil) {
      return <HealthysoilStyles />
    }

    if (isModePaintForACure) {
      return <PaintForACureStyles />
    }

    if (isModeSafelite) {
      return <SafeliteStyles />
    }

    if (isModeNavalAviationMuseum) {
      return <NavalAviationMuseumStyles />
    }
  }

  return (
    <>
      <React.Suspense fallback={<></>}>{stylesSelector()}</React.Suspense>
      {children}
    </>
  )
}

export default ThemeProvider
