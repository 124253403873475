import axios from 'axios'

import { appParamsService } from 'configuration'
import { postEventToParent, WINDOW_MESSAGES } from 'utils'
import { translate } from 'components/Translation/Translation'
import { COMMON_SOMETHING_WENT_WRONG } from 'locales/translationIds'

const ERROR_LOG_LEVELS = ['error', 'warn', 'info']

const axiosLocal = axios.create({
  baseURL: '/',
  timeout: 1000 * 30,
  headers: { Accept: `application/json` }
})

export const login = (email, password) =>
  axiosLocal.post(`/api/login`, {
    email,
    password
  })

export const resetPassword = email => axiosLocal.post('/api/forgotPassword', { email })

export const refreshApiToken = ({ refreshToken }) =>
  axiosLocal.post(`/api/refresh`, { refreshToken })

export const authStoryFile = async (accessToken, storyfileId, storyfileName, quality) =>
  axiosLocal.post(
    `/api/authStoryfile`,
    {
      accessToken,
      storyfileId,
      storyfileName,
      quality
    },
    {
      validateStatus: status => status < 500
    }
  )

export const authStoryFileCrypto = payload => axiosLocal.post(`/api/authStoryfile/crypto`, payload)

export const logError = (logMsg, level = 'error') =>
  axiosLocal.post('/api/log', {
    level: ERROR_LOG_LEVELS.includes(level) ? level : 'error',
    log: logMsg,
    sessionId: appParamsService.sessionId,
    userAgent: navigator.userAgent
  })

export const getConversationStarters = storyfileId =>
  axiosLocal.get(`api/${storyfileId}/conversationStarters`)

export const postDialogueStart = async (storyfileId, iam) => {
  try {
    const res = await axiosLocal.post('/api/start', { storyfileId, iam })

    const sessionId = res.data.sessionId
    appParamsService.setSessionId(sessionId)
    postEventToParent({ type: WINDOW_MESSAGES.SESSION_ID, value: sessionId })
  } catch (error) {
    console.error(`Dialogue service error: ${error}`)
    alert(translate(COMMON_SOMETHING_WENT_WRONG))
    window.location.reload()
  }
}

export const postDialogueInteract = (transcription, quality, langTag) =>
  axiosLocal.post('/api/interact', {
    sessionId: appParamsService.sessionId,
    userUtterance: transcription,
    clientId: appParamsService.getClientId(),
    lang: langTag,
    quality
  })

export const postSessionStateUpdate = ({ inkActionType, timeoutVideoId }) =>
  axiosLocal.post('/api/updateSessionState', {
    sessionId: appParamsService.sessionId,
    inkActionType,
    timeoutVideoId
  })

export const getRandomVideo = (storyfileId, questionType) =>
  axiosLocal.get(`api/${storyfileId}/randomVideoAnswer/${questionType}`)

export const getSessionScore = () => axiosLocal.get(`/api/session/${appParamsService.sessionId}`)

export const postScrapeShareLink = url => axiosLocal.post('/api/scrapeLink', { id: url })

export const postTranscriptSanitize = (storyfileId, transcript) =>
  axiosLocal.post('/api/post-transcript-sanitize', { storyfileId, transcript })
