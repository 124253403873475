export const AUTH_HEADER_PROVIDE_ID = 'auth.header.provideId'
export const AUTH_HEADER_NO_ACCESS = 'auth.header.noAccess'
export const AUTH_HEADER_LOGIN_REQUIRED = 'auth.header.loginRequired'
export const AUTH_HEADER_NOT_EXIST = 'auth.header.notExist'
export const AUTH_ERROR_ALL_FIELDS_REQUIRED = 'auth.error.allFieldsRequired'
export const AUTH_ERROR_WRONG_EMAIL_OR_PASSWORD = 'auth.error.wrongEmailOrPassword'
export const AUTH_ERROR_EMPTY_EMAIL = 'auth.error.emptyEmail'
export const AUTH_COMMON_AUTH_COMPLETED = 'auth.common.authCompleted'
export const AUTH_COMMON_RESET_HELPER_TEXT = 'auth.common.resetHelperText'
export const AUTH_COMMON_USE_S_S_O = 'auth.common.useSSO'
export const AUTH_COMMON_BACK_TO_LOGIN = 'auth.common.backToLogin'
export const AUTH_COMMON_FORGOT_PASSWORD = 'auth.common.forgotPassword'
export const AUTH_COMMON_PASSWORD_RESET = 'auth.common.passwordReset'
export const AUTH_COMMON_MEMBER_LOGIN = 'auth.common.memberLogin'
export const AUTH_COMMON_EMAIL = 'auth.common.email'
export const AUTH_COMMON_PASSWORD = 'auth.common.password'
export const AUTH_COMMON_SEND = 'auth.common.send'
export const AUTH_COMMON_LOGIN = 'auth.common.login'
export const AUTH_COMMON_OR_LOGIN_WITH = 'auth.common.orLoginWith'
export const AUTH_METAMASK_NOT_INSTALLED_ON_BROWSER = 'auth.metamask.notInstalledOnBrowser'
export const AUTH_METAMASK_SUCCESSFULLY_CONNECTED = 'auth.metamask.successfullyConnected'
export const AUTH_METAMASK_UNEXPECTED_ERROR = 'auth.metamask.unexpectedError'
export const AUTH_METAMASK_PLEASE_LOGIN_WITH_ETHEREUM_WALLET = 'auth.metamask.pleaseLoginWithEthereumWallet'
export const AUTH_METAMASK_CLICK_ON_METAMASK = 'auth.metamask.clickOnMetamask'
export const AUTH_METAMASK_LOGIN_THROUGH_METAMASK = 'auth.metamask.loginThroughMetamask'
export const SCORE_NUMBER_OF_QUESTIONS_MET_REQUIREMENTS = 'score.numberOfQuestionsMetRequirements'
export const SCORE_NUMBER_OF_QUESTIONS_DIDNT_MEET_REQUIREMENTS = 'score.numberOfQuestionsDidntMeetRequirements'
export const SCORE_NO_ASKED_QUESTIONS = 'score.noAskedQuestions'
export const SCORE_YOUR_RESULTS = 'score.yourResults'
export const SCORE_YOUR_OVERALL_SCORE = 'score.yourOverallScore'
export const INSTRUCTIONS_HOLD_DOWN_ANYWHERE = 'instructions.holdDownAnywhere'
export const INSTRUCTIONS_HOLD_THE_BUTTON = 'instructions.holdTheButton'
export const INSTRUCTIONS_RED_LIGHT_INDICATES = 'instructions.redLightIndicates'
export const INSTRUCTIONS_RELEASE_WHEN_YOUR_QUESTION = 'instructions.releaseWhenYourQuestion'
export const INSTRUCTIONS_INTERRUPT_RESPONSE_BY_CLICKING = 'instructions.interruptResponseByClicking'
export const INSTRUCTIONS_YOUR_QUESTION = 'instructions.yourQuestion'
export const INSTRUCTIONS_TRY_ASKING_QUESTION_LIST = 'instructions.tryAskingQuestionList'
export const ERROR_MIC_NOT_AVAILABLE_OR_ALREADY_USE = 'error.micNotAvailableOrAlreadyUse'
export const ERROR_MIC_NOT_FOUND = 'error.micNotFound'
export const ERROR_MIC_NOT_ENABLED = 'error.micNotEnabled'
export const ERROR_NO_MIC_DEFAULT = 'error.noMicDefault'
export const ERROR_NON_SECURE_CONTEXT = 'error.nonSecureContext'
export const ERROR_NO_MIC_SANTA = 'error.noMicSanta'
export const COMMON_ALERT_FACEBOOK_ANDROID = 'common.alert.facebookAndroid'
export const COMMON_ASK_ASK_FORM_BUTTON = 'common.ask.askFormButton'
export const COMMON_ASK_ASK_FORM_BUTTON_SAFELITE = 'common.ask.askFormButtonSafelite'
export const COMMON_ASK_ASK_FORM_PLACEHOLDER = 'common.ask.askFormPlaceholder'
export const COMMON_ASK_ASK_FORM_PLACEHOLDER_SAFELITE = 'common.ask.askFormPlaceholderSafelite'
export const COMMON_CONV_STARTERS_HINTS = 'common.convStarters.hints'
export const COMMON_CONV_STARTERS_HINTS_QUESTION = 'common.convStarters.hintsQuestion'
export const COMMON_CONV_STARTERS_TOGGLE_LABEL = 'common.convStarters.toggleLabel'
export const COMMON_CONV_STARTERS_DISCOVER_QUESTIONS = 'common.convStarters.discoverQuestions'
export const COMMON_CONV_STARTERS_QUESTIONS = 'common.convStarters.questions'
export const COMMON_CONV_STARTERS_QUESTIONS_SHORT = 'common.convStarters.questionsShort'
export const COMMON_CONV_STARTERS_NO_HINTS = 'common.convStarters.noHints'
export const COMMON_CREATE_YOUR_STORY_FILE = 'common.createYourStoryFile'
export const COMMON_AUTO_SWITCH_TIMES = 'common.autoSwitchTimes'
export const COMMON_RESOURCES = 'common.resources'
export const COMMON_LOADING_APP = 'common.loadingApp'
export const COMMON_SUBTITLES = 'common.subtitles'
export const COMMON_VOICE_INPUT = 'common.voiceInput'
export const COMMON_VOICE_INPUT_SAFELITE = 'common.voiceInputSafelite'
export const COMMON_OFF = 'common.off'
export const COMMON_WHAT_IS_YOUR_STORY = 'common.whatIsYourStory'
export const COMMON_INTERACT = 'common.interact'
export const COMMON_REMOTE_RECEIVER_MODE = 'common.remoteReceiverMode'
export const COMMON_ACTIVATE_FROM_TRANSMITTER_DEVICE = 'common.activateFromTransmitterDevice'
export const COMMON_CONTINUE = 'common.continue'
export const COMMON_SELECT_STORYFILE_TO_INTERACT = 'common.selectStoryfileToInteract'
export const COMMON_SOMETHING_WENT_WRONG = 'common.somethingWentWrong'
export const COMMON_STOP = 'common.stop'
export const COMMON_TAP_AND_HOLD_TO = 'common.tapAndHoldTo'
export const COMMON_ASK_QUESTION = 'common.askQuestion'
export const COMMON_ACTIVE = 'common.active'
export const COMMON_TRANSMITTER_ID = 'common.transmitterId'
export const COMMON_CONNECTION_STATUS = 'common.connectionStatus'
export const COMMON_AWAITING_CONNECTIONS = 'common.awaitingConnections'
export const COMMON_YOU_HAVE_COMPLETED_SCENARIO = 'common.youHaveCompletedScenario'
export const COMMON_OPEN_LINK_BELOW_IN_NEW_TAB = 'common.openLinkBelowInNewTab'
export const COMMON_YES = 'common.yes'
export const COMMON_CLOSE = 'common.close'
export const COMMON_POSSIBLE = 'common.possible'
export const COMMON_YOU_CAN_CLOSE_THIS_BROWSER = 'common.youCanCloseThisBrowser'
export const COMMON_OPEN_RECEIVER_WINDOW = 'common.openReceiverWindow'
export const COMMON_COMPLETED = 'common.completed'
export const COMMON_PERCENT = 'common.percent'
export const COMMON_GET_STARTED = 'common.getStarted'
export const COMMON_INSTRUCTIONS = 'common.instructions'
export const COMMON_HAPPY_STORYFILING = 'common.happyStoryfiling'
export const COMMON_BACK = 'common.back'
export const COMMON_POWERED_BY = 'common.poweredBy'
export const COMMON_RECEIVER_STATUS = 'common.receiverStatus'
export const COMMON_LISTENING = 'common.listening'
export const COMMON_SHARE = 'common.share'
export const COMMON_SHARE_LAST_QUESTION = 'common.shareLastQuestion'
export const COMMON_COPY = 'common.copy'
export const COMMON_COPIED = 'common.copied'
export const COMMON_COPY_LINK = 'common.copyLink'
export const COMMON_ROTATE_YOUR_DEVICE = 'common.rotateYourDevice'
export const COMMON_COULD_NOT_RECOGNIZE_YOUR_SPEECH = 'common.couldNotRecognizeYourSpeech'
export const COMMON_CONNECTING_TO = 'common.connectingTo'
export const COMMON_CONNECTED_TO = 'common.connectedTo'
export const COMMON_CONNECTED_TO_PEER = 'common.connectedToPeer'
export const COMMON_DISCONNECTED = 'common.disconnected'
export const COMMON_TALK_TO_ON_STORY_FILE = 'common.talkToOnStoryFile'
export const COMMON_SHARE_EMAIL_BODY = 'common.shareEmailBody'
