import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

import { SOURCE_OF_INTERACTION } from 'containers/VideoScreen/constants'
import { envConfig } from 'configuration'

import {
  ASK_QUESTION,
  ENGAGE_ASK_FORM,
  ENGAGE_EXTERNAL,
  ENGAGE_HINTS_BUTTON,
  ENGAGE_INTERACT_BUTTON,
  ENGAGE_SHARED_TRANSCRIPTION,
  VIEW_STORYFILE
} from './events'

const mapAppEventsToFirebaseEvents = {
  [SOURCE_OF_INTERACTION.ASK_FORM]: ENGAGE_ASK_FORM,
  [SOURCE_OF_INTERACTION.CONVERSATION_STARTERS]: ENGAGE_HINTS_BUTTON,
  [SOURCE_OF_INTERACTION.SHARED_TRANSCRIPTION]: ENGAGE_SHARED_TRANSCRIPTION,
  [SOURCE_OF_INTERACTION.VOICE]: ENGAGE_INTERACT_BUTTON,
  [SOURCE_OF_INTERACTION.EXTERNAL]: ENGAGE_EXTERNAL
}

class Firebase {
  constructor() {
    try {
      this.firebaseApp = initializeApp(envConfig.FIREBASE)
      this.analytics = getAnalytics(this.firebaseApp)
    } catch (err) {
      console.error('Failed to initialize firebase: ', err)
    }
  }

  _logEvent(event, args) {
    if (!this.analytics) return

    try {
      logEvent(this.analytics, event, args)
    } catch (err) {
      console.error('Error while logging firebase event: ', err)
    }
  }

  _setUserProperties(props) {
    if (!this.analytics) return

    try {
      setUserProperties(this.analytics, props)
    } catch (err) {
      console.error('Error while setting firebase properties: ', err)
    }
  }

  registerPerformance() {
    if (!this.firebaseApp) return

    try {
      getPerformance(this.firebaseApp)
    } catch (err) {
      console.error('Error while registering firebase performance: ', err)
    }
  }

  log(type, data) {
    switch (type) {
      case ASK_QUESTION: {
        const { userId, clientId } = data
        this._logEvent(ASK_QUESTION, { storyfile_id: data.userId })
        this._logEvent(mapAppEventsToFirebaseEvents[data.source], {
          storyfile_id: userId,
          client_id: clientId
        })
        break
      }
      case VIEW_STORYFILE: {
        const { userId, username, isStaticApp } = data
        this._logEvent(VIEW_STORYFILE, {
          userId: userId,
          storyfile_id: userId
        })
        this._setUserProperties({
          viewed_storyfile: username,
          static_app: isStaticApp
        })
        break
      }
      default: {
        console.error(`Unsupported event type: ${type}`)
      }
    }
  }
}

export const firebase = new Firebase()
