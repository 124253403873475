import React from 'react'

import { appParamsService } from 'configuration'
import smallLogoDefault from 'assets/images/white-small-logo.png'
import smallLogoHealthySoil from 'assets/images/Healthysoil/sf-small-logo-healthysoil.png'
import Translation from 'components/Translation/Translation'
import { COMMON_WHAT_IS_YOUR_STORY } from 'locales/translationIds'

import styles from './StoryFileLogo.module.css'

const { isModeHealthySoil } = appParamsService

const StoryFileLogo = () => {
  return (
    <div className={styles.logo}>
      <img
        width=""
        height="60"
        src={isModeHealthySoil ? smallLogoHealthySoil : smallLogoDefault}
        alt="storyfile-logo"
      />
      <h3 className={styles.noMargin}>Storyfile</h3>
      {!isModeHealthySoil && (
        <h6 className={styles.smallText}>
          <Translation id={COMMON_WHAT_IS_YOUR_STORY} />
        </h6>
      )}
    </div>
  )
}

export default StoryFileLogo
