import React from 'react'
import { isMobileSafari } from 'react-device-detect'

import Modal from 'components/Modal/Modal'
import FormButton from 'components/FormButton/FormButton'

import desktopSafariHelperImg from 'assets/images/desktop_safari_mic_help.png'
import iOSSafariHelperImg from 'assets/images/iOS_safari_mic_help.png'
import styles from './PermissionsReminderPopup.module.css'

const PermissionsReminderPopup = ({ isOpen, close }) => {
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div>Allow Microphone Access</div>
        </div>
        <div className={styles.helperImgContainer}>
          <div className={styles.text}>
            {isMobileSafari ? (
              <>
                <p>
                  1. Tap on{' '}
                  <b>
                    A<span style={{ fontSize: 24 }}>A</span>
                  </b>
                </p>
                <p>
                  2. Go to <b>Website Settings</b>
                </p>
                <p>
                  3. Select <b>Microphone</b>
                </p>
                <p>
                  4. Check <b>Allow</b>
                </p>
              </>
            ) : (
              <>
                Please give us permissions to use your microphone by going to{' '}
                <b>Website Settings</b> and setting <b>Microphone</b> to <b>Allow</b>.
              </>
            )}
          </div>
          <img
            className={styles.helperImg}
            src={isMobileSafari ? iOSSafariHelperImg : desktopSafariHelperImg}
            alt="Screenshot of Safari permissions menu"
          />
        </div>
        <FormButton styling="default" text="Close" onClick={close} />
      </div>
    </Modal>
  )
}

export default PermissionsReminderPopup
