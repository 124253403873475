import { useEffect } from 'react'

import { postEventToParent, WINDOW_MESSAGES, inIframe } from 'utils'
import { debounce } from 'helpers'

// Determine and post to parent the height of scrollable content
// Parent can listen to this event and adjust the height of the iframe
// Seems to not work for fixed/absolute containers, e.g. in VideoPlayer.js
const useIframeContentHeight = containerId => {
  useEffect(() => {
    const container = document.getElementById(containerId)
    if (!container || !inIframe) {
      return
    }

    const postContentHeightToParent = () => {
      // Reset height to 1px to force browser to recalculate scroll height
      container.style.height = '1px'
      const scrollHeight = container.scrollHeight
      container.style.height = `${scrollHeight}px`

      postEventToParent({
        type: WINDOW_MESSAGES.IFRAME_HEIGHT,
        value: scrollHeight
      })
    }

    const observer = new ResizeObserver(
      debounce(() => {
        postContentHeightToParent()
      }, 100)
    )

    observer.observe(container)

    return () => {
      observer.disconnect()
    }
  }, [containerId])
}

export default useIframeContentHeight
