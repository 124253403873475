import React from 'react'

import { loadThemeColor } from 'utils/color'

const SettingsMenuItem = ({ isOpen }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.27 41.07"
    width="30"
    height="30"
  >
    <defs>
      <style>
        {`.smci1 { 
            fill:${loadThemeColor(
              isOpen ? '--settings-menu-button-selected-color' : '--settings-menu-button-color'
            )};
        }`}
      </style>
    </defs>
    <g id="Page-1">
      <g id="Artboard">
        <g id="settings">
          <path
            id="Shape"
            className="smci1"
            d="M38.67,16.15l-3.19-.4a11.31,11.31,0,0,0-1-2.39l2-2.6a2.46,2.46,0,0,0-.2-3.38L33.49,4.59a2.48,2.48,0,0,0-3.39-.2l-2.59,2a12.16,12.16,0,0,0-2.39-1l-.4-3.19A2.27,2.27,0,0,0,22.33,0H18.54a2.39,2.39,0,0,0-2.39,2.19l-.4,3.19a14,14,0,0,0-2.39,1l-2.6-2a2.68,2.68,0,0,0-3.38.2L4.59,7.38a2.68,2.68,0,0,0-.2,3.38l2,2.6a22.09,22.09,0,0,0-1,2.19l-3.19.6A2.39,2.39,0,0,0,0,18.54v3.79a2.6,2.6,0,0,0,2.19,2.59l3.19.4a12.16,12.16,0,0,0,1,2.39l-2,2.59a2.48,2.48,0,0,0,.2,3.39l2.79,2.79a2.46,2.46,0,0,0,3.38.2l2.6-2a11.86,11.86,0,0,0,2.39,1l.4,3.19a2.59,2.59,0,0,0,2.59,2.2h3.79a2.6,2.6,0,0,0,2.59-2.2l.4-3.19a12.35,12.35,0,0,0,2.39-1l2.59,2a2.48,2.48,0,0,0,3.39-.2l2.79-2.79a2.48,2.48,0,0,0,.2-3.39l-2-2.59a12.1,12.1,0,0,0,1-2.39l3.19-.4a2.6,2.6,0,0,0,2.2-2.59V18.54A2.77,2.77,0,0,0,38.67,16.15Zm-18.14,13a8.58,8.58,0,1,1,8.58-8.58h0a8.78,8.78,0,0,1-8.58,8.58Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SettingsMenuItem
