import React, { useRef, useContext, useImperativeHandle, forwardRef } from 'react'
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import { isMobile, isIOS } from 'react-device-detect'

import { StoryFileContext } from 'App'
import { checkpointService, CHECKPOINT_EVENT } from 'api/checkpoint'
import { appendAccessToken } from 'api/token-store'
import { appParamsService } from 'configuration'
import Spinner from 'components/Spinner/Spinner'
import AudioVisualizer from 'components/AudioVisualizer/AudioVisualizer'
import { postEventToParent, WINDOW_MESSAGES, getPlayingVideo, IS_FACEBOOK_ANDROID } from 'utils'

import styles from './VideoPlayer.module.css'

const { disablePlayerDissolve, playerBackground, showTranscription, showSpeechVisualizer } =
  appParamsService

const getPlayerConfig = (attributes, tracks = []) => ({ file: { attributes, tracks } })

const VideoPlayer = (
  {
    answerVideoUrl,
    answerVideoSubtitles,
    waitingVideoUrl,
    cannotAnswerVideoUrl,
    cannotAnswerVideoSubtitles,
    pauseAnswerVideo = false,
    pauseWaitingVideo = false,
    playAnswerVideo,
    playCannotAnswerVideo,
    transitionToCannotAnswerPlayer,
    transitionToAnswerPlayer,
    playWaitingVideo,
    muteWaitingVideo,
    showAnswerPlayer = true,
    showCannotAnswerPlayer = true,
    transcription,
    showSpinner,
    forceDissolve = false,
    disableDissolve = false,
    mediaStream,
    isRecording,
    onAnswerVideoStart = () => {},
    onAnswerVideoProgressed = () => {},
    onAnswerVideoEnd = () => {},
    onAnswerVideoBuffer = () => {},
    onAnswerVideoBufferEnd = () => {},
    onWaitingVideoBuffer = () => {},
    onWaitingVideoBufferEnd = () => {},
    onWaitingVideoProgress = () => {},
    onCannotAnswerVideoStart = () => {},
    onCannotAnswerVideoEnd = () => {},
    onCannotAnswerVideoBuffer = () => {},
    onCannotAnswerVideoBufferEnd = () => {},
    onPlayerTouchStart = () => {},
    onPlayerTouchEnd = () => {},
    onVideoPlayerError = () => {}
  },
  ref
) => {
  const { userId, username, thumbnail } = useContext(StoryFileContext)

  useImperativeHandle(ref, () => ({
    rewind: () => rewind()
  }))
  const answerPlayerRef = useRef()
  const cannotAnswerPlayerRef = useRef()
  const isWebAppLoaded = useRef(false)

  const waitingVideoPoster = IS_FACEBOOK_ANDROID ? thumbnail : null
  const cannotAnswerPoster = IS_FACEBOOK_ANDROID ? thumbnail : null
  const playerDissolve = forceDissolve || (!disablePlayerDissolve && !disableDissolve)

  const publishCheckpoint = payload =>
    checkpointService.publish({ ...payload, storyfileName: username, storyfileId: userId })

  const onAnswerVideoEnded = () => {
    publishCheckpoint({
      video: getPlayingVideo(answerVideoUrl),
      event: CHECKPOINT_EVENT.ANSWER_END
    })
    onAnswerVideoEnd()
  }

  const onAnswerVideoProgress = ev => {
    // skip progress when video is not playing
    if (ev.playedSeconds) {
      publishCheckpoint({
        video: getPlayingVideo(answerVideoUrl),
        event: CHECKPOINT_EVENT.ANSWER_PROGRESS,
        ...ev
      })
      onAnswerVideoProgressed(ev)
    }
  }

  const onAnswerVideoStarted = () => {
    publishCheckpoint({
      video: getPlayingVideo(answerVideoUrl),
      event: CHECKPOINT_EVENT.ANSWER_START
    })
    onAnswerVideoStart()
  }

  const onWaitingVideoReady = () => {
    if (!isWebAppLoaded.current) {
      isWebAppLoaded.current = true
      postEventToParent(WINDOW_MESSAGES.WEBAPP_LOADED)
    }
  }

  const onWaitingVideoStart = () =>
    publishCheckpoint({
      event: CHECKPOINT_EVENT.WAITING_START
    })

  const onCannotAnswerVideoStarted = () => {
    publishCheckpoint({
      event: CHECKPOINT_EVENT.CANNOT_ANSWER_START
    })
    onCannotAnswerVideoStart()
  }

  const onCannotAnswerVideoProgress = ev => {
    if (ev.playedSeconds) {
      publishCheckpoint({
        event: CHECKPOINT_EVENT.CANNOT_ANSWER_PROGRESS,
        ...ev
      })
    }
  }

  const onVideoError = (err, type) => {
    console.error(`ReactPlayer ${type} Error: `, err)
    onVideoPlayerError(err)
  }

  const handleOnMouseDown = () => {
    if (isMobile && isIOS) return
    onPlayerTouchStart()
  }

  const handleOnMouseUp = () => {
    if (isMobile && isIOS) return
    onPlayerTouchEnd()
  }

  const onContainerContextMenu = e => e.preventDefault()

  const rewind = () => {
    answerPlayerRef.current?.seekTo(0, 'seconds')
    cannotAnswerPlayerRef.current?.seekTo(0, 'seconds')
  }

  return (
    <div
      className={styles.container}
      style={{ background: playerBackground }}
      onTouchStart={onPlayerTouchStart}
      onTouchEnd={onPlayerTouchEnd}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onContextMenu={onContainerContextMenu}
    >
      {/* Answer Video */}
      {showAnswerPlayer && (
        <div
          id="answer-container"
          className={classNames(styles.player, {
            [styles.playerVisible]: transitionToAnswerPlayer,
            [styles.playerDissolve]: playerDissolve,
            [styles.playerVisibleDissolve]: transitionToAnswerPlayer && playerDissolve
          })}
        >
          <ReactPlayer
            id="answer-video-player"
            width=""
            height="100%"
            playsinline={true}
            ref={answerPlayerRef}
            /*  Config of the player is cached until the URL is changed. 
              We unset answerVideoUrl and cannotAnswerVideoUrl when subtitles are changed to reload the config. */
            /* answerVideoUrl || waitingVideoUrl 
              If a null is passed to the ReactPlayer, the player (video element) is dettached from the DOM.
              Probably this is the reason to have "|| waitingVideoUrl" this is legacy and we inherited it.
              We are not sure if we are going to break something if we remove it.
            */
            url={appendAccessToken(answerVideoUrl || waitingVideoUrl)}
            config={getPlayerConfig({ crossOrigin: 'true' }, answerVideoSubtitles)}
            playing={playAnswerVideo && !pauseAnswerVideo}
            onEnded={onAnswerVideoEnded}
            onBuffer={onAnswerVideoBuffer}
            onBufferEnd={onAnswerVideoBufferEnd}
            onError={err => onVideoError(err, 'AnswerPlayer')}
            onProgress={onAnswerVideoProgress}
            onStart={onAnswerVideoStarted}
          />
        </div>
      )}
      {/* Waiting Video */}
      <div
        id="waiting-container"
        className={classNames(styles.player, {
          [styles.playerVisible]: !transitionToCannotAnswerPlayer && playWaitingVideo,
          [styles.playerDissolve]: playerDissolve,
          [styles.playerVisibleDissolve]:
            !transitionToCannotAnswerPlayer && playWaitingVideo && playerDissolve
        })}
      >
        <ReactPlayer
          id="waiting-video-player"
          width=""
          height="100%"
          playsinline={true}
          preload="true"
          url={appendAccessToken(waitingVideoUrl)}
          config={getPlayerConfig({ poster: waitingVideoPoster })}
          onBuffer={onWaitingVideoBuffer}
          onBufferEnd={onWaitingVideoBufferEnd}
          playing={!pauseWaitingVideo}
          muted={
            !transitionToAnswerPlayer && !transitionToCannotAnswerPlayer ? muteWaitingVideo : true
          }
          loop
          onReady={onWaitingVideoReady}
          onStart={onWaitingVideoStart}
          onProgress={onWaitingVideoProgress}
          onError={err => onVideoError(err, 'WaitingPlayer')}
        />
      </div>
      {/* Can't Answer Video */}
      {showCannotAnswerPlayer && (
        <div
          id="cannot-answer-container"
          className={classNames(styles.player, {
            [styles.playerVisible]: transitionToCannotAnswerPlayer,
            [styles.playerDissolve]: playerDissolve,
            [styles.playerVisibleDissolve]: transitionToCannotAnswerPlayer && playerDissolve
          })}
        >
          <ReactPlayer
            id="cannot-answer-video-player"
            width=""
            muted={false}
            height="100%"
            playsinline={true}
            ref={cannotAnswerPlayerRef}
            /*  Config of the player is cached until the URL is changed. 
              We unset answerVideoUrl and cannotAnswerVideoUrl when subtitles are changed to reload the config. */
            /* cannotAnswerVideoUrl || waitingVideoUrl 
              If a null is passed to the ReactPlayer, the player (video element) is dettached from the DOM.
              Probably this is the reason to have "|| waitingVideoUrl" this is legacy and we inherited it.
              We are not sure if we are going to break something if we remove it.
            */
            url={appendAccessToken(cannotAnswerVideoUrl || waitingVideoUrl)}
            config={getPlayerConfig(
              { poster: cannotAnswerPoster, crossOrigin: 'true' },
              cannotAnswerVideoSubtitles
            )}
            playing={playCannotAnswerVideo}
            onEnded={onCannotAnswerVideoEnd}
            onStart={onCannotAnswerVideoStarted}
            onProgress={onCannotAnswerVideoProgress}
            onBuffer={onCannotAnswerVideoBuffer}
            onBufferEnd={onCannotAnswerVideoBufferEnd}
            onError={err => onVideoError(err, 'CannotAnswerPlayer')}
          />
        </div>
      )}
      {showSpinner && <Spinner className={styles.spinner} />}
      {showTranscription && !showSpeechVisualizer && transcription.length > 0 && (
        <div className={styles.transcription}>{transcription}</div>
      )}
      {showSpeechVisualizer && isRecording && (
        <div className={styles.audioVisualizer}>
          <AudioVisualizer stream={mediaStream} />
        </div>
      )}
    </div>
  )
}

export default forwardRef(VideoPlayer)
