import { useMemo } from 'react'
import { isMobile, isIOS, osVersion } from 'react-device-detect'

import { translate } from 'components/Translation/Translation'
import {
  COMMON_TALK_TO_ON_STORY_FILE,
  COMMON_SHARE_EMAIL_BODY,
  COMMON_HAPPY_STORYFILING
} from 'locales/translationIds'

const resolveEmailNewLineSymbol = text => {
  // IOS 14.6 issue - https://developer.apple.com/forums/thread/681023
  const parsedVersion = parseFloat(osVersion)
  if (isMobile && isIOS && parsedVersion >= 14.6 && parsedVersion < 15) {
    return text.replaceAll('\n', ' ')
  }
  return text
}

const generateEmailButtonProps = username => ({
  subject: translate(COMMON_TALK_TO_ON_STORY_FILE, [username]),
  body: resolveEmailNewLineSymbol(translate(COMMON_SHARE_EMAIL_BODY, [username])),
  separator: resolveEmailNewLineSymbol('\n\n'),
  url: resolveEmailNewLineSymbol(` \n\n${translate(COMMON_HAPPY_STORYFILING)}`)
})

const useEmailButtonProps = username =>
  useMemo(() => generateEmailButtonProps(username), [username])

export default useEmailButtonProps
