import { useEffect } from 'react'

import { remoteControlReceiver, MessageTypes } from 'remote-control'

const useRCReceiver = (onTranscription, onReset, onSubtitlesChanged, onVoiceLangChanged) => {
  useEffect(() => () => remoteControlReceiver?.destroyConnection(), [])

  useEffect(() => {
    if (remoteControlReceiver) {
      remoteControlReceiver.on(MessageTypes.TRANSCRIPTION, onTranscription)
      remoteControlReceiver.on(MessageTypes.RESET, onReset)
      remoteControlReceiver.on(MessageTypes.SUBTITLES_CHANGED, onSubtitlesChanged)
      remoteControlReceiver.on(MessageTypes.VOICE_LANGUAGE_CHANGED, onVoiceLangChanged)

      return () => {
        remoteControlReceiver.off(MessageTypes.TRANSCRIPTION, onTranscription)
        remoteControlReceiver.off(MessageTypes.RESET, onReset)
        remoteControlReceiver.off(MessageTypes.SUBTITLES_CHANGED, onSubtitlesChanged)
        remoteControlReceiver.off(MessageTypes.VOICE_LANGUAGE_CHANGED, onVoiceLangChanged)
      }
    }
  }, [onTranscription, onReset, onSubtitlesChanged, onVoiceLangChanged])

  return remoteControlReceiver
}

export default useRCReceiver
