import Peer from 'peerjs'
import EventEmitter from 'events'

import { appParamsService } from 'configuration'
import { translate } from 'components/Translation/Translation'
import { COMMON_CONNECTED_TO_PEER, COMMON_DISCONNECTED } from 'locales/translationIds'
import { getStoreState, SELECT } from 'providers/StoreProvider'

import { loadDefaultTransmitterSubsLabel } from 'utils'

import MessageTypes from './MessageTypes'
import Heartbeat from './Heartbeat'

const log = console.log.bind(console, '[RemoteControlTransmitter]')

class RemoteControlTransmitter extends EventEmitter {
  /**
   * id can be omitted. Then peerjs will create a random one
   */
  constructor(id) {
    super()
    this.connections = []
    this.peer = new Peer(id)
    this.peer.on('open', this._onOpen)
    this.peer.on('connection', this._onConnection)
    this.peer.on('error', this._handleError)
    this.status = ''
    this.activeStoryfileId = null
    this.subtitlesLabel = loadDefaultTransmitterSubsLabel()
    this.voiceLang = getStoreState(SELECT.LANGUAGE)
    this.heartbeat = new Heartbeat()
  }

  _handleError = error => {
    log('Error', error.type, error)
  }

  /**
   * new Peer() always emits 'open' when ready.
   * Thats when we can start doing things with it
   */
  _onOpen = id => {
    log('open peer:', id)
    this.emit('open', id)
  }

  _setStatus = () => {
    const cs = this.connections.length
    this.status = cs
      ? translate(COMMON_CONNECTED_TO_PEER, [cs, cs !== 1 ? 's' : ''])
      : translate(COMMON_DISCONNECTED)
    this.emit(MessageTypes.CONNECTION_STATUS, this.status)
  }

  _onConnection = connection => {
    log('connection received:', connection)
    this.connections.push(connection)
    this.heartbeat.addConnection(connection, true)
    this._setStatus()

    connection.on('data', event => {
      //avoid spam
      if (event.type === MessageTypes.HEARTBEAT) {
        return
      }
      log('<- received:', event)
      if (event.type === MessageTypes.RECEIVER_CONNECTED) {
        const message = {
          type: MessageTypes.RECEIVER_CONNECTED,
          value: {
            storyFileId: this.activeStoryfileId,
            subtitlesLabel: this.subtitlesLabel,
            lang: this.voiceLang.tag
          }
        }
        log('-> send:', message)
        connection.send(message)
      }
      this.emit(event.type, event.value)
    })
    connection.on('close', () => {
      log('connection closed:', connection)
      this.connections.splice(this.connections.indexOf(connection), 1)
      this._setStatus()
    })
  }

  _broadcast = message => {
    log('-> broadcasting:', message)
    for (const connection of this.connections) {
      connection.send(message)
    }
  }

  sendTranscription = transcription => {
    this._broadcast({ type: MessageTypes.TRANSCRIPTION, value: transcription })
  }

  sendActiveStoryfile = storyFileId => {
    this.activeStoryfileId = storyFileId
    this._broadcast({
      type: MessageTypes.ACTIVE_STORYFILE,
      value: { storyFileId, subtitlesLabel: this.subtitlesLabel, lang: this.voiceLang.tag }
    })
  }

  sendReset = () => {
    this._broadcast({ type: MessageTypes.RESET })
  }

  sendSubtitlesChanged = label => {
    this.subtitlesLabel = label
    this._broadcast({ type: MessageTypes.SUBTITLES_CHANGED, value: label })
  }

  sendVoiceLanguageChanged = lang => {
    this.voiceLang = lang
    this._broadcast({ type: MessageTypes.VOICE_LANGUAGE_CHANGED, value: lang })
  }

  destroyConnection = () => {
    console.log(`Destroying peer ${this}`)
    this.removeAllListeners()
    this.connections.forEach(connection => connection.close())
    this.peer.destroy()
  }
}

const normalizeId = id => (id === '1' ? undefined : id)

export const remoteControlTransmitter =
  appParamsService.rcTransmitter &&
  (appParamsService.rcWired
    ? undefined
    : new RemoteControlTransmitter(normalizeId(appParamsService.rcTransmitter)))
